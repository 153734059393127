import { RpcBackend } from "./RpcBackend";
import { ServerAlmType } from "./serverModel";

export class SwitchingRpcBackend implements RpcBackend {
  constructor(
    private backends: RpcBackend[],
    private choose: (path: string) => number,
  ) {}

  init(sessionId: string, almType?: ServerAlmType) {
    this.backends.forEach((backend) => backend.init(sessionId, almType));
  }

  backend(path: string) {
    return this.backends[this.choose(path)];
  }

  roomCall<T>(path: string, args?: unknown[]): Promise<T> {
    return this.backend(path).roomCall(path, args);
  }

  sessionCall<T>(
    path: string,
    args?: unknown[],
    kwargs?: Record<string, unknown>,
  ): Promise<T> {
    return this.backend(path).sessionCall(path, args, kwargs);
  }

  serverCall<T>(path: string): Promise<T> {
    return this.backend(path).serverCall(path);
  }

  staticSessionCall<T>(
    path: string,
    args?: unknown[],
    kwargs?: object,
  ): Promise<T> {
    return this.backend(path).staticSessionCall(path, args, kwargs);
  }

  boardCall<T>(path: string, args: unknown[], kwargs?: object): Promise<T> {
    return this.backend(path).boardCall(path, args, kwargs);
  }

  flexBoardCall<T>(path: string, args: unknown[], kwargs?: object): Promise<T> {
    return this.backend(path).flexBoardCall(path, args, kwargs);
  }

  userCall<T>(path: string): Promise<T> {
    return this.backend(path).userCall(path);
  }

  adminCall<T>(path: string, args?: unknown[]): Promise<T> {
    return this.backend(path).adminCall(path, args);
  }

  almCall<T>(path: string, args?: unknown[]): Promise<T> {
    return this.backend(path).almCall(path, args);
  }

  almToolCall<T>(path: string, args: unknown[], timeout?: number): Promise<T> {
    return this.backend(path).almToolCall(path, args, timeout);
  }
}
