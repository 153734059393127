<script lang="ts" setup>
import { computed } from "vue";

import UserChip from "@/components-ng/StickyNote/components/StickyNoteFooter/components/UserChip/UserChip.vue";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import { isRiskBoard as isRiskBoardType } from "@/model/board";
import { useBoardStore } from "@/store/board";
import { useTeamStore } from "@/store/team";
import { injectStrict } from "@/utils/context";

import StickyNoteRow from "../../../StickyNoteRow/StickyNoteRow.vue";
import MirrorStickyChip from "../MirrorStickyChip/MirrorStickyChip.vue";
import ROAMChip from "../ROAMChip/ROAMChip.vue";
import StickyLinksChip from "../StickyLinksChip/StickyLinksChip.vue";

const board = injectStrict(boardKey);
const card = injectStrict(cardKey);

const isActive = computed(() => useBoardStore().activeCardId === card.id);
const isRiskBoard = computed(() => isRiskBoardType(board.value.type));

const teamName = computed(() => {
  const teams = useTeamStore().teamsInCurrentArt;
  return teams.find((team) => team.id === card.teamId)?.name;
});
</script>

<template>
  <StickyNoteRow class="risk-footer">
    <span v-if="isRiskBoard && teamName" class="team-name">{{ teamName }}</span>
    <UserChip />
    <StickyLinksChip />
    <MirrorStickyChip v-if="isActive" />
    <div v-if="!isRiskBoard" class="roam">
      <ROAMChip />
    </div>
  </StickyNoteRow>
</template>

<style lang="scss" scoped>
.risk-footer {
  .team-name {
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  .roam {
    margin-left: auto;
  }
}
</style>
