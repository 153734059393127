<template>
  <BaseTooltip position="bottom">
    <template #content>
      <div class="timer-tooltip">
        <div class="time-info">
          <span v-if="finish" class="time">{{ $t("timer.timerMessage") }}</span>
          <div v-else>
            <div class="name">{{ timer.name }}</div>
            <div v-if="timerScope" class="timer-scope">
              {{ timerScope }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="timer-btn" :class="{ isEditing }">
      <progress-timer-icon
        :end-soon="endSoon"
        :ratio="ratio"
        :is-active="isEditing"
      />
      <span
        role="timer"
        :aria-label="
          $t('timer.ariaDuration', {
            h: duration.hourStr,
            m: duration.minuteStr,
            s: duration.secondStr,
          })
        "
      >
        <span aria-hidden="true">
          <template v-if="duration.hasHours">{{
            $t("timer.longDuration", {
              h: duration.hourStr,
              m: duration.minuteStr,
              s: duration.secondStr,
            })
          }}</template>
          <template v-else>{{
            $t("timer.shortDuration", {
              m: duration.minuteStr,
              s: duration.secondStr,
            })
          }}</template>
        </span>
      </span>
    </div>
  </BaseTooltip>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { keepEndedTimer, timerEnding } from "@/Settings";
import sound from "@/assets/timer.mp3";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import EventBusUser from "@/mixins/EventBusUser";
import { ServerTime, TimerData } from "@/model/timer";
import { serverTime } from "@/serverTime";

import Duration from "../Duration";
import ProgressTimerIcon from "./ProgressTimerIcon.vue";

@Component({
  components: { ProgressTimerIcon, BaseTooltip },
  emits: ["delete"],
})
export default class RunningTimer extends mixins(EventBusUser) {
  @Prop(Object) readonly timer!: TimerData;
  @Prop(String) readonly timerScope!: string;
  @Prop(Boolean) readonly isEditing!: boolean;

  duration = new Duration(0);
  audio = new Audio(sound);

  created() {
    this.duration = new Duration(this.timer.duration);
    this.onServerTick((serverTime) => this.setDuration(serverTime));
  }

  setDuration(serverTime: ServerTime) {
    if (this.timer.state === "running") {
      this.duration = Duration.fromEnd(this.timer.end, serverTime);
      if (this.duration.value === 0) {
        const lastSound = parseInt(localStorage.getItem("lastSound")!, 10) || 0;
        if (Date.now() - lastSound > keepEndedTimer) {
          localStorage.setItem("lastSound", "" + Date.now());
          this.audio.currentTime = 0;
          this.audio.play();
        }
      } else if (this.duration.value <= -keepEndedTimer / 1000) {
        this.$emit("delete");
      }
    }
  }

  get ratio() {
    if (this.timer) {
      this.duration = Duration.fromTimer(this.timer, serverTime);
      return this.duration.hourFraction;
    }
    return 0;
  }

  get finish() {
    return (
      this.duration.value <= 0 && this.duration.value >= -keepEndedTimer / 1000
    );
  }

  get endSoon() {
    return this.duration.value <= timerEnding / 1000;
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";

.timer-tooltip {
  position: relative;
  text-align: left;

  .name {
    font-weight: font.$weight-bold;
    margin-bottom: 5px;
  }
}

.timer-btn {
  position: relative;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
  border-radius: variables.$button-icon-radius;
  padding: 5px;

  span {
    font-size: 14px;
    color: var(--text-secondary-color);
    font-weight: font.$weight-semi-bold;
  }

  .timer-icon-progress {
    height: 20px;
  }
}
</style>
