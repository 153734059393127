import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { AuthUser, isUnknownUser } from "@/model/user";

export function useUser() {
  const { t } = useI18n();

  function userName(user: AuthUser) {
    return computed(() =>
      isUnknownUser(user) ? t("boardUsers.unknown") : user.name,
    );
  }

  return { userName };
}
