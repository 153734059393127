<template>
  <div class="edit-timer">
    <inactive-timer
      v-if="isState('stopped')"
      :name="name"
      :duration="duration"
      @name="name = $event"
      @duration="duration = $event"
      @start="setState('running')"
      @cancel="remove"
    />
    <active-timer
      v-else
      :name="name"
      :duration="duration"
      :user="timer.updatedByUser"
      :paused="isState('paused')"
    >
      <!-- Play/Pause button (depending on state) -->
      <IconButton
        :icon="isState('paused') ? 'timer/play' : 'timer/pause'"
        :aria-label="
          isState('paused')
            ? $t('label.timerPanel.resume')
            : $t('label.timerPanel.pause')
        "
        @click="setState(isState('paused') ? 'running' : 'paused')"
      />
      <!-- Stop button -->
      <IconButton
        icon="timer/stop"
        :aria-label="$t('label.timerPanel.stop')"
        @click="setState('stopped')"
      />
    </active-timer>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { keepEndedTimer } from "@/Settings";
import { deleteEvent, updateTimer } from "@/backend/Backend";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import EventBusUser from "@/mixins/EventBusUser";
import { ServerTime, TimerEvent, TimerState } from "@/model/timer";
import { serverTime } from "@/serverTime";
import { timerStarted } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import Duration from "../Duration";
import ActiveTimer from "./ActiveTimer.vue";
import InactiveTimer from "./InactiveTimer.vue";

@Component({
  components: {
    IconButton,
    ActiveTimer,
    InactiveTimer,
  },
})
export default class EditTimer extends mixins(EventBusUser) {
  @Prop(Object) readonly timer!: TimerEvent;

  name = this.timer.data.name;
  state = this.timer.data.state;
  duration = Duration.fromTimer(this.timer.data, serverTime);

  created() {
    if (this.timerFinished()) {
      this.remove();
    } else {
      this.onServerTick((serverTime) => this.update(serverTime));
    }
  }

  timerFinished() {
    return (
      this.isState("running") &&
      Duration.fromEnd(this.timer.data.end, serverTime).value <
        -keepEndedTimer / 1000
    );
  }

  update(serverTime: ServerTime) {
    if (this.isState("running") && this.duration.value > 0) {
      this.duration = Duration.fromEnd(this.timer.data.end, serverTime);
    }
  }

  isState(state: TimerState) {
    return this.state === state;
  }

  async setState(state: TimerState) {
    if (!this.isState(state) && state === "running") {
      const scope = this.timer.artId ? "art" : "board";
      trackEvent(timerStarted(scope));
    }

    if (!this.isState(state)) {
      this.state = state;
      this.timer.data.end = this.duration.end(serverTime);
      await updateTimer(this.timer.id, {
        name: this.name,
        state,
        duration: this.duration.value,
        end: this.timer.data.end,
      });
    }
  }

  remove() {
    deleteEvent(this.timer.id);
  }
}
</script>

<style lang="scss">
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";

.edit-timer {
  border-top: 1px solid colors-a11y.$divider-color;
  border-bottom: 1px solid colors-a11y.$divider-color;
  padding: 2em;
}
</style>
