<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { Icon } from "@/types/icon";

defineProps<{ icon?: Icon; title: string }>();

const emit = defineEmits<{ close: [] }>();
</script>

<template>
  <aside
    class="side-panel-content"
    aria-labelledby="side-panel-title"
    @click.stop
  >
    <div class="header">
      <SvgIcon
        v-if="icon"
        :name="icon"
        width="20"
        height="20"
        class="panel-icon"
        aria-hidden="true"
      />
      <h4 id="side-panel-title" style="flex-grow: 1">{{ title }}</h4>
      <IconButton
        icon="close"
        data-testid="side-panel-close"
        :aria-label="$t('general.close')"
        @click="emit('close')"
      />
    </div>
    <div v-if="$slots['sub-title']" class="sub-header">
      <slot name="sub-title" />
    </div>
    <hr aria-hidden="true" />
    <div class="content scrollable">
      <slot></slot>
    </div>
  </aside>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "@/styles/side-panel" as *;
@use "@/styles/mixins" as *;
@use "@/styles/side-panel";

.side-panel-content {
  @include side-panel;
  @include default-shadow;

  .high-contrast-mode & {
    border-left: 2px solid colors-a11y.$divider-color;
  }

  .header {
    font-weight: font.$weight-bold;
    padding: 1.2em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    color: colors-old.$text-primary-color;
  }

  .sub-header {
    padding: 0 1em 1.2em;
    display: flex;
    align-items: center;
    gap: 1em;
    color: colors-old.$text-secondary-color;

    svg {
      flex-shrink: 0;
    }
  }

  .content {
    overflow-y: auto;
  }

  .scrollable {
    position: relative;
  }
}
</style>
