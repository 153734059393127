import { ApplicationError } from "@/ApplicationError";

export class BackendError extends ApplicationError {
  constructor(
    name: string,
    message: string,
    contexts: {
      request: Record<string, unknown>;
      response: Record<string, unknown>;
    },
  ) {
    super(name, message, contexts);
  }
}
