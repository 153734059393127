import {
  FocusTarget,
  FocusTargetValue,
  type FocusTrap,
  Options,
  createFocusTrap,
} from "focus-trap";
import { v4 as uuid } from "uuid";
import { Directive } from "vue";

/**
 * Traps the focus inside an element. Usage: `v-focus-trap`
 *
 * By default, the trap is removed when the user clicks outiside of the trapped element.
 * This can be disabled by passing `{ disableOnOutsideClick: false }` as a value.
 */
export function focusTrap(): Directive<
  HTMLElement,
  Options & {
    disableOnOutsideClick?: boolean;
    rootEl?: HTMLElement;
    extraElements?: HTMLElement[];
    paused?: boolean;
  }
> {
  const trapStack: FocusTrap[] = [];
  const trapMap: Record<string, FocusTrap> = {};

  return {
    mounted(el, { value }) {
      // Make sure element has an ID, so we can track its focus trap
      el.id ||= uuid();

      // Clean up any existing focus trap for this element
      if (trapMap[el.id]) {
        trapMap[el.id].deactivate();
        delete trapMap[el.id];
      }

      const rootEl = value?.rootEl || el;
      const trapElements = [rootEl, ...(value?.extraElements ?? [])];

      const disableOnOutsideClick = value?.disableOnOutsideClick ?? true;

      const focusTrap = createFocusTrap(trapElements, {
        initialFocus: false,
        trapStack,
        clickOutsideDeactivates: disableOnOutsideClick,
        ...(value || {}),
        fallbackFocus: evaluateFallbackFocus(value?.fallbackFocus),
      });
      focusTrap.activate();

      if (value?.paused) {
        focusTrap.pause();
      }

      trapMap[el.id] = focusTrap;
    },
    updated(el, { value }) {
      // Manage paused state
      const focusTrap = trapMap[el.id];

      if (!focusTrap || !value || !focusTrap.active) return;

      if (value.paused && !focusTrap.paused) {
        focusTrap.pause();
      }

      if (!value.paused && focusTrap.paused) {
        focusTrap.unpause();
      }
    },
    unmounted(el) {
      const focusTrap = trapMap[el.id];

      focusTrap?.deactivate();
      delete trapMap[el.id];
    },
  };
}

/**
 * Evaluates the fallback focus target. If it does not resolve to
 * a visible element, return "body" as the fallback.
 *
 * This ensures there will always be a valid focus target, even if
 * focus traps are unmounted in an unpredictable order (eg. when the user
 * navigates back in the browser history, with multiple traps active).
 */
export function evaluateFallbackFocus(
  fallbackFocus?: FocusTarget,
): FocusTargetValue {
  let el = fallbackFocus;
  if (typeof el === "function") {
    el = el();
  }

  if (typeof el === "string") {
    el = document.querySelector(el) as HTMLElement;
  }

  return el || "body";
}
