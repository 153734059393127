<script setup lang="ts">
import { clamp } from "lodash-es";

import IconButton from "@/components/ui/IconButton/IconButton.vue";

import BoardNumberInput from "./BoardNumberInput.vue";

const emit = defineEmits<{ "update:modelValue": [value: number] }>();

const props = withDefaults(
  defineProps<{
    modelValue: number;
    minValue?: number;
    maxValue?: number;
    inputId?: string;
    inputClass?: string;
    label?: string;
  }>(),
  {
    minValue: 0,
    maxValue: 100,
    inputId: undefined,
    inputClass: undefined,
    label: undefined,
  },
);

let repeatTimeout = 0;

function startChange(diff: number, delay = 500) {
  clearTimeout(repeatTimeout);
  const val = props.modelValue + diff;
  if (val >= props.minValue && val <= props.maxValue) {
    emit("update:modelValue", val);
    repeatTimeout = window.setTimeout(() => startChange(diff, 30), delay);
  }
}

function endChange() {
  clearTimeout(repeatTimeout);
}

function handleEnterKey(event: KeyboardEvent) {
  event.stopPropagation();
  (event.target as HTMLInputElement).blur();
}

function handleInputChange(newValue: number) {
  if (isNaN(newValue)) {
    newValue = props.modelValue;
  }
  emit("update:modelValue", newValue);
}

function validateInput() {
  emit(
    "update:modelValue",
    clamp(props.modelValue, props.minValue, props.maxValue),
  );
}
</script>

<template>
  <div class="percentage-input">
    <IconButton
      icon="minus"
      :aria-label="$t('label.percentage.decrease', { value: props.label })"
      @mousedown="startChange(-1)"
      @keydown.enter="startChange(-1)"
      @keydown.space.prevent="startChange(-1)"
      @mouseup="endChange"
      @keyup="endChange"
    />
    <BoardNumberInput
      :id="inputId"
      :class="inputClass"
      :min="minValue"
      :max="maxValue"
      :value="modelValue"
      :maxlength="3"
      no-borders
      @input="handleInputChange"
      @blur="validateInput"
      @keydown.enter="handleEnterKey"
    />
    <IconButton
      icon="plus"
      :aria-label="$t('label.percentage.increase', { value: props.label })"
      @mousedown="startChange(1)"
      @keydown.enter="startChange(1)"
      @keydown.space="startChange(1)"
      @mouseup="endChange"
      @keyup="endChange"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";

.percentage-input {
  height: 40px;
  width: 120px;
  border-radius: 4px;
  border: 1px solid colors-a11y.$input-border-dark;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: colors-old.$text-secondary-color;
  }
}
</style>
