import { CancelError } from "@/backend/CancelError";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reloadOnMissingChunks(error: any): never {
  if (
    error.name === "ChunkLoadError" ||
    error.code === "CSS_CHUNK_LOAD_FAILED"
  ) {
    window.location.reload();
    throw new CancelError("missing chunk");
  }
  throw error;
}
