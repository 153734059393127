<script setup lang="ts">
import { computed, ref } from "vue";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/DropdownMenu/DropdownTrigger.vue";
import ListItem from "@/components/ListItem/ListItem.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { Session } from "@/model/session";
import { useSessionStore } from "@/store/session";

const emit = defineEmits<{ close: []; duplicate: [session: Session] }>();

const sessions = computed(() => useSessionStore().session.list);
const selected = ref(useSessionStore().session.current);
</script>

<template>
  <div v-autofocus class="duplicate-flex-board-elem">
    {{ $t("flexBoardElem.duplicateToSession") }}
    <br />
    <dropdown-menu class="duplicate-flex-board-elem-menu">
      <template #trigger>
        <dropdown-trigger :value="selected.name" />
      </template>
      <div v-keyboard-nav class="dropdown-list" role="listbox">
        <list-item
          v-for="session in sessions"
          :key="session.id"
          @click="selected = session"
        >
          {{ session.name }}
        </list-item>
      </div>
    </dropdown-menu>
    <div class="bottom">
      <button class="action line line-right item" @click.stop="emit('close')">
        {{ $t("general.cancel") }}
      </button>
      <button class="action item" @click.stop="emit('duplicate', selected)">
        <SvgIcon name="duplicate" width="20" height="20" />
        {{ $t("flexBoardElem.duplicate") }}
      </button>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables/colors" as colors;

.duplicate-flex-board-elem {
  .dropdown-trigger {
    margin-top: 1.5em;

    &,
    button {
      color: colors.$text-inverse !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.duplicate-flex-board-elem-menu .dropdown-list {
  max-height: 250px;
  overflow-y: auto;
}
</style>
