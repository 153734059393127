import { Ref, onMounted, ref } from "vue";

import { cardActions } from "@/action/cardActions";
import { registerCardDrag } from "@/components/card/cardDragHandler";
import { Card } from "@/model/card";
import { useBoardStore } from "@/store/board";
import {
  stickyNoteDragEnded,
  stickyNoteDragStarted,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

export type Props = Readonly<{
  el?: Ref<HTMLDivElement | undefined>;
  card: Card;
  disabled?: Ref<Readonly<boolean>>;
}>;

export function useDrag({ el, card, disabled }: Props) {
  onMounted(() => {
    if (disabled?.value) return;
    el?.value?.addEventListener("pointerdown", handlePointerDown);
  });

  const isDragging = ref(false);

  const handlePointerDown = (event: PointerEvent) => {
    const target = event.target as HTMLElement;

    const isLinkDrag = target.classList.contains("link-drag");
    if (disabled?.value || isLinkDrag) return;

    registerCardDrag(card, onDragEnd, event);
    isDragging.value = true;

    trackEvent(
      stickyNoteDragStarted(useBoardStore().currentBoard().type, "mouse"),
    );
  };

  const onDragEnd = () => {
    cardActions.stopAlter("internal", card.id);
    isDragging.value = false;

    // Prevent the focus ring from appearing if you shift-select after dragging
    el?.value?.setAttribute("data-blur-on-shift", "true");
    el?.value?.addEventListener(
      "focusin",
      () => el?.value?.removeAttribute("data-blur-on-shift"),
      { once: true },
    );

    trackEvent(
      stickyNoteDragEnded(useBoardStore().currentBoard().type, "mouse"),
    );
  };

  return { isDragging };
}
