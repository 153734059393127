import { Vue } from "vue-class-component";

import CardFlag from "@/model/CardFlag";
import {
  InfoLevel,
  StatusClass,
  statusClasses as allStatusClasses,
} from "@/model/baseTypes";
import { Board } from "@/model/board";
import { BoardCard } from "@/model/card";
import { Art, Iteration, Team } from "@/model/session";
import { StickyType } from "@/model/stickyType";
import { replaceQuery } from "@/router/navigation";
import { SearchQuery } from "@/router/types";
import { useBoardStore } from "@/store/board";
import {
  DependencyLink,
  dependencyLinks,
  useSearchMenuStore,
} from "@/store/searchMenu";
import { equals, fromString, join } from "@/utils/list";

import { CardMatcher } from "./CardMatcher";

export default class SearchItemBase extends Vue {
  store = useSearchMenuStore();

  get flags(): CardFlag[] {
    return this.store.flags;
  }

  get text() {
    return this.store.text;
  }

  set text(value: string) {
    this.store.text = value;
  }

  get selectedRiskyLinks() {
    return this.store.selectedRiskyLinks;
  }

  get selectedStatusClasses(): Set<StatusClass> {
    return this.store.selectedStatusClasses;
  }

  get types() {
    return this.store.selectedStickyTypes;
  }

  get allTeams() {
    return this.store.allTeams;
  }

  get allArts() {
    return this.store.allArts;
  }

  get allIterations() {
    return this.store.allIterations;
  }

  get iterations() {
    return this.store.selectedIterations;
  }

  get assignees() {
    return this.store.selectedAssignees;
  }

  get teams() {
    return this.store.selectedTeams;
  }

  get arts() {
    return this.store.selectedArts;
  }

  get allTypes(): StickyType[] {
    return useBoardStore().possibleStickyTypes;
  }

  clearSearchCriteria() {
    this.store.resetCriteria();
    this.updateSearch();
  }

  /** Populates search criterias from search query
   * @returns {boolean | null} Indicates that the search got populated with some values.
   * Returns null if the input criteria matches the previous state.
   */
  applySearchQuery(value: SearchQuery): boolean | null {
    const anySearchCriteria =
      value.text !== undefined ||
      value.id !== undefined ||
      value.flag !== undefined ||
      value.assignee !== undefined ||
      value.team !== undefined ||
      value.art !== undefined ||
      value.iteration !== undefined ||
      value.type !== undefined ||
      value.depLink !== undefined ||
      value.link !== undefined ||
      value.statusClass !== undefined;
    if (!anySearchCriteria) {
      this.clearSearchCriteria();
      return false;
    }
    const text = value.text || "";
    const flags = value.flag ? value.flag.split(",") : [];
    const assignees = value.assignee ? value.assignee.split(",") : [];
    const teams = fromString(this.allTeams, value.team, "name");
    const arts = fromString(this.allArts, value.art, "name");
    const iterations = fromString(this.allIterations, value.iteration, "name");
    const types: StickyType[] = fromString(this.allTypes, value.type, "name");
    const depLinks: DependencyLink[] = types.some(
      (type) => type.functionality === "dependency",
    )
      ? fromString(dependencyLinks, value.depLink)
      : [];

    const statusClasses: StatusClass[] = fromString(
      allStatusClasses,
      value.statusClass,
    );
    const links = value.link ? (value.link.split(",") as InfoLevel[]) : [];
    if (
      this.text === text &&
      this.store.id === value.id &&
      equals(
        this.flags.map((f) => f.toString()),
        flags,
      ) &&
      equals(
        this.assignees.map(({ id }) => id),
        assignees,
      ) &&
      equals(this.teams, teams) &&
      equals(this.iterations, iterations) &&
      equals(this.arts, arts) &&
      equals(this.types, types) &&
      equals(this.store.statusClasses, statusClasses) &&
      equals(this.selectedRiskyLinks, links) &&
      equals(this.store.selectedDependencyLinks, depLinks)
    ) {
      return null;
    }
    this.populateSearchQuery({
      text,
      id: value.id,
      flags,
      assignees,
      teams,
      arts,
      iterations,
      types,
      depLinks,
      statusClasses,
      links,
    });
    return true;
  }

  populateSearchQuery({
    text,
    id,
    flags,
    assignees,
    teams,
    arts,
    iterations,
    types,
    depLinks,
    statusClasses,
    links,
  }: {
    text: string;
    id?: string;
    flags: string[];
    assignees: string[];
    teams: Team[];
    arts: Art[];
    iterations: Iteration[];
    types: StickyType[];
    depLinks: DependencyLink[];
    statusClasses: StatusClass[];
    links: InfoLevel[];
  }) {
    this.text = text;
    this.store.id = id;
    this.store.selectedFlagStrings = new Set(flags);
    this.store.selectedAssigneeIds = new Set(assignees);
    this.store.selectedTeamIds = new Set(teams.map((team) => team.id));
    this.store.selectedArtIds = new Set(arts.map((art) => art.id));
    this.store.selectedIterationIds = new Set(
      iterations.map((iteration) => iteration.id),
    );
    this.store.selectedStickyTypeIds = new Set(
      types.map((stickyType) => stickyType.id),
    );
    this.store.selectedStatusClasses = new Set(statusClasses);
    this.store.selectedLinkStates = new Set(links);
    this.store.selectedDependencyLink = new Set(depLinks);
  }

  updateSearch() {
    // implemented by firstUsers of this mixin
  }

  updateRoute(foundIndex: number) {
    const search: SearchQuery = {
      text: this.text,
      id: this.store.id,
      flag: join(this.flags),
      assignee: join(this.assignees, "id"),
      team: join(this.teams, "name"),
      art: join(this.arts, "name"),
      iteration: join(this.iterations, "name"),
      type: join(this.types, "name"),
      depLink: join(this.store.selectedDependencyLinks),
      statusClass: join(this.store.statusClasses),
      link: join(this.selectedRiskyLinks),
    };
    if (foundIndex) {
      search.pos = "" + foundIndex;
    }
    replaceQuery({ search });
  }

  // TODO make the board responsible for marking search results? (like objectives board)
  matches(board: Board, card: BoardCard): boolean {
    // If there are no search criteria, don't list any results
    // (but don't grey out any cards, either)
    if (!this.store.anyFilterCriteria) {
      card.meta.mark = "normal";
      card.meta.dependencyTeamFilter = null;
      return false;
    }
    const cardMatcher = new CardMatcher(this.store, card);
    const [teamMatch, dependencyTeamFilter] = cardMatcher.teamMatches;
    const typeMatch = cardMatcher.typeMatches(board);

    const explicitLinkMatch = cardMatcher.explicitLinkMatch(board);
    const linkMatch =
      !this.linkSearch ||
      this.selectedRiskyLinks.length === 0 ||
      explicitLinkMatch;
    const propMatch =
      (cardMatcher.idMatches &&
        cardMatcher.flagMatches &&
        teamMatch &&
        cardMatcher.iterationMatches &&
        cardMatcher.assigneeMatches &&
        cardMatcher.artMatches &&
        typeMatch &&
        cardMatcher.statusClassMatches &&
        linkMatch) ||
      explicitLinkMatch;
    const matchesCard = cardMatcher.textMatches && propMatch;
    card.meta.mark = matchesCard
      ? "normal"
      : propMatch
        ? "fade-out"
        : "filter-out";
    card.meta.dependencyTeamFilter = dependencyTeamFilter;
    return matchesCard;
  }

  get selectedDependencyLink() {
    return this.store.selectedDependencyLink;
  }

  get selectedDependencyTeamFilter() {
    return this.store.selectedDependencyTeamFilter;
  }

  get linkSearch() {
    return useBoardStore().showRiskyLinks;
  }
}
