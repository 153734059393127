<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import ListItem from "@/components/ListItem/ListItem.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { Team } from "@/model/session";
import { useTeamStore } from "@/store/team";
import { planReadoutTeamChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

interface Props {
  currentTeam: Team;
}

const props = defineProps<Props>();

const emit = defineEmits<{ select: [team: Team] }>();

/**
 * All teams in the current session
 */
const teams = computed(() => useTeamStore().teamsInCurrentArt);

/**
 * Index of the currently selected team
 */
const currentTeamIndex = computed(() =>
  teams.value.findIndex((team) => team.id === props.currentTeam.id),
);

/**
 * Previous team in the list
 */
const previousTeam = computed(() => teams.value[currentTeamIndex.value - 1]);

/**
 * Next team in the list
 */
const nextTeam = computed(
  () => teams.value[currentTeamIndex.value + 1] || false,
);

const selectPreviousTeam = () => {
  if (previousTeam.value) {
    emit("select", previousTeam.value);

    trackEvent(planReadoutTeamChanged(previousTeam.value.id, "arrows"));
  }
};

const selectNextTeam = () => {
  if (nextTeam.value) {
    emit("select", nextTeam.value);

    trackEvent(planReadoutTeamChanged(nextTeam.value.id, "arrows"));
  }
};

const selectTeam = (team: Team) => {
  emit("select", team);

  trackEvent(planReadoutTeamChanged(team.id, "dropdown"));
};
</script>

<template>
  <div class="team-selector">
    <IconButton
      variant="outlined"
      size="small"
      icon="arrow/left"
      :tooltip="$t('action.previous')"
      :disabled="!previousTeam"
      @click="selectPreviousTeam"
    />
    <DropdownMenu :style="{ maxHeight: '50vh' }">
      <template #trigger>
        <BaseButton
          variant="ghost"
          color="grey"
          class="team-dropdown-trigger"
          icon-after="arrow/down"
        >
          <div class="team-name">
            <div class="name-container">
              <!--
                Small hack to always render this div as the same width
                by rendering all teams' names invisibly
              -->
              <div
                v-for="team in teams"
                :key="team.id"
                class="invisible"
                aria-hidden="true"
              >
                {{ team.name }}
              </div>
              <div class="current-team" data-testid="current-team">
                {{ currentTeam.name }}
              </div>
            </div>
          </div>
        </BaseButton>
      </template>
      <ListItem
        v-for="team in teams"
        :key="team.id"
        role="option"
        :active="team.id === currentTeam.id"
        @click="selectTeam(team)"
      >
        <div>
          <div class="team-title">{{ team.name }}</div>
        </div>
      </ListItem>
    </DropdownMenu>
    <IconButton
      variant="outlined"
      size="small"
      icon="arrow/right"
      :tooltip="$t('action.next')"
      :disabled="!nextTeam"
      @click="selectNextTeam"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

$team-name-line-height: 20px;
$team-name-max-width: 200px;

.team-selector {
  display: flex;
  align-items: center;
  gap: 16px;
}

// Small hack to always render this div as the same width
// by rendering all teams' names invisibly
.team-dropdown-trigger .team-name {
  height: $team-name-line-height;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: colors-old.$text-primary-color;
  font-size: font.$size-large;
  line-height: $team-name-line-height;

  .name-container {
    // Shift the container up so only the last name is displayed
    transform: (translateY(calc(-100% + $team-name-line-height)));

    .invisible {
      visibility: hidden;
    }

    .current-team {
      max-width: $team-name-max-width;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.arrow-icon {
  width: 12px;
  height: 12px;
  margin: 5px 4px 3px; // Make the down-arrow icon appear at the right height
}
</style>
