<script setup lang="ts">
import { ref } from "vue";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/DropdownMenu/DropdownTrigger.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { useSessionStore } from "@/store/session";
import { lastWeekDay } from "@/utils/date";
import { formatShortDate } from "@/utils/dateFormat";

const values = [
  {
    id: "workday",
    name: /*$t*/ "boardDiff.lastWorkday",
    date: () => lastWeekDay(new Date()),
  },
  {
    id: "iteration",
    name: /*$t*/ "boardDiff.iterationStart",
    date: () => useSessionStore().currentIteration.start,
  },
  {
    id: "pi",
    name: /*$t*/ "boardDiff.piStart",
    date: () => useSessionStore().iterations[0].start,
  },
];

export interface DateItem {
  id: string;
  date: Date;
}

const model = defineModel<DateItem | undefined>();
const selection = ref(
  values.find((value) => value.id === model.value?.id) || values[0],
);

function date(value: (typeof values)[number]) {
  return { date: formatShortDate(value.date()) };
}

select(selection.value);

function select(value: (typeof values)[number]) {
  model.value = { id: value.id, date: value.date() };
}
</script>

<template>
  <div class="date-filter">
    <div>{{ $t("boardDiff.dateFilter") }}</div>
    <DropdownMenu width="sm" is-aria-menu>
      <template #trigger>
        <DropdownTrigger :value="$t(selection.name, date(selection))" />
      </template>
      <SelectableList
        v-model="selection"
        v-keyboard-nav
        :values="values"
        @update:model-value="select"
      >
        <template #default="{ value }">
          <span>{{ $t(value.name, date(value)) }}</span>
        </template>
      </SelectableList>
    </DropdownMenu>
  </div>
</template>

<style lang="scss">
.date-filter {
  padding: 0 1em;
  width: 100%;

  .dropdown-menu {
    width: 100%;

    .dropdown-trigger {
      width: 100%;
    }
  }
}
</style>
