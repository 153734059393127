<script setup lang="ts">
import { ref, watch } from "vue";

import { getBoardDiff } from "@/backend/Backend";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import SidePanel from "@/components/SidePanel/SidePanel.vue";
import SidePanelContent from "@/components/SidePanel/SidePanelContent.vue";
import { Board } from "@/model/board";
import { StickyChange } from "@/model/change";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";

import DateFilter, { DateItem } from "./components/DateFilter.vue";
import DiffsBySticky from "./components/DiffsBySticky.vue";

const store = useActivityStore();
const changes = ref<StickyChange[]>([]);
const loading = ref(false);
const date = ref<DateItem>();

watch(
  () => ({ board: store.board, date: date.value }),
  async (to, from) => {
    const board = useBoardStore().board;
    // somehow the watch triggers sometimes even if neither board nor date change
    // check it to avoid multiple calls to the backend
    if (board && (to.board !== from.board || to.date?.id !== from.date?.id)) {
      if (store.board && date.value) {
        loading.value = true;
        changes.value = [];
        changes.value = await getBoardDiff(
          board.id,
          date.value.date,
          new Date(),
        );
        fadeCards(board);
        loading.value = false;
      } else {
        changes.value = [];
        unfadeCards(board);
      }
    }
  },
);

function fadeCards(board: Board) {
  Object.values(board.cards).forEach((card) => (card.meta.mark = "fade-out"));
  for (const change of changes.value) {
    const card = board.cards[change.stickyId];
    if (card) {
      card.meta.mark = "normal";
    }
  }
}

function unfadeCards(board: Board) {
  Object.values(board.cards).forEach((card) => (card.meta.mark = "normal"));
}
</script>

<template>
  <div class="board-diff-panel" data-ignore-click @scroll.stop>
    <SidePanel side="left" :active="!!store.board" name="boardDiff" resizable>
      <SidePanelContent
        v-focus-trap
        v-autofocus
        icon="action-menu/activity"
        :title="$t('boardDiff.boardTitle')"
        @close="store.inactivate()"
      >
        <template #sub-title>
          <DateFilter v-model="date" />
        </template>

        <DiffsBySticky :changes="changes" />
        <LoadingIndicator v-if="loading" />
        <div v-if="changes.length === 0 && !loading" class="group">
          {{ $t("boardDiff.empty") }}
        </div>
      </SidePanelContent>
    </SidePanel>
  </div>
</template>

<style lang="scss">
@use "@/styles/z-index";
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.board-diff-panel {
  position: relative;
  z-index: z-index.$menu;

  .side-panel .panel-icon {
    color: colors-old.$text-subtle-color;
  }

  .text {
    color: colors-old.$text-subtle-color;
    font-weight: font.$weight-medium;
    overflow-wrap: anywhere;
  }

  .group {
    padding: 0 1em;
    margin-top: 2.5em;
    margin-bottom: 1.5em;
  }

  .loading-indicator {
    margin-left: 2em;
  }
}
</style>
