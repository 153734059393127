<template>
  <div>
    <router-view />
    <net-statistics />
    <global-error />
    <loading-indicator global background />
    <!-- @vue-ignore -->
    <component v-bind="modal.attrs" :is="modal.component" ref="modal" />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Ref } from "vue-property-decorator";

import * as Gestures from "@/Gestures";
import { dragStartDist, maxZoom, minZoom } from "@/Settings";
import { resetZoom } from "@/lowlevel";
import EventBusUser from "@/mixins/EventBusUser";
import { useModalStore } from "@/store/modal";

import LoadingIndicator from "./LoadingIndicator.vue";
import NetStatistics from "./NetStatistics.vue";
import GlobalError from "./modal/GlobalError.vue";

interface Showable {
  show(data?: unknown): void;
}

@Component({ components: { LoadingIndicator, GlobalError, NetStatistics } })
export default class Base extends mixins(EventBusUser) {
  @Ref("modal") readonly modalElem!: Showable;

  get modal() {
    return useModalStore().modal;
  }

  mounted() {
    this.onBoardSwitch(resetZoom);
    Gestures.init(dragStartDist, minZoom, maxZoom);
  }
}
</script>

<style lang="scss">
@use "@/styles/font-families";
@use "@/styles/global";
@use "@/styles/tooltip";
</style>
