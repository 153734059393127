import type { SeverityLevel } from "@sentry/types";
import { Contexts } from "@sentry/types/types/context";
import type {
  Breadcrumb,
  EventHint,
  configureScope,
  init,
  addBreadcrumb as sentryAddBreadcrumb,
  getCurrentHub as sentryGetCurrentHub,
  setUser as sentrySetUser,
} from "@sentry/vue";
import { Hub, Replay } from "@sentry/vue";
import { ClientJS } from "clientjs";
import { noop } from "lodash-es";
import { App } from "vue";

import { environment, isCloud, sentryDsn } from "@/Environment";
import { isFeatureEnabled, parseUrlWithoutRouter } from "@/feature";
import { isBrowserTabActive } from "@/utils/dom";
import { asyncNoop } from "@/utils/general";
import { reloadOnMissingChunks } from "@/utils/import";

interface SentryFns {
  init: typeof init;
  setUser: typeof sentrySetUser;
  configureScope: typeof configureScope;
  getCurrentHub: typeof sentryGetCurrentHub;
  addBreadcrumb: typeof sentryAddBreadcrumb;
}

/* eslint-disable no-console */
const consoleLogger: Record<string, (...args: any[]) => void> = {
  error: console.error,
  warning: console.warn,
};

function consoleLogException(exception: unknown, hint?: EventHint) {
  console.error(exception, contexts(hint));
  return "";
}

function consoleLogMessage(
  message: string,
  level?: SeverityLevel,
  hint?: EventHint,
) {
  const logFunc = consoleLogger[level || "error"] || console.log;
  logFunc(message, contexts(hint));
  return "";
}

function contexts(hint?: EventHint) {
  return (hint?.captureContext as any)?.contexts || "";
}

/* eslint-enable */

const mockSentry: SentryFns = {
  init: noop,
  setUser: asyncNoop,
  getCurrentHub: () =>
    ({
      captureException: consoleLogException,
      captureMessage: consoleLogMessage,
    }) as Hub,
  configureScope: noop,
  addBreadcrumb: noop,
};

const loadSentry = (() => {
  let Sentry: SentryFns;
  return async (): Promise<SentryFns> => {
    if (!Sentry) {
      if (isCloud && sentryDsn) {
        Sentry = await import("@sentry/vue").catch(reloadOnMissingChunks);
      } else {
        Sentry = mockSentry;
      }
    }
    return Sentry;
  };
})();

export async function initSentry(app: App) {
  const Sentry = await loadSentry();
  const config: Parameters<typeof init>[0] = {
    app,
    dsn: sentryDsn,
    environment,
    normalizeDepth: 6,
  };
  if (!isFeatureEnabled({ query: parseUrlWithoutRouter() }, "log-less")) {
    Object.assign(config, {
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
      // useCompression: false to avoid CSP problems: https://github.com/getsentry/sentry-javascript/issues/6739
      integrations: [new Replay({ useCompression: false })],
    });
  }
  Sentry.init(config);
}

export async function setUser(company: string, authid: string) {
  const Sentry = await loadSentry();
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: new ClientJS().getFingerprint().toString(16),
      company,
      username: authid,
    });
    scope.setTag("company", company);
  });
}

export async function resetUser() {
  const Sentry = await loadSentry();
  Sentry.configureScope((scope) => {
    scope.setUser({ id: scope.getUser()?.id });
    scope.setTag("company", undefined);
  });
}

export async function captureException(
  exception: any,
  contexts: Contexts = {},
) {
  const Sentry = await loadSentry();
  return Sentry.getCurrentHub().captureException(
    exception,
    eventHint({ ...contexts, ...exception.contexts }),
  );
}

export async function captureMessage(message: string, contexts: Contexts = {}) {
  const Sentry = await loadSentry();
  return Sentry.getCurrentHub().captureMessage(
    message,
    "error",
    eventHint(contexts),
  );
}

function eventHint(contexts: Contexts): EventHint {
  contexts.browser = {
    tabActive: isBrowserTabActive(),
    online: navigator.onLine,
  };
  const eventId =
    contexts.request?.correlationId ||
    (contexts.request?.headers as any)?.["correlation-id"];
  return {
    event_id: eventId,
    captureContext: { contexts },
  };
}

export async function addBreadcrumb(
  category: Breadcrumb["category"],
  additionalProperties: Breadcrumb = {},
) {
  const Sentry = await loadSentry();
  Sentry.addBreadcrumb({ ...additionalProperties, category });
}
