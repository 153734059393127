<script setup lang="ts">
import { ref } from "vue";

import IconButton from "@/components/ui/IconButton/IconButton.vue";

defineProps<{ value: string }>();

const buttonRef = ref<HTMLElement | null>(null);
const focus = () => {
  buttonRef.value?.focus();
};
defineExpose({ focus });
</script>

<template>
  <button ref="buttonRef" class="dropdown-trigger" data-dropdown-trigger>
    <span>{{ value }}</span>
    <IconButton icon="arrow/down" size="small" tabindex="-1" />
  </button>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors" as colors;

.dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  height: 40px;
  border: 1px solid colors-a11y.$input-border-dark;
  border-radius: 4px;
  font-size: 14px;
  color: colors.$text;
  outline: revert;
  cursor: pointer;
}
</style>
