import { defineStore } from "pinia";

import { removeBoardSubscriptions } from "@/backend/Backend";
import { BoardLocation, fullBoardLocation } from "@/components/BoardLocation";
import { PlanningBoardLocation } from "@/components/PlanningBoardLocation";
import { RiskBoardLocation } from "@/components/RiskBoardLocation";
import { TeamBoardLocation } from "@/components/TeamBoardLocation";
import { i18n } from "@/i18n";
import { sendStickyNoteAction } from "@/mixins/EventBusUser";
import { Id } from "@/model/baseTypes";
import { Board, isPlanningBoard, isTeamBoard } from "@/model/board";
import { BoardCard, Card } from "@/model/card";
import { RelativeCoordinate } from "@/model/coordinates";
import { MarkMode } from "@/model/markMode";
import { Art, Group, Team } from "@/model/session";
import { StickyType } from "@/model/stickyType";
import {
  startUserHeartbeat,
  stopUserHeartbeat,
} from "@/services/heartbeat.service";

import { useArtStore } from "./art";
import { useBoardsStore } from "./boards";
import { useClientSettingsStore } from "./clientSettings";
import { usePointerStore } from "./pointer";
import { useStickyTypeStore } from "./stickyType";
import { useTeamStore } from "./team";
import { useUsersOnBoardStore } from "./usersOnBoard";

class BoardNotFoundError extends Error {}

export const useBoardStore = defineStore("board", {
  state: () => {
    return {
      board: undefined as Board | undefined,
      activeCardId: null as string | null,
      enlargedStickyNoteId: null as string | null,
      magnifying: false,
    };
  },
  getters: {
    hasCurrentBoard: (state) => {
      return typeof state.board !== "undefined";
    },
    positionalCardProperties() {
      return (pos: RelativeCoordinate): Partial<Card> => {
        const board = this.currentBoard();
        switch (board.type) {
          case "backlog":
            return { priority: 0 };
          case "program": {
            const loc = this.boardLocation(pos) as PlanningBoardLocation;
            return { iterationId: loc.iterationId, teamId: loc.groupId };
          }
          case "solution": {
            const loc = this.boardLocation(pos) as PlanningBoardLocation;
            return { iterationId: loc.iterationId, artId: loc.groupId };
          }
          case "team": {
            const loc = this.boardLocation(pos) as TeamBoardLocation;
            return { iterationId: loc.iterationId, teamId: board.team.id };
          }
          default:
            return {};
        }
      };
    },
    currentBoard: (state) => (): Board => {
      if (state.board) {
        return state.board;
      }
      throw new BoardNotFoundError("No current board is set");
    },
    boardId() {
      return () => this.currentBoard().id;
    },
    isCurrentBoardFluid: (state) => {
      return state.board?.type !== "objectives";
    },
    boardLocationFromIndex() {
      return (index: string[]): BoardLocation =>
        this.boardLocation(parseInt(index[0], 10), parseInt(index[1], 10));
    },
    boardLocation() {
      return (
        pos: RelativeCoordinate | number,
        top?: number,
        board?: Board,
      ): BoardLocation => {
        board = board || this.currentBoard();
        switch (board.type) {
          case "program":
            return PlanningBoardLocation.ofTeams(
              this.boardGroups(board),
              pos,
              top,
            );
          case "solution":
            return PlanningBoardLocation.ofArts(
              this.boardGroups(board),
              pos,
              top,
            );
          case "risk":
            return RiskBoardLocation.of(pos);
          case "team":
            return TeamBoardLocation.of(board.iterations, pos);
          default:
            return fullBoardLocation;
        }
      };
    },
    // sticky types that can possibly appear on the board
    possibleStickyTypes(): StickyType[] {
      const board = this.currentBoard();
      return board.type === "flex"
        ? useStickyTypeStore().flexBoardStickyTypes(board.flexType.id)
        : useStickyTypeStore().nonFlexBoardStickyTypes(board.type);
    },
    // sticky types that can be used to create a new sticky note
    creatableStickyTypes(): StickyType[] {
      switch (this.currentBoard().type) {
        case "risk":
          // we can’t select a team on the risk board, hence we should not allow creating team board stickies on the risk board
          return this.possibleStickyTypes.filter(
            (type) => type.origin !== "team",
          );
        case "solution":
          // we can't select a team on the solution-planning board, hence we should not allow creating team board stickies (dependencies)
          // also we should not allow creating art backlog stickies on the solution-planning board, because we do not automatically create
          // the origin on the art backlog board (not implemented in the backend)
          return this.possibleStickyTypes.filter(
            (type) => type.origin !== "team" && type.origin !== "backlog",
          );
        default:
          return this.possibleStickyTypes;
      }
    },
    selectedStickyIds: (state) => {
      return Object.keys(state.board?.selected || {});
    },
    selectedStickies(): BoardCard[] {
      return this.selectedStickyIds.map((id) => this.currentBoard().cards[id]);
    },
    selectedOrActiveCards(): BoardCard[] {
      if (this.selectedStickyIds.length > 0) {
        return this.selectedStickies;
      }
      const active = this.activeCard;
      return active ? [active] : [];
    },
    isStickySelected: (state) => {
      return (card: Card) => card.id in (state.board?.selected || {});
    },
    isStickyIdSelectedOrActive: (state) => {
      return (id: string) =>
        id in (state.board?.selected || {}) || state.activeCardId === id;
    },
    areMultipleStickiesSelected() {
      return this.selectedStickyIds?.length > 1;
    },
    boardGroups() {
      return (board?: Board): Group[] => {
        const theBoard = board || this.board;
        switch (theBoard?.type) {
          case "program":
            return programBoardTeams(theBoard.artId || "");
          case "solution":
            return solutionBoardArts();
          default:
            return [];
        }
      };

      function programBoardTeams(artId: string): Team[] {
        const milestoneEvents = {
          id: "",
          name: i18n.global.t("programBoard.milestoneEvents"),
        };
        const otherArts = useArtStore().isMultiArt
          ? [{ id: "", name: i18n.global.t("programBoard.otherArts") }]
          : [];
        const emptyTeam = { id: "", name: "" };

        return [
          milestoneEvents,
          ...useTeamStore().teamsInArt(artId),
          ...otherArts,
          emptyTeam,
        ];
      }

      function solutionBoardArts(): Art[] {
        const milestoneEvents = {
          id: "",
          name: i18n.global.t("programBoard.milestoneEvents"),
        };
        const emptyArt = { id: "", name: "" };
        const arts = useArtStore().arts;
        return [milestoneEvents, ...arts, emptyArt];
      }
    },
    activeCard(): BoardCard | null {
      const activeCardId = this.activeCardId;
      return activeCardId ? this.currentBoard().cards[activeCardId] : null;
    },
    showRiskyLinks(state): boolean {
      return (
        !!state.board &&
        (isPlanningBoard(state.board.type) || isTeamBoard(state.board.type))
      );
    },
    hasPermanentLinks(state): boolean {
      switch (state.board?.type) {
        case "program":
          return useClientSettingsStore().permanentProgramLinks;
        case "solution":
          return true;
        case "team":
          return useClientSettingsStore().permanentTeamLinks;
        default:
          return false;
      }
    },
  },
  actions: {
    switchBoard(board?: Board) {
      const newBoard =
        board || (this.board && useBoardsStore().boardByType(this.board.type));
      const res = { board: this.board, cards: new Array<BoardCard>() };
      if (newBoard && newBoard.id !== this.board?.id) {
        if (this.hasCurrentBoard) {
          res.cards = this.leaveBoard().cards;
        }
        this.board = newBoard;
        startUserHeartbeat();
      }
      return res;
    },
    leaveBoard() {
      const board = this.currentBoard();
      this.setActiveCardId(null);
      const cards = [];
      for (const id in board.cards) {
        const card = board.cards[id];
        if (card.meta.editing) {
          cards.push(card);
          card.meta.editing = false;
        }
      }
      stopUserHeartbeat();
      usePointerStore().reset();
      useUsersOnBoardStore().usersOnBoard = [];
      removeBoardSubscriptions();
      return { board, cards };
    },
    selectCard(cardId: string) {
      this.currentBoard().selected[cardId] = true;
    },
    unselectCard(cardId: string) {
      delete this.currentBoard().selected[cardId];
    },
    setActiveCardId(cardId: string | null) {
      if (this.activeCardId && cardId === null) {
        sendStickyNoteAction(this.activeCardId, { action: "changed" });
      }
      this.activeCardId = cardId;
    },
    setEnlargedStickyNoteId(id: string | null) {
      this.enlargedStickyNoteId = id;
    },
    setActiveCard(e: Id & { active: boolean }) {
      // don't clear active card if a different one is active
      if (!e.active && this.activeCardId !== e.id) {
        return;
      }
      this.setActiveCardId(e.active ? e.id : null);
    },
    markCard(id: string, mark: MarkMode) {
      this.currentBoard().cards[id].meta.mark = mark;
    },
  },
});
