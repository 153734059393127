<script lang="ts" setup>
import { computed, provide, reactive, ref, toRefs, watch } from "vue";

import {
  boardKey,
  boardMetaKey,
  cardKey,
  cardMetaKey,
  cardMethodsKey,
} from "@/components/card/injectKeys";
import { isBacklogBoard } from "@/model/board";
import { Card, CardMeta } from "@/model/card";
import { RelativeCoordinate } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useUserStore } from "@/store/user";
import {
  stickyNoteActivated,
  stickyNoteDeactivated,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";

import * as animations from "./animations";
import StickyNoteAccessibilityControls from "./components/Accessibility/StickyNoteAccessibilityControls.vue";
import ActionMenu from "./components/ActionMenu/ActionMenu.vue";
// sticky note elements
import StickyNoteFilter from "./components/StickyNoteFilter/StickyNoteFilter.vue";
import StickyNoteFooter from "./components/StickyNoteFooter/StickyNoteFooter.vue";
import StickyNoteHeader from "./components/StickyNoteHeader/StickyNoteHeader.vue";
import StickyNoteLinks from "./components/StickyNoteLinks/StickyNoteLinks.vue";
import StickyNoteLocked from "./components/StickyNoteLocked/StickyNoteLocked.vue";
import StickyNotePin from "./components/StickyNotePin/StickyNotePin.vue";
import StickyNoteProgress from "./components/StickyNoteProgress/StickyNoteProgress.vue";
import StickyNoteTextEditable from "./components/StickyNoteText/StickyNoteTextEditable.vue";
// composables
import { useActionMenu } from "./composables/useActionMenu";
import { useDrag } from "./composables/useDrag";
import { useEnlarge } from "./composables/useEnlarge";
import { useLinkDrag } from "./composables/useLinkDrag";
import { usePin } from "./composables/usePin";
import { useSelect } from "./composables/useSelect";
import { useStyles } from "./composables/useStyles";

type Props = Readonly<{
  card: Card;
  cardMeta: CardMeta;
  override?: { size: number; position: RelativeCoordinate }; // used to set the card size on backlog boards
  draggable?: boolean;
  fullDetails?: boolean;
  levelOfDetails?: 0 | 1 | 2;
}>;

const props = withDefaults(defineProps<Props>(), {
  draggable: true,
  levelOfDetails: 0,
});

const board = injectStrict(boardKey);
const boardMeta = injectStrict(boardMetaKey);

const levelOfDetails = computed(() => {
  if (
    props.fullDetails ||
    isEnlarged.value ||
    isEnlarging.value ||
    isActive.value
  ) {
    return 2;
  }
  return props.levelOfDetails;
});

const { override, draggable } = toRefs(props);

const stickyNoteRef = ref<HTMLDivElement>();
const actionMenuRef = ref<HTMLDivElement>();
const textInputRef = ref<typeof StickyNoteTextEditable>();
const accessibilityControlsRef = ref<typeof StickyNoteAccessibilityControls>();

// someone else is editing
const isLocked = computed(() => !!props.card.editor?.id);
// there are more than one card selected on the current board
const isMultiSelecting = computed(
  () => Object.keys(useBoardStore().currentBoard().selected).length > 1,
);
const isActive = computed(() => useBoardStore().activeCardId === props.card.id);

// used for analytics
watch(isActive, (newVal, prevVal) => {
  const cardType = props.card.type.functionality;
  const boardType = board.value.type;

  if (newVal && !prevVal) {
    trackEvent(stickyNoteActivated(cardType, boardType));
  } else {
    trackEvent(stickyNoteDeactivated(cardType, boardType));
  }
});

const {
  isEnlarged,
  isEnlarging,
  classes: enlargeTranstionClasses,
} = useEnlarge({
  el: stickyNoteRef,
  card: props.card,
  board: board.value,
  disabled: isLocked,
});

const { isSelected, deactivateSticky, tabindex, wasActivatedByKeyboard } =
  useSelect({
    el: stickyNoteRef,
    textInput: textInputRef,
    card: props.card,
    board: board,
    cardMeta: props.cardMeta,
  });

const { styles, sizes, position } = useStyles({
  card: props.card,
  cardMeta: props.cardMeta,
  board,
  override,
  isEnlarged,
  isActive,
  boardMeta,
});

const isPinningDisabled = computed(
  () => isEnlarged.value || isLocked.value || isBacklogBoard(board.value.type),
);
const { isPinned, unpin, pin } = usePin({
  el: stickyNoteRef,
  board,
  card: props.card,
  disabled: isPinningDisabled,
});

const isReadonly = computed(() => !useUserStore().isAllowed("edit"));

const isDraggingDisabled = computed(
  () =>
    isEnlarged.value || !draggable.value || isLocked.value || isReadonly.value,
);
useDrag({
  el: stickyNoteRef,
  card: props.card,
  disabled: isDraggingDisabled,
});

const isActionMenuDisabled = computed(
  () => isLocked.value || isEnlarging.value,
);
const { isOpen: isActionMenuOpen } = useActionMenu({
  referenceEl: stickyNoteRef,
  popperEl: actionMenuRef,
  card: props.card,
  isPinned,
  disabled: isActionMenuDisabled,
});

const { isLinkingTarget, isLinkingSource } = useLinkDrag({
  el: stickyNoteRef,
  card: props.card,
  disabled: isLocked,
});

provide(cardKey, props.card);
provide(
  cardMetaKey,
  reactive({
    rootEl: stickyNoteRef.value,
    pos: props.cardMeta.pos,
    isPinned,
    unpin,
    pin,
    isPinningDisabled,
    isSelected,
    isLinkingTarget,
    isEnlarged,
    levelOfDetails,
    isReadonly,
    wasActivatedByKeyboard,
  }),
);

provide(cardMethodsKey, {
  animate: async (animation) => {
    switch (animation) {
      case "mirroring":
        return await animations.mirror(stickyNoteRef.value);
      case "deleting":
        return await animations.remove(stickyNoteRef.value);
      case "moving":
        return await animations.move(stickyNoteRef.value);
      default:
        throw new Error(`Unknown animation: ${animation}`);
    }
  },
});

/**
 * Enter move-by-keyboard mode when the user presses 'm' on the sticky directly
 */
const handleMoveHotkey = (e: KeyboardEvent) => {
  if (e.target !== e.currentTarget) return;
  accessibilityControlsRef.value?.focusAndStartMove();
};

/**
 * Ensure the sticky note is deactivated (if necessary) and focused
 */
const handleAccessibleDeactivate = () => {
  if (isActive.value) {
    deactivateSticky();
  } else {
    stickyNoteRef.value?.focus();
  }
};
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions // extra shortcut -->
  <div
    :id="card.id"
    ref="stickyNoteRef"
    :class="[
      'sticky-note',
      {
        ...enlargeTranstionClasses,
        'linking-target': isLinkingTarget,
        draggable: !isDraggingDisabled,
        selected: isSelected,
      },
    ]"
    :style="styles"
    v-bind="$attrs"
    :role="
      isActive
        ? 'region' /* Act as a region when active, so descendent buttons work for screen readers */
        : 'button' /* Act as a button when inactive, so user can 'enter' the sticky */
    "
    :tabindex="tabindex"
    :aria-label="
      $t('label.stickyNote', { type: card.type.name, text: card.text })
    "
    :aria-owns="isActive ? `action-menu-${props.card.id}` : ''"
    @keydown.m="handleMoveHotkey"
  >
    <StickyNotePin v-if="isPinned && !isEnlarged && !isEnlarging" />

    <StickyNoteHeader />
    <StickyNoteTextEditable
      ref="textInputRef"
      @deactivate-note="deactivateSticky"
    />
    <StickyNoteFooter />

    <StickyNoteProgress v-if="!isEnlarged" />
    <StickyNoteFilter :mark="cardMeta.mark" />
    <StickyNoteLocked
      v-if="card.editor && card.editor.color"
      :editor="card.editor"
    />
    <StickyNoteLinks
      v-if="
        isActive &&
        !isEnlarging &&
        !isLinkingSource &&
        !isBacklogBoard(board.type) &&
        !isMultiSelecting
      "
      :scale="sizes.enlarged"
      :offset="position.offset"
    />
    <StickyNoteAccessibilityControls
      ref="accessibilityControlsRef"
      @deactivate="handleAccessibleDeactivate"
    />
  </div>

  <Teleport v-if="isActionMenuOpen" to="body">
    <div :ref="(el: any) => (actionMenuRef = el)" class="action-menu-container">
      <ActionMenu :id="`action-menu-${props.card.id}`" />
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/variables";
@use "@/styles/mixins";
@use "@/styles/mixins/a11y";
@use "@/styles/z-index";

.sticky-note {
  @include mixins.new-sticky-note-shadow;
  @include a11y.sticky-note;

  position: absolute;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  font-size: 12px;
  line-height: 1.25;
  height: variables.$sticky-size;
  width: variables.$sticky-size;
  transform-origin: top left;

  &.draggable {
    cursor: grab;
  }

  &.selected {
    outline: 2px solid colors-old.$primary-color;
  }

  &.enlarge-transition {
    transition-property: top, left, transform, z-index;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    transform-origin: top left;
  }

  &.linking-target {
    filter: brightness(70%);
  }

  &.deleting {
    opacity: 0;
    transition: opacity 0.5s;
  }
}

.action-menu-container {
  z-index: z-index.$action-menu;
}
</style>
