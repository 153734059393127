import { groupBy, sortBy } from "lodash-es";

import { i18n } from "@/i18n";
import { boardTitle } from "@/model/baseTypeI18n";
import { boardTypeOrder } from "@/model/board";
import { BaseStickyInfo, BoardCard } from "@/model/card";
import { SearchResult } from "@/model/search";
import { Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useStickyTypeStore } from "@/store/stickyType";

export type CardSearchResult = SearchResult & { card?: BoardCard };

export interface ItemKey {
  id: string;
  boardId?: string;
  teamId?: string;
}

export function toSearchResult(
  boardCard: BoardCard,
  boardId = useBoardStore().boardId(),
): CardSearchResult {
  const card = boardCard.data;
  return {
    kind: "sticky",
    boardId,
    id: card.id,
    text: card.text,
    typeId: card.type.id,
    iterationId: card.iterationId || undefined,
    teamId: card.teamId || undefined,
    flag: card.flagType,
    almId: card.almId,
    card: boardCard,
    artId: useBoardsStore().boardById(boardId).artId,
  };
}

interface SearchResultGroupByArt {
  artId: string;
  artName: string;
  boards: GroupedSearchResults[];
}

export interface GroupedSearchResults {
  boardId: string;
  boardName: string;
  count: string;
  items: BaseStickyInfo[];
}

const kindNames = {
  unknown: /*$t*/ "searchResultsTs.results",
  sticky: /*$t*/ "searchResultsTs.stickies",
};

export function sortSearchResults(results: SearchResult[]): SearchResult[] {
  return sortBy(
    results,
    ({ boardId }) => boardTypeOrder(useBoardsStore().boardById(boardId).type),
    ({ boardId }) => boardTitle(useBoardsStore().boardById(boardId)),
  );
}

export function groupSearchResultsByArt(
  results: SearchResult[],
): SearchResultGroupByArt[] {
  const groups = groupBy(results, (result) => result.artId);

  return Object.entries(groups).map(([artId, items]) => {
    const art = useArtStore().artById(artId);
    const artName = ` ${art?.name || ""}`;
    return {
      artId,
      artName: artName,
      boards: groupSearchResults(items),
    };
  });
}

function mapSearchResult(item: SearchResult): BaseStickyInfo {
  const type = useStickyTypeStore().findStickyType(item.typeId);
  return {
    id: item.id,
    text: item.text,
    color: type.color,
    type: type.name,
    flag: item.flag,
    almId: item.almId,
    teamId: item.teamId,
    functionality: type.functionality,
  };
}

export function groupSearchResults(
  results: SearchResult[],
): GroupedSearchResults[] {
  const groups = groupBy(results, (result) => result.boardId);
  return Object.entries(groups).map(([boardId, items]) => ({
    boardId,
    boardName: boardTitle(useBoardsStore().boardById(boardId)),
    count: countSearchResults(items),
    items: items.map((item) => mapSearchResult(item)),
  }));
}

export function groupSearchResultsByType(
  items: BaseStickyInfo[],
): Record<string, BaseStickyInfo[]> {
  return groupBy(items, (item) => item.type);
}

export function groupByTeamName(
  items: BaseStickyInfo[],
): Record<string, BaseStickyInfo[]> {
  const searchMenuStore = useSearchMenuStore();
  const findTeamById = (teamId: Team["id"]) =>
    searchMenuStore.allTeams.find((team) => team.id === teamId);

  const searchResultsGroupedByTeam = groupBy(items, (item) => {
    const { teamId } = item;

    if (!teamId) {
      return i18n.global.t("general.noTeam");
    }

    const foundTeam = findTeamById(teamId);
    if (!foundTeam) {
      return i18n.global.t("general.noTeam");
    }
    return foundTeam.name;
  });
  return Object.fromEntries(Object.entries(searchResultsGroupedByTeam));
}

function countSearchResults(results: SearchResult[]) {
  const kind = kindNames[results[0]?.kind || "unknown"];
  return i18n.global.t(kind, results.length);
}
