<script lang="ts" setup>
import { FunctionalComponent, computed } from "vue";

import UserAvatar from "@/components/UserAvatar.vue";
import { useUser } from "@/composables/useUser";
import { Card, Reaction } from "@/model/card";

const props = defineProps<{
  card: Card;
  reaction: Reaction;
  icon: FunctionalComponent;
}>();

const { userName } = useUser();

const users = computed(() => props.card.reactions?.[props.reaction]);
</script>

<template>
  <div v-if="users && users.length > 0" class="users-list">
    <I18nT keypath="reaction.with" scope="global" tag="h3">
      <template #reaction>
        <component :is="icon" class="small-reaction-icon" />
      </template>
    </I18nT>
    <ul class="scrollable scrollable-relative">
      <li v-for="user in users" :key="user.id">
        <UserAvatar :user="user" size="small" />
        {{ userName(user).value }}
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/tooltip-users-list";

.small-reaction-icon {
  width: 12px;
  height: 12px;
}
</style>
