<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";

import BaseInput from "./ui/BaseInput/BaseInput.vue";

/**
 * This component renders a text input with search icon
 * It also adds a listener that captures keypresses and focuses the input
 */
const modelValue = defineModel<string>({ required: true });
defineProps<{ title?: string }>(); // Read as the input label by screen readers

const elemRef = ref<HTMLInputElement>();

onMounted(() => {
  document.addEventListener("keydown", keydown);
});

onUnmounted(() => {
  document.removeEventListener("keydown", keydown);
});

function keydown(e: KeyboardEvent) {
  if (elemRef.value) {
    if (e.key === "Escape") {
      elemRef.value.blur();
      return;
    }

    if (isCharacter(e.key)) {
      elemRef.value.focus();
    }

    if (e.key === "Backspace" && modelValue.value.length > 0) {
      elemRef.value.focus();
    }
  }
}

function isCharacter(keyValue: string) {
  return keyValue.length === 1;
}
</script>

<template>
  <BaseInput
    ref="elemRef"
    v-model="modelValue"
    control-type="text"
    icon-before="search"
    :icon-before-title="title"
    :placeholder="$t('action.search')"
  />
</template>
