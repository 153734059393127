// NOTE: Use explicit paths for imports, otherwise check-events.ts will fail
import {
  BoardType,
  StatusClass,
  WorkingMode,
} from "../../../src/model/baseTypes";
import { ObjectiveType } from "../../../src/model/objective";
import { Functionality } from "../../../src/model/stickyType";

type Trigger =
  | "board-actions-menu"
  | "keyboard-shortcut"
  | "topbar-menu"
  | "sidebar-menu"
  | "action-menu"
  | "attribute-chip";

export type AnalyticsEvent = { readonly event: string } & Record<
  string,
  string | number | boolean | null
>;

/***
 * PLAN READOUT MODAL
 ***/

/**
 * Triggered after user sees  the plan readout modal
 */
export const planReadoutModalOpened = (): AnalyticsEvent => {
  return {
    event: "web.plan_readout_modal.opened",
  };
};

/**
 * Triggered when the plan readout modal is closed
 * (** not triggered when the user closes the tab/window)
 */
export const planReadoutModalClosed = (): AnalyticsEvent => {
  return {
    event: "web.plan_readout_modal.closed",
  };
};

/**
 * Triggered when the user switches tabs in the plan readout modal
 *
 * @param newTab Tab user clicked (tabId)
 * @param previousTab Current tab (tabId)
 */
export const planReadoutTabClicked = (
  newTab: string,
  previousTab: string,
): AnalyticsEvent => {
  return {
    event: "web.plan_readout_modal.tab_clicked",
    newTab,
    previousTab,
  };
};

/**
 * Triggered when the user switches teams in the plan readout modal
 *
 * @param teamId ID of the team the user switched to
 * @param source Whether the user used the dropdown or the arrows to switch teams
 */
export const planReadoutTeamChanged = (
  teamId: string,
  source: "dropdown" | "arrows",
): AnalyticsEvent => {
  return {
    event: "web.plan_readout_modal.team_changed",
    teamId,
    source,
  };
};

/**
 * Triggered when the user clicks on an objective in the plan readout modal
 * @param objectiveId
 */
export const planReadoutSelectObjective = (
  objectiveId: string,
  objectiveType: ObjectiveType,
) => {
  return {
    event: "web.app.plan_readout_modal.objective_selected",
    objectiveId,
    objectiveType,
  };
};

/**
 * Triggered when the user clicks on a risk in the plan readout modal
 * @param objectiveId
 */
export const planReadoutSelectRisk = (riskId: string) => {
  return {
    event: "web.plan_readout_modal.risk_selected",
    riskId,
  };
};

/**
 * Triggered when the expands/shrinks the 'linked stickies' section
 *
 * @param open True when the section is opened
 */
export const planReadoutObjectiveLinksToggled = (
  open: boolean,
): AnalyticsEvent => {
  return {
    event: "web.plan_readout_modal.objective_links_toggled",
    open,
  };
};

/***
 * OBJECTIVES
 ***/

/**
 * Triggered when the user sees the objectives modal
 */
export const teamObjectivesModalOpened = (): AnalyticsEvent => {
  return {
    event: "web.team_objectives_modal.opened",
  };
};

/**
 * Triggered when the user adds an objective
 */
export const objectiveCreated = (): AnalyticsEvent => {
  return {
    event: "web.team_objectives_modal.objective_created",
  };
};

/**
 * Triggered when the user sets the business value for an objective
 *
 * @param arg0.hadPreviousValue Whether the objective had a non-null business value before
 * @param arg0.hasNewValue Whether a non-null business value was set
 * @param arg0.location Where the business value was set from
 */
export const objectiveBusinessValueSet = ({
  hadPreviousValue,
  hasNewValue,
  location,
}: {
  hadPreviousValue: boolean;
  hasNewValue: boolean;
  location: "plan-readout-modal" | "objectives-modal";
}): AnalyticsEvent => {
  return {
    event: "web.team_objectives_modal.business_value_set",
    hadPreviousValue,
    hasNewValue,
    location,
  };
};

/**
 * Triggered when the user sets the actual value for an objective
 *
 * @param arg0.hadPreviousValue Whether the objective had a non-null business value before
 * @param arg0.hasNewValue Whether a non-null business value was set
 */
export const objectiveActualValueSet = ({
  hadPreviousValue,
  hasNewValue,
}: {
  hadPreviousValue: boolean;
  hasNewValue: boolean;
}): AnalyticsEvent => {
  return {
    event: "web.team_objectives_modal.actual_value_set",
    hadPreviousValue,
    hasNewValue,
  };
};

/***
 * GENERAL SETTINGS
 ***/

/**
 * Triggered after user changes the work mode
 */
export const workModeChanged = (
  mode: WorkingMode,
  board: BoardType,
  trigger: Extract<Trigger, "topbar-menu" | "keyboard-shortcut">,
): AnalyticsEvent => {
  return {
    event: "web.board.work_mode_changed",
    mode,
    board,
    trigger,
  };
};

/***
 * STICKY NOTE
 ***/

export type StickyCreateTrigger =
  | "board-actions-menu-click"
  | "board-actions-menu-drag"
  | "long-press-on-board"
  | "right-click-on-board"
  | "backlog-board-plus-button"
  | "keyboard-shortcut"
  | "action-menu-duplicate";

/**
 * Triggered after user create a sticky note
 */
export const stickyNoteCreated = (
  type: Functionality,
  board: BoardType,
  trigger?: StickyCreateTrigger,
  fromDefaultType?: boolean,
): AnalyticsEvent => ({
  event: "web.sticky_note.created",
  type,
  board,
  ...(trigger ? { trigger } : {}),
  ...(fromDefaultType ? { fromDefaultType } : {}),
});

// Triggered when the user pins the sticky note
export const stickyNotePinned = (
  stickyType: Functionality,
  board: BoardType,
  trigger: Extract<Trigger, "action-menu"> | "long-press-on-sticky-note",
): AnalyticsEvent => ({
  event: "web.sticky_note.pinned",
  stickyType,
  board,
  trigger,
});

// Triggered when the user unpins the sticky note
export const stickyNoteUnpined = (
  stickyType: Functionality,
  board: BoardType,
  trigger:
    | Extract<Trigger, "action-menu">
    | "long-press-on-sticky-note"
    | "pin-button",
): AnalyticsEvent => ({
  event: "web.sticky_note.unpined",
  stickyType,
  board,
  trigger,
});

/**
 * Triggered when the use duplicates the sticky note
 */
export const stickyNoteDuplicated = (
  stickyType: Functionality,
  board: BoardType,
  trigger: Extract<Trigger, "action-menu">,
): AnalyticsEvent => ({
  event: "web.sticky_note.duplicated",
  stickyType,
  board,
  trigger,
});

/**
 * Triggered when the user changes the team on the board
 */
export const teamChanged = (
  board: BoardType,
  trigger: Extract<Trigger, "topbar-menu" | "sidebar-menu">,
): AnalyticsEvent => ({
  event: "web.board.team_changed",
  board,
  trigger,
});

/**
 * Triggered when the user saves the board as image / takes a screenshot
 */
export const boardScreenshotSaved = (board: BoardType): AnalyticsEvent => ({
  event: "web.board.screenshot",
  board,
});

/**
 * Triggered when the user changes the board
 */
export const boardChanged = (
  prevBoard: BoardType,
  targetBoard: BoardType,
  trigger?: Extract<Trigger, "sidebar-menu" | "keyboard-shortcut">,
): AnalyticsEvent => ({
  event: "web.board.changed",
  prevBoard,
  targetBoard,
  ...(trigger ? { trigger } : {}),
});

/**
 * Triggered when the user opens a chip in the StickyNote
 */
export const stickyNoteAttributeChipClicked = (
  chipName: string,
  stickyType: Functionality,
  board: BoardType,
  isStickyActive: boolean,
): AnalyticsEvent => ({
  event: "web.sticky_note.attribute_chip_clicked",
  stickyType,
  board,
  chipName,
  isStickyActive,
});

// Triggered when the user opens an item in the action menu of the StickyNote
export const stickyNoteActionMenuItemClicked = (
  itemName: string,
  stickyType: Functionality,
  board: BoardType,
): AnalyticsEvent => ({
  event: "web.sticky_note.action_menu_item_clicked",
  stickyType,
  board,
  chipName: itemName,
});

// Triggered when the user mirrors a sticky note
export const stickyNoteMirrored = (
  stickyType: Functionality,
  fromBoard: BoardType,
  toBoard: BoardType,
  trigger: Extract<Trigger, "action-menu" | "attribute-chip">,
): AnalyticsEvent => ({
  event: "web.sticky_note.mirrored",
  stickyType,
  fromBoard,
  toBoard,
  trigger,
});

// Triggered when the user changes the sticky type
export const stickyNoteTypeChanged = (
  fromType: Functionality,
  toType: Functionality,
  board: BoardType,
  trigger: Extract<Trigger, "action-menu">,
) => ({
  event: "web.sticky_note.type_changed",
  fromType,
  toType,
  board,
  trigger,
});

// Triggered when the user changes the sticky status
export const stickyNoteStatusChanged = (
  fromStatus: StatusClass,
  toStatus: StatusClass,
  stickyType: Functionality,
  board: BoardType,
  trigger: Extract<Trigger, "action-menu" | "attribute-chip">,
) => ({
  event: "web.sticky_note.status_changed",
  from: fromStatus,
  to: toStatus,
  board,
  stickyType,
  trigger,
});

export type LinkCreateTrigger = "drag" | "linking-modal";
export type LinkRemoveTrigger =
  | "link-remove-button"
  | "linking-modal"
  | "team-objectives-modal"
  | "art-objectives-board";

// Triggered when the user link two sticky notes or a sticky note with an objective
export const stickyNoteLinkCreated = (
  from: Functionality,
  to: Functionality | "objective",
  board: BoardType,
  trigger?: string,
) => ({
  event: "web.sticky_note.link_created",
  from,
  to,
  board,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user removes a link
export const stickyNoteLinkRemoved = (
  from: Functionality,
  to: Functionality | "objective",
  board: BoardType,
  trigger?: string,
) => ({
  event: "web.sticky_note.link_removed",
  from,
  to,
  board,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user changes the wsjf value
export const stickyNoteWsjfChanged = (
  board: BoardType,
  stickyType: Functionality,
  trigger?: Extract<Trigger, "action-menu" | "attribute-chip">,
) => ({
  event: "web.sticky_note.wsjf_changed",
  board: board,
  stickyType,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user changes the storypoints of a sticky note
export const stickyNoteStoryPointsChanged = (
  board: BoardType,
  stickyType: Functionality,
  trigger: "action-menu" | "attribute-chip",
) => ({
  event: "web.sticky_note.storypoints_changed",
  board,
  stickyType,
  trigger,
});

// Triggered when the user changes the project of a sticky note
export const stickyNoteProjectChanged = (
  board: BoardType,
  stickyType: Functionality,
  trigger: Extract<Trigger, "action-menu">,
) => ({
  event: "web.sticky_note.project_changed",
  board,
  stickyType,
  trigger,
});

// Triggered when the user changes the depend on team of a dependency sticky note
export const stickyNoteDependOnTeamChanged = (
  board: BoardType,
  stickyType: Functionality,
  isSameArt: boolean,
  trigger?: Extract<Trigger, "action-menu" | "attribute-chip">,
) => ({
  event: "web.sticky_note.depend_on_team_changed",
  board,
  stickyType,
  isSameArt,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user opens the timer sidebar
export const timerSidebarOpened = (
  trigger?: Extract<Trigger, "topbar-menu" | "keyboard-shortcut">,
) => ({
  event: "web.timer.sidebar_opened",
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user adds a timer
export const timerAdded = (scope: "board" | "art") => ({
  event: "web.timer.added",
  type: scope,
});

// Triggered when the user starts a timer
export const timerStarted = (scope: "board" | "art") => ({
  event: "web.timer.started",
  scope,
});

// Triggered when the user opens the search sidebar
export const searchOpened = (
  trigger?: Extract<Trigger, "topbar-menu" | "keyboard-shortcut">,
) => ({
  event: "web.search.opened",
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user closes the search sidebar
export const searchClosed = (
  keepFilters?: boolean,
  trigger?: Extract<Trigger, "topbar-menu" | "keyboard-shortcut">,
) => ({
  event: "web.search.closed",
  ...(keepFilters ? { keepFilters } : {}),
  ...(trigger ? { trigger } : {}),
});

export const searchFilterOpened = (filterName: string) => ({
  event: "web.search.filter_opened",
  filterName,
});

// Triggered when the user activates the pointer trail functionality
export const pointerTrailActivated = (
  board: BoardType,
  trigger?: Extract<Trigger, "board-actions-menu" | "keyboard-shortcut">,
) => ({
  event: "web.board.pointer_trail_activated",
  board,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user activates the select on click functionality
export const selectOnClickActivated = (
  trigger?: Extract<Trigger, "board-actions-menu" | "keyboard-shortcut">,
) => ({
  event: "web.board.select_on_click_activated",
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user opens the sticky note links modal
export const stickyNoteLinkingModalOpened = (
  stickyType: Functionality,
  board: BoardType,
  trigger: Extract<Trigger, "action-menu">,
) => ({
  event: "web.sticky_note.linking_modal_opened",
  stickyType,
  board,
  trigger,
});

// when the user opens the app zoom menu in the top right corner of the app
export const appZoomOpened = (): AnalyticsEvent => ({
  event: "web.topbar-menu.app_zoom_opened",
});

// Triggered when the user changes the zoom level using the app zoom slider
// or the +/- buttons at the top right corner of the app
export const appZoomZoomChanged = (): AnalyticsEvent => ({
  event: "web.topbar-menu.app_zoom_changed",
});

// triggered when the user deactivates a StickyNote.
export const stickyNoteDeactivated = (
  stickyType: Functionality,
  board: BoardType,
): AnalyticsEvent => ({
  event: "web.sticky_note.deactivated",
  stickyType,
  board,
});

// triggered when the user activates a StickyNote.
export const stickyNoteActivated = (
  stickyType: Functionality,
  board: BoardType,
): AnalyticsEvent => ({
  event: "web.sticky_note.activated",
  stickyType,
  board,
});

// triggered when the user changes the text of a StickyNote. Triggered only once per focus
export const stickyNoteTextChanged = (
  stickyType: Functionality,
  board: BoardType,
  textLengthBeforeEdit: number,
): AnalyticsEvent => ({
  event: "web.sticky_note.text_changed",
  stickyType,
  board,
  textLengthBeforeEdit,
});

export type EnlargeTrigger =
  | "double-click"
  | "spacebar"
  | "after-create"
  | "search-item-selected"
  | "after-link-drag";
// triggered when the user enlarges the StickyNote.
export const stickyNoteEnlarged = (
  stickyType: Functionality,
  board: BoardType,
  trigger?: EnlargeTrigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.enlarged",
  stickyType,
  board,
  ...(trigger ? { trigger } : {}),
});

// triggered when the user shrinks the StickyNote.
export const stickyNoteShrunk = (
  stickyType: Functionality,
  board: BoardType,
): AnalyticsEvent => ({
  event: "web.sticky_note.shrunk",
  stickyType,
  board,
});

// triggered when the user changes the search text. Triggered once per focus
export const searchTextChanged = (board: BoardType): AnalyticsEvent => ({
  event: "web.search.text_changed",
  board,
});

// triggered when the user select an item in the search sidebar
export const searchItemSelected = (
  currentBoardType: BoardType,
  targetBoardType?: BoardType,
): AnalyticsEvent => ({
  event: "web.search.item_selected",
  currentBoardType,
  ...(targetBoardType ? { targetBoardType } : {}),
});

// trigereed when the user sees the overview modal
export const overviewModalSeen = (): AnalyticsEvent => ({
  event: "web.overview_modal.seen",
});

// triggers when the user clicks the link drag on the sticky nore
export const stickyNoteLinkDragStarted = (
  stickyType: Functionality,
  board: BoardType,
): AnalyticsEvent => ({
  event: "web.sticky_note.link_drag_started",
  stickyType,
  board,
});

// triggers when the use clicks on the app logo at the top left corner
export const appLogoClicked = (): AnalyticsEvent => ({
  event: "web.app.logo_clicked",
});

// triggereted when the user clicks the rte cockpit button
export const rteCockpitButtonClicked = (): AnalyticsEvent => ({
  event: "web.session_select_page.cockpit_button_clicked",
});

// triggereted when the user clicks the "show archived sessions" checkbox
export const showArchivedSessionClicked = (value: boolean): AnalyticsEvent => ({
  event: "web.session_select_page.show_archived_clicked",
  value,
});

// when the user select a session in the session page
export const sessionSelected = (): AnalyticsEvent => ({
  event: "web.session_select_page.session_selected",
});

// when the use selects an art in the ARTs page
export const artSelected = (): AnalyticsEvent => ({
  event: "web.arts_select_page.art_selected",
});

// when the user clicks on the back button on the ARTs page
export const artBackButtonClicked = (): AnalyticsEvent => ({
  event: "web.arts_select_page.back_button_clicked",
});

// when the user selects a team in the team page // not the modal
export const teamSelected = (): AnalyticsEvent => ({
  event: "web.team_select_page.team_selected",
});

// when the user click on the back button on the team select page / not the modal
export const teamBackButtonClicked = (): AnalyticsEvent => ({
  event: "web.team_select_page.back_button_clicked",
});

// when the use sees the team change modal
export const teamChangeModalSeen = (): AnalyticsEvent => ({
  event: "web.team_change_modal.seen",
});

// when the user opens the user actions menu at the top right corner of the app
export const userActionsMenuOpened = (): AnalyticsEvent => ({
  event: "web.app.user_actions_menu_opened",
});

/**
 * Drag Events
 */
export const stickyNoteDragStarted = (
  board: BoardType,
  trigger: "mouse" | "keyboard" | "accessibility-controls",
): AnalyticsEvent => ({
  event: "web.sticky_note.keyboard_drag_started",
  board,
  trigger,
});
export const stickyNoteDragMoved = (
  board: BoardType,
  trigger: "keyboard" | "accessibility-controls",
): AnalyticsEvent => ({
  event: "web.sticky_note.keyboard_drag_moved",
  board,
  trigger,
});
export const stickyNoteDragEnded = (
  board: BoardType,
  trigger: "mouse" | "keyboard" | "accessibility-controls",
): AnalyticsEvent => ({
  event: "web.sticky_note.keyboard_drag_ended",
  board,
  trigger,
});
