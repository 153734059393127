<script lang="ts" setup>
import { nextTick, onMounted, ref, watch } from "vue";

import { cardKey } from "@/components/card/injectKeys";
import { TextFontData } from "@/components/input/fontSizeCache";
import { optimalFontSize } from "@/fontSizeOptimizer";
import { isDependency } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { useZoomStore } from "@/store/zoom";
import { injectStrict } from "@/utils/context";

const DEFAULT_FONT_SIZE = 300;

const props = defineProps<{
  notOnBoard?: boolean; // When rendered on a board, the card size is fixed (so we can optimize the font-size calculation)
  renderedEl?: HTMLElement | null;
  isEditing?: boolean;
}>();

onMounted(() => {
  watch(() => card.text, calculateOptimalFontSize, { immediate: true });
  watch([() => useZoomStore().factor, () => useBoardStore().board?.id], () =>
    calculateOptimalFontSize(card.text),
  );
});

const card = injectStrict(cardKey);

const fontSize = ref(DEFAULT_FONT_SIZE);
const lines = ref<string[]>([]);

const calculateOptimalFontSize = async (value: string) => {
  await nextTick();

  if (!props.renderedEl?.clientHeight) return;

  const fontData = await optimalFontSize(
    props.renderedEl,
    value,
    props.isEditing,
    isDependency(card),
    props.notOnBoard,
  );

  fontSize.value = fontData.size;
  if (!props.isEditing) {
    lines.value = (fontData as TextFontData).htmlLines;
  }
};

defineExpose({ recalculateFontSize: calculateOptimalFontSize });
</script>

<template>
  <div class="sticky-note-text-wrapper" :data-type="card.type.functionality">
    <slot :font-size="fontSize" :lines="lines"> </slot>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.sticky-note-text-wrapper {
  font-family: var(--card-font);
  overflow: hidden;
  margin: 4px;
  padding: 4px;

  &:focus-within {
    background-color: colors.$black-alpha-5;
  }
}
</style>
