<script setup lang="ts">
import { computed, ref } from "vue";

import RevealHeightTransition from "@/components-ng/transitions/RevealHeightTransition.vue";
import { stickyChanges } from "@/components/ActivityPanel/stickyChanges";
import UserAvatar from "@/components/UserAvatar.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { StickyChange } from "@/model/change";
import {
  formatTimestampFriendly,
  relativeDate,
  relativeTime,
} from "@/utils/dateFormat";

import { groupByDay } from "./groupChanges";

const props = defineProps<{ changes: StickyChange[] }>();

const changesByDay = computed(() => groupByDay(props.changes));

const expanded = ref<Record<string, boolean>>({});

function toggle(dayIndex: number, changeIndex: number) {
  expanded.value[dayIndex + "_" + changeIndex] = !isExpanded(
    dayIndex,
    changeIndex,
  );
}

function isExpanded(dayIndex: number, changeIndex: number) {
  return expanded.value[dayIndex + "_" + changeIndex];
}

function action(change: StickyChange) {
  switch (change.kind) {
    case "create":
      return /*$t*/ "stickyChange.created";
    case "mirror":
      return /*$t*/ "stickyChange.mirrored";
    case "unmirror":
      return /*$t*/ "stickyChange.unmirrored";
    case "link":
      return /*$t*/ "stickyChange.link";
    case "unlink":
      return /*$t*/ "stickyChange.unlink";
    case "update":
      // Handle the special case where the dependent-on team was set -- here,
      // the sticky is automatically flagged at the same time, so there are
      // multiple updates, but we want to use the dependent-on-team label
      // so, it's clear to the user what happened
      if (change.fields.find((field) => field.name === "dependTeamId")) {
        return stickyChanges.dependTeamId.keyNew;
      }
      if (change.fields.length > 1) {
        return /*$t*/ "stickyChange.updated";
      }
      // If the value was previously undefined, use a slightly different label
      if ((change.fields[0].old ?? null) === null) {
        return stickyChanges[change.fields[0].name].keyNew;
      }
      return stickyChanges[change.fields[0].name].key;
    case "delete":
      return /*$t*/ "stickyChange.deleted";
  }
}
</script>

<template>
  <div
    v-for="(dayChange, dayIndex) in changesByDay"
    :key="dayChange.date.getTime()"
    class="sticky-change-by-day"
  >
    <span class="day" :aria-label="$t('label.activityPanel.date')">
      {{ relativeDate(dayChange.date) }}
    </span>
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
    <div
      v-for="(change, changeIndex) in dayChange.changes"
      :key="change.timestamp.getTime()"
      class="sticky-change"
      :class="{ action: change.expandable }"
      @click.stop="change.expandable && toggle(dayIndex, changeIndex)"
    >
      <UserAvatar :user="change.user" aria-hidden="true" />
      <div class="change-content">
        <div class="desc">
          <I18nT :keypath="action(change)" scope="global" tag="span">
            <template #user>
              <span class="user">{{ change.user.name }}</span>
            </template>
          </I18nT>
        </div>
        <div>
          <BaseTooltip position="bottom" tag="span">
            <span class="timestamp">
              {{ relativeTime(change.timestamp) }}
            </span>
            <template #content>
              {{ formatTimestampFriendly(change.timestamp) }}
            </template>
          </BaseTooltip>
        </div>
        <RevealHeightTransition>
          <div
            v-if="isExpanded(dayIndex, changeIndex)"
            class="change-details"
            aria-live="polite"
            @click.stop
          >
            <div v-for="field in change.fields" :key="field.name" class="field">
              <Component
                :is="stickyChanges[field.name].component"
                :field="field"
                :sticky-id="change.stickyId"
              />
            </div>
          </div>
        </RevealHeightTransition>
      </div>
      <IconButton
        v-if="change.expandable"
        icon="arrow/down"
        width="16"
        height="16"
        class="toggle-icon"
        :aria-label="$t('label.activityPanel.toggleChange')"
        :aria-expanded="isExpanded(dayIndex, changeIndex)"
        :class="{ expanded: isExpanded(dayIndex, changeIndex) }"
      />
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "changes";

.sticky-change-by-day {
  padding: 0 1em;
  margin-top: 2.5em;
  margin-bottom: 1.5em;

  .day {
    font-weight: font.$weight-bold;
  }

  .sticky-change {
    @include changes.sticky-change;
  }
}
</style>
