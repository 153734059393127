import { noop } from "lodash-es";
import "the-new-css-reset/css/reset.css";
import { createApp } from "vue";
import VueGtag from "vue-gtag";

import Base from "@/components/Base.vue";
import { IntercomStatic } from "@/declare/windowIntercom";
import { autofocus } from "@/directives/autofocus";
import { focusTrap } from "@/directives/focusTrap";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { scrollableOnBoard } from "@/directives/scrollableOnBoard";
import { setLanguage } from "@/language";
import { wrapMutationObserver } from "@/mutationObserver";
import { goToLogout } from "@/router/navigation";
import { router } from "@/router/router";
import { setUnauthorizedHandler } from "@/services/api.config";
import { useClientSettingsStore } from "@/store/clientSettings";
import pinia from "@/store/pinia";
import { useStatisticsStore } from "@/store/statistics";
import "@/styles/global/custom-properties.css";

import { showHelp } from "./console";
import { registerCopyPaste } from "./copyPaste";
import { ownedCards } from "./directives/ownedCards";
import { handleError } from "./errorHandler";
import { registerGlobalActions } from "./globalActions";
import { i18n } from "./i18n";
import { captureException, initSentry } from "./sentry";

wrapMutationObserver();

const app = createApp(Base).use(router).use(pinia).use(i18n).use(VueGtag, {
  bootstrap: false,
  disableScriptLoad: true,
});

initSentry(app)
  .then(() => {
    window.onunhandledrejection = (event) => {
      event.preventDefault();
      handleError(event.reason);
    };
    window.onerror = (msg, url, line, col, error) => {
      captureException(error);
      return true;
    };
  })
  .finally(() => {
    app.directive("focus-trap", focusTrap());
    app.directive("autofocus", autofocus());
    app.directive("scrollable-on-board", scrollableOnBoard());
    app.directive("owned-cards", ownedCards());
    app.directive("keyboard-nav", keyboardNavigation());

    app.mount("#app").$watch("$route.query.lang", setLanguage);
  });

useClientSettingsStore().init();

window.stats = () => simpleObject(useStatisticsStore().current);
window.allStats = () => simpleObject(useStatisticsStore().statistics);

window.baseSize = () => {
  const child = window.open(location.href, "", "resizable=1")!;
  child.addEventListener("load", () => {
    const docElem = child.document.documentElement;
    const dw = 1920 - docElem.clientWidth;
    const dh = 1080 - docElem.clientHeight;
    child.resizeBy(dw, dh);
  });
};

// real Intercom will be inited after login if enabled
// dummy init here, so we can just call Intercom without always checking if it's available or not
window.Intercom = noop as IntercomStatic;

showHelp();
registerGlobalActions();
registerCopyPaste();
setUnauthorizedHandler(async () => {
  await goToLogout();
});

function simpleObject(obj: object) {
  return JSON.parse(JSON.stringify(obj));
}
