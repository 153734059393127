<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { getAlmInfo } from "@/backend/Backend";
import {
  cockpitBillingUrl,
  cockpitProfileUrl,
  cockpitSessionUrl,
} from "@/cockpitUrls";
import { useBoardStore } from "@/store/board";
import { useFlexStore } from "@/store/flex";
import { useLicenseStore } from "@/store/license";
import { useModalStore } from "@/store/modal";
import { useSessionStore } from "@/store/session";
import { useUserStore } from "@/store/user";
import { userActionsMenuOpened } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import DropdownMenu from "./DropdownMenu/DropdownMenu.vue";
import ListItem from "./ListItem/ListItem.vue";
import ListItemSeparator from "./ListItem/ListItemSeparator.vue";
import SvgIcon from "./SvgIcon/SvgIcon.vue";
import UserAvatar from "./UserAvatar.vue";
import SettingsModal from "./modal/SettingsModal.vue";
import ShortcutsModal from "./modal/ShortcutsModal.vue";
import BaseButton from "./ui/BaseButton/BaseButton.vue";
import BaseTooltip from "./ui/BaseTooltip/BaseTooltip.vue";
import InfoMarker from "./ui/InfoMarker/InfoMarker.vue";
import InfoMessage from "./ui/InfoMessage/InfoMessage.vue";

const props = defineProps<{ isUserOnBoard?: boolean }>();

const route = useRoute();

const cockpitUrl = computed(() =>
  cockpitSessionUrl(useBoardStore().board?.type),
);

const currentUser = computed(() => useUserStore().currentUser);
const cockpitAllowed = computed(() => useUserStore().isAllowed("cockpit"));
const canEdit = computed(() => useUserStore().isAllowed("edit"));
const infoLink = computed(() => {
  const board = useBoardStore().board;
  if (!board || board.type !== "flex") {
    return "";
  }
  return useFlexStore().infoLink(board.flexType);
});
const showUserSync = computed(() => useSessionStore().userSync === "unsynced");
const almType = computed(() => getAlmInfo().name);

const { t } = useI18n();
const expirationMessage = computed(() => {
  if (useLicenseStore().isExpired) {
    return t("license.expired");
  }
  const expiresIn = useLicenseStore().willExpireIn;
  if (expiresIn) {
    return t("license.expiredInDays", expiresIn);
  }
  return "";
});
const showExpirationMessage = computed(
  () => expirationMessage.value && useUserStore().isAllowed("cockpit"),
);

function openSettings() {
  useModalStore().open(SettingsModal, {
    attrs: { isUserOnBoard: props.isUserOnBoard },
  });
}

function openShortcuts() {
  useModalStore().open(ShortcutsModal);
}
const handleDropdownOpen = () => {
  trackEvent(userActionsMenuOpened());
};
</script>

<template>
  <DropdownMenu
    v-if="currentUser"
    :offset="{ top: 12 }"
    max-height="auto"
    class="user-actions"
    :is-aria-menu="true"
    @open="handleDropdownOpen"
  >
    <template #trigger="{ isOpen }">
      <BaseTooltip position="bottom" class="tooltip">
        <BaseButton
          id="user-actions-trigger"
          variant="ghost"
          color="grey"
          style="position: relative"
          class="main-button"
          data-dropdown-trigger
          :aria-label="$t('userAction.mainMenu')"
          :aria-expanded="isOpen"
          aria-haspopup="menu"
          aria-controls="user-actions-menu"
        >
          <UserAvatar :user="currentUser" size="big" />
          <InfoMarker
            v-if="showUserSync || expirationMessage"
            level="error"
            :top="-2"
            :right="-2"
          />
        </BaseButton>
        <template #content>
          {{ $t("userAction.mainMenu") }}
        </template>
      </BaseTooltip>
    </template>
    <div id="user-actions-menu" v-keyboard-nav class="dropdown" role="menu">
      <ListItem class="info" static>
        <div>
          <p v-if="currentUser.name">{{ currentUser.name }}</p>
          <p class="email">{{ currentUser.email }}</p>
        </div>
      </ListItem>
      <ListItem v-if="showUserSync" disabled>
        <InfoMessage level="warn">
          {{ $t("userAction.userNotSynced", { almType }) }}
          <br /><br />
          <a :href="cockpitProfileUrl(route.fullPath)">
            {{ $t("userAction.gotoProfileSync") }}
          </a>
        </InfoMessage>
      </ListItem>
      <ListItem v-if="showExpirationMessage" disabled role="menuitem">
        <InfoMessage level="warn">
          {{ expirationMessage }}
          <a target="_blank" :href="cockpitBillingUrl()">
            {{ $t("license.renew") }}
          </a>
        </InfoMessage>
      </ListItem>
      <ListItemSeparator />
      <a
        v-if="canEdit"
        target="_blank"
        :href="cockpitProfileUrl()"
        class="list-item"
        role="menuitem"
        :aria-label="$t('label.mainMenu.profile')"
      >
        <span>
          <SvgIcon name="user-action/profile" width="20" height="20" />
          {{ $t("userAction.profile") }}
        </span>
      </a>
      <a
        v-if="isUserOnBoard && cockpitAllowed"
        target="_blank"
        :href="cockpitUrl"
        class="list-item"
        role="menuitem"
        :aria-label="$t('label.mainMenu.rteCockpit')"
      >
        <span>
          <SvgIcon name="user-action/rte-cockpit" width="20" height="20" />
          {{ $t("userAction.rteCockpit") }}
        </span>
      </a>
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
      <a
        class="list-item"
        role="menuitem"
        tabindex="-1"
        :aria-label="$t('label.mainMenu.boardSettings')"
        @click="openSettings"
      >
        <span>
          <SvgIcon name="user-action/settings" width="20" height="20" />
          {{ $t("userAction.boardSettings") }}
        </span>
      </a>
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
      <a
        class="list-item"
        role="menuitem"
        tabindex="-1"
        :aria-label="$t('label.mainMenu.keyboardShortcuts')"
        @click="openShortcuts"
      >
        <span>
          <SvgIcon name="user-action/shortcuts" width="20" height="20" />
          {{ $t("userAction.keyboardShortcuts") }}
        </span>
      </a>
      <ListItemSeparator />
      <a
        v-if="infoLink"
        :href="infoLink"
        target="_blank"
        class="list-item"
        role="menuitem"
      >
        {{ $t("userAction.tips") }}
      </a>
      <a
        href="https://www.piplanning.io/releases"
        target="_blank"
        class="list-item"
        role="menuitem"
        :aria-label="$t('label.mainMenu.releaseNotes')"
      >
        {{ $t("userAction.releaseNotes") }}
      </a>
      <a
        href="https://www.piplanning.io/courses"
        target="_blank"
        class="list-item"
        role="menuitem"
        :aria-label="$t('label.mainMenu.gettingStarted')"
      >
        {{ $t("userAction.gettingStarted") }}
      </a>
      <a
        href="https://help.piplanning.io"
        target="_blank"
        class="list-item"
        role="menuitem"
        :aria-label="$t('label.mainMenu.documentation')"
      >
        {{ $t("userAction.documentation") }}
      </a>
      <ListItemSeparator />
      <RouterLink
        :to="{ path: '/logout', query: { reason: 'user-invoked' } }"
        class="list-item"
        role="menuitem"
      >
        <span>
          <SvgIcon name="user-action/logout" width="20" height="20" />
          {{ $t("userAction.logOut") }}
        </span>
      </RouterLink>
    </div>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/components/ListItem/listItem";

.user-actions {
  display: flex;
  height: 40px;

  .tooltip {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .main-button {
    padding: 5px;

    :deep(.content) {
      height: unset;
    }
  }

  #user-actions-menu {
    max-width: 22em;

    .info {
      font-weight: font.$weight-bold;

      p {
        margin: 0;
      }

      .email {
        font-weight: font.$weight-light;
        color: colors-old.$text-secondary-color;
        text-transform: none;
      }
    }
  }

  .dropdown {
    padding: 8px 0;
  }

  a {
    text-decoration: none;
  }

  .list-item svg {
    color: colors-old.$text-secondary-color;
  }
}
</style>
