<template>
  <div
    class="flag-picker"
    tabindex="0"
    role="grid"
    :aria-label="$t('general.stickers')"
    @focusout="focusout"
  >
    <span
      v-for="(cardFlagsRow, rowIndex) in visibleCardFlags"
      :key="rowIndex"
      role="row"
      class="flag-picker-row"
    >
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
      <span
        v-for="(cardFlag, columnIndex) in cardFlagsRow"
        :key="cardFlag.toString()"
        role="gridcell"
        class="flag"
      >
        <base-flag
          role="button"
          :flag="cardFlag"
          :class="{
            current: current(rowIndex, columnIndex),
            'flag--selected': isSelected(cardFlag),
          }"
          @pointerenter="setCurrent(rowIndex, columnIndex)"
          @click="select(cardFlag)"
      /></span>
    </span>
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
    <span
      v-if="noStickerOption"
      class="flag-picker-row no-sticker"
      :class="{
        current: current(icons.length),
        'flag--selected': isSelected(noneCardFlag),
      }"
      @pointerenter="setCurrent(icons.length)"
      @click.stop="select(noneCardFlag)"
    >
      <span
        class="no-sticker-button"
        :class="noneFlagIsCurrent ? 'no-sticker-current' : ''"
      >
        <SvgIcon name="stickers" width="18" height="18" />
        <span class="no-sticker-text">{{ $t("flagPicker.noSticker") }}</span>
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import BaseFlag from "@/components/card/BaseFlag.vue";
import ShortcutUser from "@/mixins/ShortcutUser";
import CardFlag, { FlagIcon } from "@/model/CardFlag";
import { flagColors } from "@/model/colors";

@Component({ components: { SvgIcon, BaseFlag }, emits: ["select", "deselect"] })
export default class FlagPicker extends mixins(ShortcutUser) {
  @Prop([Array, Object]) readonly selected!: CardFlag[] | CardFlag | undefined;
  @Prop(Boolean) readonly noStickerOption: boolean | undefined;

  colors = flagColors;
  icons: FlagIcon[] = ["flag", "check", "round", "star", "question", "exclam"];
  coloredCardFlags: CardFlag[][] = [];
  iconIndex = -1;
  colorIndex = -1;

  created() {
    this.shortcut("ArrowUp", () => this.diff(-1, 0), { repeat: true });
    this.shortcut("ArrowDown", () => this.diff(1, 0), { repeat: true });
    this.shortcut("ArrowLeft", () => this.diff(0, -1), { repeat: true });
    this.shortcut("ArrowRight", () => this.diff(0, 1), { repeat: true });
    this.shortcut("Enter", () => this.selectCurrentByKey());
    this.shortcut(" ", () => this.selectCurrentByKey());

    this.icons.forEach((icon, index) => {
      this.coloredCardFlags[index] = this.colors.map(
        (_, colorIndex) => new CardFlag(icon, colorIndex),
      );
    });
    this.coloredCardFlags[this.icons.length] = [CardFlag.emptyFlag()];
  }

  get visibleCardFlags(): CardFlag[][] {
    return this.coloredCardFlags.filter((row) =>
      row.every((cardFlag) => !cardFlag.isEmpty()),
    );
  }

  get noneCardFlag(): CardFlag {
    return this.coloredCardFlags[this.icons.length][0];
  }

  get selectedFlags() {
    if (!this.selected) {
      return [];
    }
    if (Array.isArray(this.selected)) {
      return this.selected;
    }
    return [this.selected];
  }

  get noneFlagIsCurrent() {
    return this.iconIndex === this.icons.length;
  }

  diff(iconDiff: number, colorDiff: number) {
    this.iconIndex = doDiff(
      this.icons.length + (this.noStickerOption ? 1 : 0),
      this.iconIndex,
      iconDiff,
    );
    this.colorIndex = doDiff(this.colors.length, this.colorIndex, colorDiff);

    function doDiff(len: number, value: number, diff: number) {
      if (value < 0) {
        if (diff === 0) {
          return 0;
        }
        if (diff < 0) {
          diff++;
        }
      }
      return (value + diff + len) % len;
    }
  }

  select(cardFlag: CardFlag) {
    this.$emit("select", cardFlag);
  }

  deselect(cardFlag: CardFlag) {
    this.$emit("deselect", cardFlag);
  }

  focusout() {
    this.iconIndex = -1;
    this.colorIndex = -1;
  }

  selectCurrentByKey() {
    if (this.noneFlagIsCurrent) {
      this.select(this.noneCardFlag);
    } else if (this.iconIndex >= 0 && this.colorIndex >= 0) {
      const flag = this.coloredCardFlags[this.iconIndex][this.colorIndex];
      if (this.isSelected(flag)) {
        this.deselect(flag);
      } else {
        this.select(flag);
      }
    }
  }

  setCurrent(iconIndex: number, colorIndex?: number) {
    this.iconIndex = iconIndex;
    this.colorIndex = colorIndex === undefined ? -1 : colorIndex;
  }

  current(iconIndex: number, colorIndex?: number) {
    return (
      this.iconIndex === iconIndex &&
      (colorIndex === undefined || this.colorIndex === colorIndex)
    );
  }

  isSelected(cardFlag: CardFlag) {
    return Boolean(this.selectedFlags.find((flag) => flag.equals(cardFlag)));
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.flag-picker {
  outline: revert;

  &-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 3em;
  }
}

.flag {
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.no-sticker {
  margin-top: 0.75em;

  &-button {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 0.5em 1em;
  }

  &-current {
    background-color: colors-old.$divider-color;
  }
}
</style>
