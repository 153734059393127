<script setup lang="ts">
import { computed } from "vue";

import { linkActions } from "@/action/linkActions";
import StatusDot from "@/components/StatusDot.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/TypeDot.vue";
import BaseFlag from "@/components/card/BaseFlag.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { CardLink } from "@/model/link";
import { useWorkModeStore } from "@/store/workMode";
import {
  isARTWorkItem,
  isDependency,
  isObjective,
  isOtherItem,
  isTeamWorkItem,
} from "@/utils/cardLinks";

const props = defineProps<{ link: CardLink }>();

const isExecutionMode = computed(() => useWorkModeStore().isExecutionMode);

const unlink = () => {
  if (props.link.kind === "sticky") {
    linkActions.remove("card", { linkId: props.link.id }, "link-remove-button");
  } else if (props.link.kind === "objective") {
    linkActions.removeObjective(
      "card",
      props.link.cardId,
      props.link.boardId,
      props.link.objectiveId,
      "link-remove-button",
    );
  }
};
</script>

<template>
  <div class="sticky-link-row-container">
    <!-- ART work item -->
    <template v-if="isARTWorkItem(link)">
      <div class="sticky-link-info-container">
        <div class="first-row">
          <TypeDot class="type-icon" :color="link.color" />
          <BaseTooltip class="text-tooltip" position="bottom">
            <div class="text">{{ link.text }}</div>
            <template #content>{{ link.text }}</template>
          </BaseTooltip>
        </div>
        <div class="second-row">
          <div v-if="isExecutionMode && link.status" class="sticky-item">
            <BaseTooltip position="bottom">
              <StatusDot
                data-testid="art-work-item-status"
                :status-class="link.status.statusClass"
              />
              <template #content>{{ link.status.name }}</template>
            </BaseTooltip>
          </div>
          <div class="secondary-text sticky-item">{{ link.type }}</div>
          <div v-if="link.almId" class="secondary-text sticky-item">
            {{ link.almId }}
          </div>
          <div v-if="link.teamName" class="secondary-text sticky-item">
            {{ link.teamName }}
          </div>
          <div v-if="link.iterationName" class="secondary-text sticky-item">
            {{ link.iterationName }}
          </div>
          <div v-if="link.flag && !link.flag.isEmpty()">
            <div class="flag-container sticky-item">
              <BaseFlag :flag="link.flag" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Dependency -->
    <template v-if="isDependency(link)">
      <div class="sticky-link-info-container">
        <div class="first-row">
          <TypeDot class="type-icon" :color="link.color" />
          <BaseTooltip class="text-tooltip" position="bottom">
            <div class="text">{{ link.text }}</div>
            <template #content>{{ link.text }}</template>
          </BaseTooltip>
        </div>
        <div class="second-row">
          <div class="secondary-text sticky-item">{{ link.type }}</div>
          <div class="secondary-text sticky-item">
            <span v-if="link.precondTeam">
              {{ link.precondTeam.name }}
            </span>
            <span v-else class="no-team">
              {{ $t("linkChip.noPrecondTeam") }}
            </span>
            <SvgIcon
              name="arrow/right-thin"
              class="arrow-icon"
              width="10"
              height="100%"
            />
            <span v-if="link.dependTeam">
              {{ link.dependTeam.name }}
            </span>
            <span v-else class="no-team">
              {{ $t("linkChip.noDependentTeam") }}
            </span>
          </div>
          <div v-if="link.iterationName" class="secondary-text sticky-item">
            {{ link.iterationName }}
          </div>
          <div v-if="link.flag && !link.flag.isEmpty()">
            <div class="flag-container sticky-item">
              <BaseFlag :flag="link.flag" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Team work item -->
    <template v-if="isTeamWorkItem(link)">
      <div class="sticky-link-info-container">
        <div class="first-row">
          <TypeDot class="type-icon" :color="link.color" />
          <BaseTooltip class="text-tooltip" position="bottom">
            <div class="text">{{ link.text }}</div>
            <template #content>{{ link.text }}</template>
          </BaseTooltip>
        </div>
        <div class="second-row">
          <div v-if="isExecutionMode && link.status" class="sticky-item">
            <BaseTooltip position="bottom">
              <StatusDot
                data-testid="team-work-item-status"
                :status-class="link.status.statusClass"
              />
              <template #content>{{ link.status.name }}</template>
            </BaseTooltip>
          </div>
          <div class="secondary-text sticky-item">{{ link.type }}</div>
          <div
            v-if="!isExecutionMode && link.points"
            class="secondary-text sticky-item"
          >
            {{ $t("linkChip.storyPoints", { points: link.points }) }}
          </div>
          <div v-if="link.almId" class="secondary-text sticky-item">
            {{ link.almId }}
          </div>
          <div v-if="link.teamName" class="secondary-text sticky-item">
            {{ link.teamName }}
          </div>
          <div v-if="link.iterationName" class="secondary-text sticky-item">
            {{ link.iterationName }}
          </div>
          <div v-if="link.flag && !link.flag.isEmpty()">
            <div class="flag-container sticky-item">
              <BaseFlag :flag="link.flag" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Objective -->
    <template v-if="isObjective(link)">
      <div class="sticky-link-info-container">
        <div class="first-row">
          <SvgIcon
            class="objective-icon"
            name="stickynote/team-objectives"
            height="16"
            width="16"
          />
          <BaseTooltip class="text-tooltip" position="bottom">
            <div class="text">{{ link.text }}</div>
            <template #content>{{ link.text }}</template>
          </BaseTooltip>
        </div>
        <div class="second-row">
          <div v-if="link.type === 'committed'" class="secondary-text">
            {{ $t("objectives.committed") }}
          </div>
          <div v-else class="secondary-text">
            {{ $t("objectives.uncommitted") }}
          </div>
        </div>
      </div>
    </template>

    <!-- Other -->
    <template v-if="isOtherItem(link)">
      <div class="sticky-link-info-container">
        <div class="first-row">
          <TypeDot class="type-icon" :color="link.color" />
          <BaseTooltip class="text-tooltip" position="bottom">
            <div class="text">{{ link.text }}</div>
            <template #content>{{ link.text }}</template>
          </BaseTooltip>
        </div>
        <div class="second-row">
          <div class="secondary-text sticky-item">{{ link.type }}</div>
          <div v-if="link.teamName" class="secondary-text sticky-item">
            {{ link.teamName }}
          </div>
          <div v-if="link.iterationName" class="secondary-text sticky-item">
            {{ link.iterationName }}
          </div>
          <div v-if="link.flag && !link.flag.isEmpty()">
            <div class="flag-container sticky-item">
              <BaseFlag :flag="link.flag" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <div>
      <IconButton
        variant="ghost"
        icon="unlink"
        data-testid="unlink-button"
        :aria-label="$t('linkableCardRow.removeLink', link.text)"
        :tooltip="$t('linkModal.removeLink')"
        @click="unlink"
        @keydown.enter="unlink"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.sticky-link-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;

  .sticky-link-info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;

    .first-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 20px;
      gap: 8px;

      .objective-icon {
        color: colors-old.$text-secondary-color;
      }

      .type-icon {
        width: 16px;
        height: 16px;
        border-radius: 2px;
      }

      .text-tooltip {
        display: flex;
        height: 100%;

        .text {
          max-width: 570px;
          font-weight: font.$weight-semi-bold;
          font-size: font.$size-normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .second-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 24px;

      .sticky-item {
        display: flex;
        align-items: center;

        &:not(:first-child) {
          padding-left: 8px;
        }

        &:not(:last-child)::after {
          content: "";
          width: 1px;
          height: 8px;
          background-color: colors-old.$divider-color;
          border-radius: 1px;
          margin-left: 8px;
        }
      }

      :deep(.status-dot) {
        height: 14px;
        width: 14px;
      }

      .secondary-text {
        font-weight: font.$weight-medium;
        font-size: font.$size-small;
        color: colors-old.$text-secondary-color;
      }

      .flag-container {
        margin-left: 8px;
      }

      .flag-icon {
        height: 14px;
        padding: 0;
      }

      .no-team {
        border: 1px dashed colors-old.$text-secondary-color;
        border-radius: 8px;
        padding: 0 4px;
      }

      .arrow-icon {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}
</style>
