<template>
  <nav
    class="menu-sidebar"
    :class="menuPosition"
    :aria-label="$t('label.menuSidebar')"
  >
    <div class="turn shift-button">
      <ActionButton
        :action="actions.shiftMenu"
        :disabled="isButtonDisabled"
        position="right"
      />
    </div>
    <menu-item-delimiter horizontal />
    <boards-item class="boards-item" />
  </nav>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { toggleActions } from "@/action/toggleActions";
import ActionButton from "@/components/ui/ActionButton/ActionButton.vue";
import EventBusUser from "@/mixins/EventBusUser";
import { useBoardMenuStore } from "@/store/boardMenu";
import { useSidePanelStore } from "@/store/sidePanel";

import BoardsItem from "./BoardsItem.vue";
import MenuItemDelimiter from "./components/MenuItemDelimiter.vue";

@Component({
  components: {
    ActionButton,
    BoardsItem,
    MenuItemDelimiter,
  },
})
export default class MainMenu extends mixins(EventBusUser) {
  boardStore = useBoardMenuStore();
  sidePanelStore = useSidePanelStore();
  actions = toggleActions;

  @Watch("sidePanelStore.panelLeftOpen")
  onPanelLeftOpen(open: boolean) {
    this.boardStore.setMenuPosition(open ? "right" : "default");
  }

  get menuPosition() {
    return this.boardStore.position;
  }

  get isButtonDisabled() {
    return this.boardStore.disabledShiftButton;
  }
}
</script>

<style lang="scss">
@use "@/styles/z-index";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "@/styles/mixins" as *;

#main-menu.navigation .menu-sidebar {
  position: fixed;
  width: 44px;
  left: 10px;
  font-size: 9px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  background-color: colors-old.$back-color;
  z-index: z-index.$menu-sidebar;

  @include default-shadow;

  border-radius: variables.$common-border-radius;
  padding: 2px;

  .high-contrast-mode & {
    border: 2px solid colors-a11y.$board-border;
  }

  .shift-button {
    display: flex;
  }

  &.right {
    left: auto;
    right: 10px;

    .turn svg {
      transform: rotate(180deg);
    }
  }
}
</style>
