import { defineStore } from "pinia";

export const useGlobalErrorStore = defineStore("globalError", {
  state: () => ({
    globalError: null as {
      error: any;
      timestamp: number;
      message?: string;
    } | null,
  }),
  actions: {
    setGlobalError(error: unknown, message?: string) {
      this.globalError = { error, timestamp: Date.now(), message };
    },
  },
});
