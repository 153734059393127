import { relativeCoord } from "@/model/coordinates";
import cssValue from "@/styles/variable.module.scss";

export const stickySize = parseInt(cssValue.stickySize);
export const fakeZoom = parseInt(cssValue.fakeZoom);

const MILLI_SECONDS = 1;
const SECONDS = 1000;
const MINUTES = 60 * SECONDS;

export const boardAspect = 16 / 9;
export const zoomFactor = relativeCoord(0.018, 0.018 * boardAspect);
export const arrowBoxFactor = 0.982;
export const arrowLen = 0.008;
export const arrowAngle = 2.8;
export const longClick = 0.5 * SECONDS;
export const longClickDist = 50;
export const dragStartDist = 16;
export const minZoom = 1;
export const maxZoom = 10;
export const cardZoomHeight = 0.3333;
export const moveSendPeriod = 5 * SECONDS;
export const moveSendPerPeriod = 50;
export const maxMoveSendPerPeriod = 100;
export const moveReceiveDelay = 45 * MILLI_SECONDS;
export const moveReceiveStep = 0.20001;
export const statHistory = 1000;
export const pointerPosInterval = 60 * MILLI_SECONDS;
export const pointerPosHide = 30 * SECONDS;
export const pointerTrailMaxLen = 50;
export const pointerTrailDotDist = 8;
export const userCacheTimeout = 24 * 60 * MINUTES;
export const usersInTeamCacheTimeout = 60 * MINUTES;
export const keepEndedTimer = 10 * SECONDS;
export const timerEnding = 2 * MINUTES;
export const userHeartbeatInterval = 3 * SECONDS;
export const maxVisibleUsersOnBoard = 4;
export const natsRequestTimeout = 15 * SECONDS;
export const natsTabInactiveRequestTimeout = 60 * SECONDS;
export const iterationSyncTimeout = 2 * MINUTES;
export const backlogSyncTimeout = 5 * MINUTES;
export const updateTimeout = 500 * MILLI_SECONDS;
export const screenShotWidth = 10000;
export const snapDistance = 0.0002;
