import { natsRequestTimeout, natsTabInactiveRequestTimeout } from "@/Settings";
import { useClientStore } from "@/store/client";
import { isBrowserTabActive } from "@/utils/dom";

export interface CallContext extends Record<string, unknown> {
  path: string;
  args: unknown[];
  kwargs?: object;
  correlationId: string;
  tabActiveBefore: boolean;
  requestTimeout: number;
  issuedAt: string;
  retry?: number;
}

export function createCallContext(
  path: string,
  args: unknown[],
  kwargs?: object,
  timeout?: number,
  retry?: number,
): CallContext {
  return {
    path,
    args,
    kwargs,
    correlationId: useClientStore().newCorrelationId(),
    tabActiveBefore: isBrowserTabActive(),
    requestTimeout:
      timeout ||
      (isBrowserTabActive()
        ? natsRequestTimeout
        : natsTabInactiveRequestTimeout),
    issuedAt: new Date().toISOString(),
    retry,
  };
}

export function createCallHeaders(context: CallContext) {
  return {
    "correlation-id": context.correlationId,
    client_version: "20220428r0",
    issued_at: context.issuedAt,
  };
}
