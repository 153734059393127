<script lang="ts" setup>
import { ref } from "vue";

const baseListItem = ref<HTMLElement>();

defineProps<{ static?: boolean; active?: boolean }>();

const focus = () => baseListItem.value?.focus();
defineExpose({ focus });
</script>

<template>
  <button
    ref="baseListItem"
    :class="['base-list-item', { static, active }]"
    role="option"
    :aria-selected="active ? 'true' : 'false'"
    tabindex="-1"
  >
    <div v-if="$slots.before" class="before">
      <slot name="before"></slot>
    </div>
    <div class="content">
      <slot />
    </div>

    <div v-if="$slots.after" class="after">
      <slot name="after" />
    </div>
  </button>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/list";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.base-list-item {
  flex-shrink: 0;
  outline: revert;

  &:not(.static) {
    @include list.item;
  }

  &.static {
    @include list.static-item;
  }

  &.active {
    @include list.active-item;
  }

  .before,
  .after {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &:not(.active) {
    .before,
    .after {
      color: colors-old.$text-secondary-color;
    }
  }

  .content {
    flex-grow: 1;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
}
</style>
