<script setup lang="ts">
import { computed, ref } from "vue";

import RevealHeightTransition from "@/components-ng/transitions/RevealHeightTransition.vue";
import TypeDot from "@/components/TypeDot.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { Color } from "@/model/baseTypes";
import { StickyChange } from "@/model/change";
import { useCardStore } from "@/store/card";
import { useStickyTypeStore } from "@/store/stickyType";

import DiffList from "./DiffList.vue";
import { StickyChangesBySticky, groupBySticky } from "./groupChanges";

const props = defineProps<{ changes: StickyChange[] }>();

const changesBySticky = computed(() => groupBySticky(props.changes));

const expanded = ref<Record<string, boolean>>({});

function card(change: StickyChangesBySticky): { text: string; color: Color } {
  const card = useCardStore().cards[change.id];
  if (card) {
    return { text: card.text, color: card.type.color };
  }
  return {
    text: deleteField(change, "text") || "",
    color: useStickyTypeStore().findStickyType(deleteField(change, "type"))
      ?.color || [0.5, 0.5, 0.5, 1],
  };
}

function deleteField(change: StickyChangesBySticky, name: "text" | "type") {
  const fields = change.changes["delete"]?.[0].fields || [];
  return fields.find((field) => field.name === name)?.old;
}
</script>

<template>
  <div class="diffs-by-sticky">
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
    <div
      v-for="change in changesBySticky"
      :key="change.id"
      class="sticky-change action"
      @click="expanded[change.id] = !expanded[change.id]"
    >
      <div class="sticky">
        <TypeDot size="medium" :color="card(change).color" />
      </div>
      <div class="change-content">
        <span class="text">{{ card(change).text }}</span>
        <RevealHeightTransition>
          <DiffList v-if="expanded[change.id]" :changes="change.changes" />
        </RevealHeightTransition>
      </div>
      <IconButton
        icon="arrow/down"
        width="16"
        height="16"
        class="toggle-icon"
        :aria-label="$t('label.activityPanel.toggleChange')"
        :aria-expanded="expanded[change.id]"
        :class="{ expanded: expanded[change.id] }"
      />
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "changes";

.diffs-by-sticky {
  padding: 0 1em;
  margin-top: 2.5em;
  margin-bottom: 1.5em;

  .sticky-change {
    @include changes.sticky-change;
  }

  .text {
    display: inline-block;
    margin-bottom: 1em;
    overflow: hidden;
    max-height: 2.5em;
  }
}
</style>
