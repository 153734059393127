<template>
  <div ref="rootEl" class="search-item" :class="{ active: isSearchActive }">
    <div class="search-item-grid">
      <div class="search-item-col">
        <IconButton
          v-if="isSearchActive"
          icon="close"
          :aria-label="$t('searchItem.closeSearch')"
          :tooltip="$t('searchItem.closeSearch')"
          @click="toggle"
        />
        <search-bar
          v-model="text"
          class="search-item__search-bar"
          :is-active="isSearchActive"
          :any-filter-criteria="anyFilterCriteria"
          @toggle="toggle"
        />
        <div v-if="isSearchActive" class="search-item-actions">
          <SkipButton @click="focusOnResults">
            {{ $t("label.skipToResults") }}
          </SkipButton>
          <StickyTypeItem />
          <template v-if="store.isDependencySearch">
            <DependencyLinkItem />
            <MenuItemDelimiter />
          </template>
          <StickersItem />
          <IterationItem />
          <AssigneeItem />
          <TeamItem v-if="!isSolutionBoard" />
          <ArtItem v-else />
          <template v-if="showDependencyTeamFilter">
            <DependencyTeamItem />
            <MenuItemDelimiter />
          </template>
          <StatusClassItem />
          <MenuItemDelimiter />
          <RiskyLinksItem />
          <template v-if="anyFilterCriteria">
            <menu-item-delimiter />
            <BaseButton variant="ghost" @click="hide">
              {{ $t("searchItem.hideSearch") }}
            </BaseButton>
          </template>
        </div>
      </div>
      <div @click.stop>
        <side-panel
          side="left"
          :active="isSearchActive"
          name="search"
          resizable
        >
          <search-results
            v-focus-trap="{
              rootEl: $refs.rootEl,
              paused: !!editingCardId,
              allowOutsideClick: true,
              onPostDeactivate,
            }"
            :results="found"
            :selected="currentItem"
            @select="setCurrent"
          />
        </side-panel>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { isEqual } from "lodash-es";
import { Options as Component, mixins } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

import { Key, key } from "@/Shortcuts";
import { linkActions } from "@/action/linkActions";
import { toggleActions } from "@/action/toggleActions";
import SkipButton from "@/components-ng/a11y/SkipButton.vue";
import SidePanel from "@/components/SidePanel/SidePanel.vue";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import EventBusUser, {
  onStickyNoteAction,
  sendStickyNoteAction,
} from "@/mixins/EventBusUser";
import SearchBase from "@/mixins/SearchBase";
import ShortcutUser from "@/mixins/ShortcutUser";
import { Board, isSolutionBoard } from "@/model/board";
import { isDependency } from "@/model/stickyType";
import { SearchQuery } from "@/router/types";
import { useBoardStore } from "@/store/board";
import { getLinkTargetId, useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";

import SearchBar from "./SearchBar.vue";
import SearchItemBase from "./SearchItemBase";
import { CardSearchResult, ItemKey, toSearchResult } from "./SearchResult";
import SearchResults from "./SearchResults.vue";
import ArtItem from "./dropdown/ArtItem.vue";
import AssigneeItem from "./dropdown/AssigneeItem.vue";
import DependencyLinkItem from "./dropdown/DependencyLinkItem.vue";
import DependencyTeamItem from "./dropdown/DependencyTeamItem.vue";
import IterationItem from "./dropdown/IterationItem.vue";
import RiskyLinksItem from "./dropdown/RiskyLinksItem.vue";
import StatusClassItem from "./dropdown/StatusClassItem.vue";
import StickersItem from "./dropdown/StickersItem.vue";
import StickyTypeItem from "./dropdown/StickyTypeItem.vue";
import TeamItem from "./dropdown/TeamItem.vue";

@Component({
  components: {
    SidePanel,
    AssigneeItem,
    BaseButton,
    DependencyTeamItem,
    DependencyLinkItem,
    SearchResults,
    SearchBar,
    StickyTypeItem,
    TeamItem,
    ArtItem,
    StatusClassItem,
    RiskyLinksItem,
    StickersItem,
    IterationItem,
    IconButton,
    MenuItemDelimiter,
    SkipButton,
  },
  emits: ["toggle"],
})
export default class SearchItem extends mixins(
  SearchItemBase,
  ShortcutUser,
  EventBusUser,
  SearchBase,
) {
  store = useSearchMenuStore();
  linkStore = useLinkStore();
  boardStore = useBoardStore();

  // If the user opened the card for editing directly from the search,
  // save the id of the card being edited so we can focus on the result item after the card is closed
  editingCardId: string | null = null;

  setCurrent(e: ItemKey, focus?: boolean) {
    const index = this.found.findIndex((result) => result.id === e.id);
    if (index >= 0) {
      this.setFound(index + 1 === this.foundIndex ? 0 : index + 1, focus);
    }
  }

  updateSearch() {
    this.update();
    this.updateRoute(this.foundIndex);
  }

  update() {
    // TODO make the board responsible for marking search results? (like objectives board)
    this.search(this.cards, (card) => this.matches(this.currentBoard, card), {
      mapResult: toSearchResult,
      sortBy: (result) => -result.card!.meta.pos.y,
    });

    if (this.store.isDependencySearch) {
      this.highlightDependencyLinkedStickies();
    }
  }

  /** highlights the stickies which are related to a dependency that matches the search */
  highlightDependencyLinkedStickies() {
    // because generic mixins don't work (or how?)
    const found = this.found as CardSearchResult[];
    found.forEach((result) => {
      const card = result.card;
      if (!card || !isDependency(card.data)) {
        return;
      }
      card.data.links.forEach((link) => {
        const linkedCard = useLinkStore().boardCardByLink(
          getLinkTargetId(card.data, link),
          this.currentBoard,
        );
        if (linkedCard) {
          linkedCard.meta.mark = "normal";
        }
      });
    });
  }

  @Watch("boardStore.enlargedStickyNoteId")
  enlargedStickyNoteIdChanged() {
    if (this.boardStore.enlargedStickyNoteId === null) {
      this.resetSelectedCard();

      if (this.editingCardId) {
        // If the user opened the card for editing directly from the search,
        // focus on that result after the card is closed
        this.$el.querySelector(`#result-${this.editingCardId}`)?.focus();
        this.editingCardId = null;
      }
    }
  }

  get showDependencyTeamFilter() {
    return this.store.showDependencyTeamFilter;
  }

  /**
   * Marks or unmarks a card as found
   * @param item: The card to enlarge/shrink
   * @param mark: Enlarge the sticky if true, shrink if false
   * @param focus: If true (and mark is true), focus on the sticky for editing
   */
  markFound(item: CardSearchResult, mark: boolean, focus: boolean) {
    if (item?.card) {
      if (mark) {
        sendStickyNoteAction(item.card.data.id, {
          action: "enlarge",
          focus,
          trigger: "search-item-selected",
        });

        this.editingCardId = focus ? item.id : null;
      } else {
        sendStickyNoteAction(item.card.data.id, { action: "shrink" });
      }
    }
  }

  get currentItem() {
    return {
      id: this.foundItem()?.id,
      teamId: this.foundItem()?.teamId,
    };
  }

  get isSearchActive() {
    return this.store.isSearchActive;
  }

  get anyFilterCriteria() {
    return this.store.anyFilterCriteria;
  }

  get isSolutionBoard() {
    return isSolutionBoard(useBoardStore().currentBoard().type);
  }

  bindSearchEvents() {
    this.onSearch((value: SearchQuery) => {
      if (this.applySearchQuery(value)) {
        this.store.expandSearch();
      }
    });
  }

  created() {
    this.defineKeybindings();
    this.bindSearchEvents();
  }

  mounted() {
    this.onBoardLoaded((boardId: Board["id"]) => {
      const isCurrentBoard = useBoardStore().currentBoard().id === boardId;
      if (isCurrentBoard && this.isSearchActive) {
        this.update();
      }
    });

    this.onBoardSwitch(this.update);

    // TODO could be optimized by only matching the changed sticky to the search
    onStickyNoteAction(null, "changed", () => {
      if (this.isSearchActive) {
        this.update();
      }
    });

    this.update();
  }

  defineKeybindings() {
    this.globalActionShortcut(toggleActions.showSearch);
    // KeyY would be dependent on keyboard language
    this.globalShortcut(key("f", "altCtrl"), () => {
      this.store.expandSearch();
    });
    this.openShortcut("Escape", () => {
      this.toggle();
    });
    this.openShortcut("Enter", () => {
      const found = this.foundItem();
      if (found && found.boardId !== this.currentBoard.id) {
        this.setCurrent({ id: found.id });
      }
    });
    this.openShortcut("ArrowUp", (e: KeyboardEvent) => {
      this.findNext(-1);
      e.preventDefault();
    });
    this.openShortcut("ArrowDown", (e: KeyboardEvent) => {
      this.findNext(1);
      e.preventDefault();
    });
  }

  openShortcut(k: Key, handler: (e: KeyboardEvent) => void) {
    this.globalShortcut(
      k,
      (e) => {
        if (this.isSearchActive) {
          handler(e);
        }
      },
      { prevent: false }, // Preventing default stops enter from acting as a click (globally)
    );
  }

  get currentBoard() {
    return useBoardStore().currentBoard();
  }

  get cards() {
    return this.currentBoard.cards;
  }

  get searchQuery() {
    return [
      this.text,
      this.store.id,
      this.types,
      this.assignees,
      this.teams,
      this.arts,
      this.iterations,
      this.selectedRiskyLinks,
      this.selectedStatusClasses,
      this.flags,
      this.selectedDependencyLink,
      this.selectedDependencyTeamFilter,
    ];
  }

  @Watch("searchQuery", { deep: true })
  searchChanged(value: unknown[], oldValue: unknown[]) {
    // simple optimization: ignore search criteria "change" from empty array to empty array
    if (value?.length === 0 && oldValue?.length === 0) {
      return;
    }

    // Avoid unnecessary update if the search criteria didn't change
    // If we do an extra search here, it can mess up cards' faded states when a card is pinned
    if (isEqual(value, oldValue)) {
      return;
    }

    // shrink the enlarged sticky note if search criteria changed
    const id = this.boardStore.enlargedStickyNoteId;
    if (id) {
      sendStickyNoteAction(id, { action: "shrink" });
    }

    this.updateSearch();
  }

  @Watch("linkStore.isMarkingLinks")
  markingLinksChanged(marking: boolean) {
    if (this.store.anyFilterCriteria && !marking) {
      this.updateSearch();
    }
  }

  @Watch("store.anyFilterCriteria")
  anyFilterCriteriaChanged(filterCriteria: boolean) {
    if (!filterCriteria) {
      linkActions.updateCardLinkedMarks("internal");
    }
  }

  @Emit()
  toggle() {
    if (this.isSearchActive) {
      const id = this.boardStore.enlargedStickyNoteId;
      if (id) sendStickyNoteAction(id, { action: "shrink" });

      this.clearSearchCriteria();
      this.resetSelectedCard();
    }

    toggleActions.showSearch("internal", "topbar-menu");
  }

  hide() {
    if (this.isSearchActive) {
      toggleActions.showSearch("internal", "topbar-menu", true);
    }
  }

  resetSelectedCard() {
    this.setFound(0);
  }

  /**
   * Move focus to the first section of search results
   */
  focusOnResults() {
    this.$el.querySelector("[data-search-results-anchor]")?.focus();
  }

  /**
   * Called after the search panel is closed, to return focus to the trigger
   */
  onPostDeactivate() {
    document.getElementById("search-bar-trigger")?.focus();
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/z-index";

.search-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__search-bar {
    max-height: 40px;
    position: relative;
  }

  &.active {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: var(--back-color);
    height: 100%;
    z-index: z-index.$low;
  }

  &-grid {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-actions,
  &-col {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &-col {
    &:nth-child(2) {
      margin-left: auto;
    }
  }

  .search-item-actions {
    margin-left: 9px;
  }
}
</style>

<style lang="scss">
.search-select {
  &.dropdown-content {
    width: 200px;
  }
}
</style>
