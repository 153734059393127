import { Options, mixins } from "vue-class-component";

import { applyFullBoardSize } from "@/lowlevel";
import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import { Board } from "@/model/board";

@Options({})
export default class BoardBase extends mixins(ShortcutUser, EventBusUser) {
  active = false;

  // must be overridden by subclasses
  get board(): Board {
    return {} as Board;
  }

  activated() {
    this.applyBoardSize();
    this.active = true;
  }

  deactivated() {
    this.active = false;
  }

  applyBoardSize() {
    applyFullBoardSize();
  }

  // This must be callable by the parent component
  focus() {
    this.$el?.focus();
  }
}
