import { nextTick, ref } from "vue";

import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { sendCardMove } from "@/components/card/animator";
import { CardMetaInjected } from "@/components/card/injectKeys";
import { i18n } from "@/i18n";
import { cardInsideBoardRelative } from "@/math/coordinate-systems";
import { Card } from "@/model/card";
import {
  RelativeCoordinate,
  divided,
  plus,
  relativeCoord,
  times,
} from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import {
  stickyNoteDragEnded,
  stickyNoteDragMoved,
  stickyNoteDragStarted,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

export type Props = Readonly<{
  card: Card;
  cardMeta: CardMetaInjected;
  deactivate: () => void;
}>;

export function useKeyboardDrag({ card, cardMeta, deactivate }: Props) {
  const { t } = i18n.global;
  const moveMode = ref(false);
  const screenReaderMessage = ref<string>("");
  const boardStore = useBoardStore();

  /**
   * Treat the user moving the sticky by keyboard as a drag operation
   * (so eg. the action menu is hidden)
   */
  const startMove = () => {
    cardActions.startAlter("keyboardDrag", card.id);
    boardActions.cardToFront("card", card.id);
    screenReaderMessage.value = t("label.moveSticky.controlsActivated");
    trackEvent(
      stickyNoteDragStarted(
        boardStore.currentBoard().type,
        "accessibility-controls",
      ),
    );

    useDraggingStore().startCardDragging(card.id, {}, cardMeta.pos);
    moveMode.value = true;
  };
  /**
   * Returns the current position of the sticky note, taking into account the current drag position
   */
  const getCurrentPos = (): RelativeCoordinate => {
    const dragCoords = { ...useDraggingStore().findById(card.id)?.pos };
    return relativeCoord(
      dragCoords.x || cardMeta.pos.x,
      dragCoords.y || cardMeta.pos.y,
    );
  };

  /**
   * Key handler for the sticky note accessibility controls
   * If user is in moveMode, passes the event to handleMoveKey
   * Otherwise:
   * - Enter/Space/M: Start moving the sticky note
   * - Escape: Deactivate (focus on sticky)
   */
  const handleKeydown = (event: KeyboardEvent) => {
    if (moveMode.value) {
      event.preventDefault();
      event.stopImmediatePropagation();

      handleMoveKey(event);
    } else if (["Enter", " ", "m"].includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();

      startMove();
    } else if (event.key === "Escape") {
      event.stopPropagation();
      event.preventDefault();

      deactivate();
    }
  };

  /**
   * Handle the keyboard interaction while user is moving the sticky note
   * - Escape: Cancel the move (reset to original position)
   * - Enter/Space: Save the new position and stop dragging
   * - M: Stop dragging, save the new position, and focus on the sticky
   * - Arrow keys: Move the sticky note in the given direction
   */
  const handleMoveKey = (event: KeyboardEvent) => {
    switch (event.key) {
      case "Escape":
        useDraggingStore().dragCard(card.id, cardMeta.pos);
        stopMove();
        screenReaderMessage.value = t("label.moveSticky.moveCancelled");
        break;
      case "Enter":
      case " ":
        stopMove();
        break;
      case "m":
        // Save the new position, stop dragging, and focus on the sticky
        stopMove();
        deactivate();
        break;
      case "ArrowUp":
        moveSticky(0, -1);
        break;
      case "ArrowDown":
        moveSticky(0, 1);
        break;
      case "ArrowLeft":
        moveSticky(-1, 0);
        break;
      case "ArrowRight":
        moveSticky(1, 0);
        break;
      default:
        break;
    }
  };

  /**
   * Move the sticky note in the given direction
   * (in increments of 1/3 of the current card size)
   * and announce the change to screen reader users
   */
  const moveSticky = async (x: number, y: number) => {
    const distance = divided(boardStore.currentBoard().cardSize, 3);
    const currentPos = getCurrentPos();
    const movedPos = plus(
      currentPos,
      times(relativeCoord(x, y), distance.x, distance.y),
    );
    const [newPos, delta] = cardInsideBoardRelative(movedPos);

    sendCardMove(card.id, useBoardStore().currentBoard().id, newPos);
    useDraggingStore().dragCard(card.id, newPos);

    trackEvent(
      stickyNoteDragMoved(
        boardStore.currentBoard().type,
        "accessibility-controls",
      ),
    );

    /*
      Finally, communicate the changes to screen-reader users
    */

    const originalSection = boardStore.boardLocation(currentPos);
    const newSection = boardStore.boardLocation(newPos);

    const reachedEdge = delta.x || delta.y;

    // If the sticky note is moved into a new section, announce it
    if (originalSection.index().toString() !== newSection.index().toString()) {
      screenReaderMessage.value = t("label.moveSticky.intoSection", {
        from: originalSection.name,
        to: newSection.name,
      });
    } else if (reachedEdge) {
      // If the sticky note is at the edge of the board, let the user know
      screenReaderMessage.value = t("label.moveSticky.edgeOfBoard");
    } else {
      // otherwise announce how far across the board the sticky is
      const left = Math.round(newPos.x * 100);
      const top = Math.round(newPos.y * 100);
      const msgPosition = { top, left, section: newSection.name };

      screenReaderMessage.value = t("label.moveSticky.moved", msgPosition);
    }

    // Scroll the sticky note into view (after the new position is rendered)
    await nextTick();
    document.getElementById(card.id)?.scrollIntoView({ block: "nearest" });
  };

  /**
   * If moving, end the drag operation and save the new position
   */
  const stopMove = () => {
    if (moveMode.value) {
      screenReaderMessage.value = t("label.moveSticky.controlsDeactivated");
      const newPos = getCurrentPos();

      useDraggingStore().endCardDragging(card.id, newPos);
      sendCardMove(card.id, boardStore.currentBoard().id, newPos);
      cardActions.stopAlter("internal", card.id);
      trackEvent(
        stickyNoteDragEnded(
          boardStore.currentBoard().type,
          "accessibility-controls",
        ),
      );
    } else {
      screenReaderMessage.value = "";
    }

    moveMode.value = false;
  };

  return { screenReaderMessage, moveMode, handleKeydown, startMove, stopMove };
}
