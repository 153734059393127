type StyleObject = Record<string, string>;

function removeNodesWithAttribute(
  element: HTMLElement,
  attributeName: string,
): void {
  element
    .querySelectorAll(`[${attributeName}]`)
    .forEach((node) => node.remove());
}

function applyTransitionStyles(
  element: HTMLElement,
  styles: StyleObject,
  duration: string,
): void {
  element.style.transition = Object.keys(styles)
    .map((prop) => `${prop} ${duration}`)
    .join(", ");

  Object.entries(styles).forEach(([property, value]) => {
    if (property === "transform" && element.style.transform) {
      element.style.transform = `${element.style.transform} ${value}`.trim();
    } else {
      element.style[property as any] = value;
    }
  });
}

function animateElement(
  el: HTMLElement,
  styles: StyleObject,
  duration: string,
): Promise<void> {
  return new Promise((resolve) => {
    removeNodesWithAttribute(el, "data-no-animate");

    requestAnimationFrame(() => {
      applyTransitionStyles(el, styles, duration);

      el.addEventListener("transitionend", () => resolve(), { once: true });
      el.addEventListener("transitioncancel", () => resolve(), { once: true });
    });
  });
}

export async function move(el?: HTMLElement) {
  if (!el) return;
  const styles = { transform: "translateX(100px)", opacity: "0" };
  await animateElement(el, styles, "1s");
}

export async function mirror(el?: HTMLElement) {
  if (!el) return;
  const elCopy = el.cloneNode(true) as HTMLElement;
  el.insertAdjacentElement("afterend", elCopy);

  const styles = { transform: "translateX(100px)", opacity: "0" };
  await animateElement(elCopy, styles, "1s");
  elCopy.remove();
}

export async function remove(el?: HTMLElement) {
  if (!el) return;
  await animateElement(el, { opacity: "0" }, ".5s");
}
