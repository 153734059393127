<script setup lang="ts">
import { sortBy } from "lodash-es";
import { onMounted, ref, watch } from "vue";

import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import SearchInput from "@/components-ng/SearchInput/SearchInput.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import UnmappedUserWarning from "@/components/card/components/UnmappedUserWarning/UnmappedUserWarning.vue";
import { cardKey } from "@/components/card/injectKeys";
import IconUser from "@/icons/user.svg?component";
import { AuthUser } from "@/model/user";
import { searchUsers, usersInTeam } from "@/services/user.service";
import { useTeamStore } from "@/store/team";
import { injectStrict } from "@/utils/context";

type User = AuthUser & { foreign: boolean };

defineProps<{
  selected: AuthUser | null;
  title?: string;
}>();

const card = injectStrict(cardKey);

const currentTeam = useTeamStore().team.current;

const emit = defineEmits<{ select: [AuthUser | null] }>();
const users = ref<User[]>([]);

const searchVal = ref<string>("");
const teamUsers = ref<User[]>([]);

onMounted(async () => {
  const rawUsers = await usersInTeam(currentTeam);
  teamUsers.value = [...rawUsers.map((user) => ({ ...user, foreign: false }))];
});

watch(
  [searchVal, teamUsers],
  async () => {
    users.value = sortBy(
      searchVal.value.length < 2
        ? teamUsers.value
        : [...filteredTeamUsers(), ...(await searchNonTeamUsers())],
      ["foreign", "name"],
    );
  },
  { immediate: true },
);

function filteredTeamUsers() {
  return teamUsers.value.filter((user) =>
    user.name.toLowerCase().includes(searchVal.value.toLowerCase()),
  );
}

async function searchNonTeamUsers() {
  const users = await searchUsers(searchVal.value);
  return users
    .filter((user) => !teamUsers.value.find((tu) => tu.id === user.id))
    .map((user) => ({
      ...user,
      foreign: !user.teams.find((team) => team.id === currentTeam.id),
    }));
}
</script>

<template>
  <UnmappedUserWarning :user="card.assignee" />
  <BaseList
    class="user-selector"
    aria-labelledby="user-selector-title"
    role="dialog"
  >
    <BaseListTitle id="user-selector-title">{{ title }}</BaseListTitle>
    <div class="search-container">
      <SearchInput
        v-model="searchVal"
        autofocus
        icon-position="after"
        size="small"
        :placeholder="$t('action.searchUsers')"
        :icon-title="$t('label.userSelector.searchTitle')"
        :results-count="users?.length"
        @focusout.stop
      />
    </div>

    <BaseListItems aria-labelledby="user-selector-title">
      <BaseListItem @click.stop="emit('select', null)">
        <template #before>
          <div class="unassign-container">
            <IconUser />
          </div>
        </template>
        <span>{{ $t("general.unassigned") }}</span>
      </BaseListItem>

      <BaseListItem
        v-for="user in users"
        :key="user.id"
        class="list-item"
        :class="{ active: user.id === selected?.id }"
        @click.stop="emit('select', user)"
      >
        <template #before>
          <UserAvatar :user="user" />
        </template>
        <span :class="{ 'team-user': !user.foreign }">{{ user.name }}</span>
      </BaseListItem>
    </BaseListItems>

    <p v-if="!users.length" class="no-results list-item static">
      {{ $t("searchResults.noResults") }}
    </p>
  </BaseList>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.user-selector {
  max-height: 330px;

  .search-container {
    padding: 8px;
  }

  .unassign-container {
    width: 24px;
    text-align: center;

    svg {
      width: 20px;
    }
  }

  .team-user {
    font-weight: font.$weight-bold;
  }
}
</style>
