import { computed, nextTick, reactive } from "vue";
import { Provide, Watch } from "vue-property-decorator";

import * as Gestures from "@/Gestures";
import { dragScroll } from "@/Gestures";
import { fakeZoom, longClick } from "@/Settings";
import { keys, registerShortcut } from "@/Shortcuts";
import { ActionSource } from "@/action/actions";
import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { applyFluidBoardSize } from "@/lowlevel";
import { calcBoardSize } from "@/math/coordinate-systems";
import { BoardCard } from "@/model/card";
import {
  RelativeCoordinate,
  boardCoord,
  clientCoord,
} from "@/model/coordinates";
import { Size } from "@/model/size";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { useContextMenuStore } from "@/store/contextMenu";
import { useDrawStore } from "@/store/draw";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { useSelectionStore } from "@/store/selection";
import { useToastStore } from "@/store/toast";
import { useUserStore } from "@/store/user";
import { useZoomStore } from "@/store/zoom";

import BoardBase from "./BoardBase";
import { BoardMeta, boardKey, boardMetaKey } from "./card/injectKeys";
import BoardContextMenu, {
  BoardContextMenuMode,
} from "./menu/BoardContextMenu.vue";
import ConfirmArrangeModal from "./modal/ConfirmArrangeModal.vue";

export interface ContextInfo extends BoardContextActions {
  selection?: SelectionContextActions;
  region?: { name: string } & RegionContextActions;
}

const emptyContextInfo: ContextInfo = {
  syncProgramBacklog: false,
  draw: false,
};

export type ContextActions = BoardContextActions &
  SelectionContextActions &
  RegionContextActions;

interface BoardContextActions {
  syncProgramBacklog: boolean;
  draw: boolean;
}

interface SelectionContextActions {
  stickyMove: boolean;
  link: boolean;
  mirror: boolean;
  team: boolean;
}

interface RegionContextActions {
  arrange: boolean;
  overview: boolean;
  sync: boolean;
  zoom: boolean;
}

export interface CardSize extends Size {
  id: string;
}

interface ContextActionEvent {
  source: ActionSource;
}

export interface LocatedContextActionEvent extends ContextActionEvent {
  coord: RelativeCoordinate;
}

export default class FluidBoard extends BoardBase {
  zoomed = false;
  fixedFS = 0;
  boardSize = { width: 0, height: 0 };
  boardStore = useBoardStore();
  levelOfDetails: 0 | 1 | 2 = 0;

  @Provide({ to: boardMetaKey as symbol })
  boardMeta: BoardMeta = reactive({
    size: boardCoord(this.width, this.height),
  });

  @Watch("height")
  @Watch("width")
  onPropertyChange() {
    this.boardMeta.size.x = this.width;
    this.boardMeta.size.y = this.height;
  }

  @Provide({ to: boardKey as symbol })
  get boardData() {
    return computed(() => useBoardStore().currentBoard());
  }

  mounted() {
    const el = this.$el as HTMLElement;
    Gestures.onLongClick(el, longClick, (e) => {
      if (!useDrawStore().active) {
        if (useBoardStore().enlargedStickyNoteId) return;
        cardActions.add("mouse", { pos: e.pos }, true, "long-press-on-board");
      }
    });
    // TODO this is done multiple times, avoid!
    dragScroll(el);
    el.addEventListener("contextmenu", (e) => {
      const target = e.target as HTMLElement;
      // don't open the context menu when clicking on the sticky note > textarea
      if (target?.classList.contains("sticky-note-text-input")) return;

      useContextMenuStore().open(BoardContextMenu, {
        position: clientCoord(e),
        mode: "mouse" as BoardContextMenuMode,
      });
    });
    registerShortcut(
      this,
      keys("Shift"),
      () => {
        // Blur the active element if it was recently dragged (to prevent focus ring from appearing when shift-selecting)
        const activeEl = document.activeElement as HTMLElement;
        if (activeEl?.hasAttribute("data-blur-on-shift")) {
          activeEl.blur();
        }

        useSelectionStore().selecting = "hover";
        el.style.cursor = "copy";

        const timeout = setTimeout(() => {
          if (useBoardStore().selectedStickyIds.length === 0) {
            useToastStore().show(/*$t*/ "message.selectStickyHint");
          }
        }, 1000);

        // Don't show toast if user is shift-tabbing or releases shift
        document.addEventListener(
          "keyup",
          (e: KeyboardEvent) => {
            if (e.key === "Tab" || e.key === "Shift") clearTimeout(timeout);
          },
          { once: true },
        );
      },
      {
        up() {
          useSelectionStore().selecting = "no";
          el.style.cursor = "auto";
        },
      },
    );
    registerShortcut(
      this,
      keys("Meta", "Control"),
      () => {
        useSelectionStore().singleCard = true;
        setTimeout(() => {
          if (
            useSelectionStore().singleCard &&
            useBoardStore().selectedStickies.length > 1
          ) {
            useToastStore().show(/*$t*/ "message.singleCardMode");
          }
        }, 1000);
      },
      {
        up() {
          useSelectionStore().singleCard = false;
        },
      },
    );
    registerShortcut(
      this,
      " ",
      () => {
        useBoardStore().magnifying = true;
        el.style.cursor = "zoom-in";
        this.zoomed = false;

        setTimeout(() => {
          if (!this.zoomed) {
            useToastStore().show(/*$t*/ "message.keepBarPressed");
          }
        }, 1000);
      },
      {
        up() {
          useBoardStore().magnifying = false;
          el.style.cursor = "auto";
        },
      },
    );
  }

  activated() {
    window.addEventListener("resize", this.onWindowResize);
  }

  deactivated() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize() {
    this.applyBoardSize();
  }

  blur() {
    this.$el?.blur();
  }

  get appSize() {
    return useAppSizeStore().appSize;
  }

  get zoomFactor() {
    return useZoomStore().factor;
  }

  get boardHasCards() {
    return Object.keys(this.board.cards).length > 0;
  }

  get isZooming() {
    return useZoomStore().zooming;
  }

  @Watch("isZooming")
  @Watch("boardStore.board.cardSize.factor")
  @Watch("boardHasCards")
  async onLevelOfDetailsChange() {
    await nextTick();

    if (!this.boardHasCards || this.isZooming) return;

    const boardEl = this.$el as HTMLElement;
    const sticky = boardEl.querySelector<HTMLDivElement>(".sticky-note");
    if (!sticky) return;

    const currentSize = sticky.getBoundingClientRect().width;
    if (currentSize === 0) return;

    if (currentSize <= 50) {
      this.levelOfDetails = 0;
    } else if (currentSize > 50 && currentSize <= 90) {
      this.levelOfDetails = 1;
    } else {
      this.levelOfDetails = 2;
    }
  }

  @Watch("boardStore.board")
  onBoardChange() {
    // clear all pinned sticky notes when changing the board
    useLinkStore().removeAllMarks();
  }

  get height() {
    return this.boardSize.height * fakeZoom;
  }

  get width() {
    return this.boardSize.width * fakeZoom;
  }

  get fixedFontSize() {
    if (this.active) {
      this.fixedFS = 100 / this.zoomFactor;
    }
    return this.fixedFS;
  }

  get readOnly() {
    return !useUserStore().isAllowed("edit");
  }

  @Watch("appSize.padding.left")
  @Watch("appSize.padding.top")
  @Watch("appSize.margin.left")
  @Watch("appSize.margin.right")
  onSpaceChange() {
    if (useBoardStore().isCurrentBoardFluid) {
      this.applyBoardSize(true);
    }
  }

  @Watch("zoomFactor")
  onZoom() {
    this.applyBoardSize();
  }

  applyBoardSize(smooth = false) {
    const newBoardSize = calcBoardSize();

    this.boardSize.height = newBoardSize.height;
    this.boardSize.width = newBoardSize.width;

    applyFluidBoardSize(newBoardSize, smooth);
    useAppSizeStore().setAppSize(newBoardSize);
  }

  getRelativeCardSizes(): CardSize[] {
    const res: Array<CardSize & { order: number }> = [];

    const selector = ".board > .sticky-note";

    const elements = document.querySelectorAll<HTMLDivElement>(selector);

    elements.forEach((el) => {
      const id = el.getAttribute("id");
      const zIndex = parseInt(el.style.zIndex);

      if (!id) {
        // eslint-disable-next-line no-console
        console.error("No id found");
        return;
      }

      const card = this.board.cards[id];
      const order = zIndex || card.meta.zIndex;
      res.push(this.calcRelativeCardSize(card, order));
    });

    return res.sort((a, b) => a.order - b.order);
  }

  calcRelativeCardSize(
    card: BoardCard,
    order: number,
  ): CardSize & { order: number } {
    return {
      id: card.data.id,
      left: card.meta.pos.x - this.board.cardSize.x / 2,
      top: card.meta.pos.y - this.board.cardSize.y / 2,
      width: this.board.cardSize.x,
      height: this.board.cardSize.y,
      order,
    };
  }

  overview(_: unknown, _source: ActionSource) {
    throw Error("Implement in board");
  }

  contextActions(_c?: RelativeCoordinate): ContextInfo {
    return emptyContextInfo;
  }

  doContextAction(action: keyof ContextActions, coord: RelativeCoordinate) {
    const name = action + "Action";
    const target = (this as any)[name] as (
      event: LocatedContextActionEvent,
    ) => void;
    if (typeof target === "function") {
      return target({ coord, source: "contextMenu" });
    }
  }

  //default context action implementations
  arrangeAction(event: LocatedContextActionEvent) {
    const loc = useBoardStore().boardLocation(event.coord);
    useModalStore().open(ConfirmArrangeModal, {
      attrs: {
        boardId: this.board.id,
        location: loc.index(),
      },
    });
  }

  overviewAction(event: LocatedContextActionEvent) {
    this.overview(useBoardStore().boardLocation(event.coord), event.source);
  }

  zoomAction(event: LocatedContextActionEvent) {
    boardActions.zoomToRegion(
      event.source,
      useBoardStore().boardLocation(event.coord).bounds,
    );
  }
}

export function fluidBoard(board: BoardBase) {
  return (board as any).boardComponent?.() || board;
}
