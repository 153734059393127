import * as amplitude from "@amplitude/analytics-browser";

import {
  amplitudeApiKey,
  amplitudeDebug,
  environment,
  getConfig,
  isCloud,
} from "@/Environment";
import { captureMessage } from "@/sentry";
import { useLicenseStore } from "@/store/license";
import { useUserStore } from "@/store/user";
import { toKebabCase } from "@/utils/text";

import { AnalyticsEvent } from "./events";

type CustomAmplitudeUserProperty = "company" | "role" | "plan" | "language";

export const trackEvent = (params: AnalyticsEvent) => {
  const { event, ...data } = params;

  if (amplitudeDebug) {
    // eslint-disable-next-line no-console
    console.info("trackEvent", event, data);
  }

  const formattedData = formatEventData(data);

  const valid = isValidEventData(formattedData);
  if (!valid && environment !== "production") {
    // eslint-disable-next-line no-console
    console.warn("Invalid event data", { event, data });
  }

  if (!valid) {
    captureMessage("Invalid event data", { data: { params } });
    return;
  }

  if (!shouldTrack()) return;

  amplitude.track(event, formattedData);
};

/**
 * Format all strings in data to kebab case and all nullish values to null
 * (we need this to handle some existing values, eg. solution_backlog)
 */
function formatEventData(data: Partial<AnalyticsEvent>) {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      if (typeof value === "string") {
        return [key, toKebabCase(value)];
      }

      if (value === null || value === undefined) {
        return [key, null];
      }

      return [key, value];
    }),
  );
}

// validate event data
function isValidEventData(
  data: Record<string, string | number | boolean>,
): boolean {
  const kebabCaseRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/;

  return Object.values(data).every((v) => {
    // must be kebab case
    if (typeof v === "string") {
      return kebabCaseRegex.test(v);
    }

    // must be a finite number
    if (typeof v === "number" && isFinite(v)) {
      return true;
    }

    // must be a boolean
    if (typeof v === "boolean") {
      return true;
    }

    // allow null values
    if (v === null) {
      return true;
    }

    return false;
  });
}

export function identifyUser() {
  if (!amplitudeApiKey) return;

  amplitude.init(amplitudeApiKey, {
    defaultTracking: false,
    serverZone: "EU",
    trackingOptions: {
      ipAddress: false,
    },
  });
  amplitude.setUserId(useUserStore().technicalUser.id);

  const { type, plan } = useLicenseStore().license;
  const { company, role } = useUserStore().technicalUser;

  const identifyEvent = new amplitude.Identify();
  identifyEvent.set("company", company);
  identifyEvent.set("role", role);
  identifyEvent.set("type", type);
  identifyEvent.set("isLicenseExpired", useLicenseStore().isExpired);
  identifyEvent.set("plan", plan);
  identifyEvent.set("build_hash", getConfig().build.hash);

  const lang = useUserStore().preferredLanguage;
  if (lang) {
    identifyEvent.set("language", lang);
  }

  amplitude.identify(identifyEvent);
}

/**
 * Set any custom amplitude user property
 * (the new value will be added to all future events)
 *
 * @param property Property name
 * @param value Property value
 */
export function setAmplitudeUserProperty(
  property: CustomAmplitudeUserProperty,
  value: amplitude.Types.ValidPropertyType,
) {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set(property, value);
  amplitude.identify(identifyEvent);
}

const shouldTrack = () => {
  return isCloud && useLicenseStore().license.tracking;
};
