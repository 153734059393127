import { reactive } from "vue";

import * as Environment from "@/Environment";
import { userCacheTimeout, usersInTeamCacheTimeout } from "@/Settings";
import { getAlmInfo } from "@/backend/Backend";
import { CancelError } from "@/backend/CancelError";
import { UnauthenticatedError } from "@/backend/UnauthenticatedError";
import { mapUser, mapUserTeams } from "@/backend/mapper/mapBackendData";
import { ServerAuthUser, ServerUserTeam } from "@/backend/serverModel";
import { IdMap } from "@/model/baseTypes";
import { Team } from "@/model/session";
import {
  AlmUser,
  AuthUser,
  TechnicalUser,
  almUser,
  backendUser,
  isBackendUserId,
  uninitedUser,
} from "@/model/user";
import { captureMessage } from "@/sentry";

import { createApiClient, modernInterceptor } from "./api.config";

const emptyListApi = modernInterceptor(
  createApiClient(Environment.authAPIUrl),
  {
    defaultValue: { results: [] },
  },
);
const api = modernInterceptor(createApiClient(Environment.authAPIUrl));

type CacheUser = AuthUser & { timestamp: number };
type Users = IdMap<CacheUser>;
type UserIdentifier = { id: string } | TechnicalUser;

export async function searchUsers(
  query: string,
): Promise<Array<AuthUser & { teams: Team[] }>> {
  const res = await emptyListApi.get("/v1/users/search", { params: { query } });
  return res.data.map((user: ServerAuthUser & { teams: ServerUserTeam[] }) => ({
    ...mapUser(user),
    teams: mapUserTeams(user.teams),
  }));
}

let usersInTeamCache = {
  teamId: "",
  timestamp: 0,
  users: new Array<AuthUser>(),
};

export async function usersInTeam(team: Team): Promise<AuthUser[]> {
  if (
    usersInTeamCache.teamId === team.id &&
    Date.now() - usersInTeamCache.timestamp < usersInTeamCacheTimeout
  ) {
    return usersInTeamCache.users;
  }
  const users = await getUsersInTeam(team);
  usersInTeamCache = { teamId: team.id, timestamp: Date.now(), users };
  return users;
}

async function getUsersInTeam(team: Team): Promise<AuthUser[]> {
  const res = await emptyListApi.get(`/v1/teams/${team.id}/users`);
  return res.data.results.map(mapUser);
}

export async function getTeams(): Promise<Team[]> {
  const res = await emptyListApi.get("/v1/users/teams");
  return mapUserTeams(res.data.results);
}

export function loadUserImmediate(
  user: UserIdentifier & { name?: string },
  mapBackendToAlm?: boolean,
): AuthUser {
  const res = reactive(uninitedUser(user, "loading"));
  loadUser(user, { mapBackendToAlm })
    .then((loaded) => {
      res.type = loaded.type || "regular";
      res.id = loaded.id;
      res.name = loaded.name;
      res.email = loaded.email;
      res.imageUrl = loaded.imageUrl;
      res.color = loaded.color;
      res.preferredLanguage = loaded.preferredLanguage;
      res.hash = loaded.hash;
      if ("iconName" in loaded) {
        (res as AlmUser).iconName = loaded.iconName;
      }
    })
    .catch(() => {
      res.type = "unknown";
    });
  return res;
}

let users = load();
const loading: IdMap<Promise<CacheUser>> = {};

export async function loadUser(
  user: UserIdentifier,
  options?: { useCache?: boolean; mapBackendToAlm?: boolean },
): Promise<AuthUser> {
  const useCache = options?.useCache ?? true;

  if (!user.id) {
    return add(uninitedUser(user, "unknown"));
  }
  if (isBackendUserId(user.id)) {
    return options?.mapBackendToAlm ? almUser(getAlmInfo()) : backendUser();
  }
  const cached = users[user.id];
  if (useCache && cached && Date.now() - cached.timestamp < userCacheTimeout) {
    return cached;
  }
  if (user.id in loading) {
    return loading[user.id];
  }
  try {
    return await (loading[user.id] = getUser(user));
  } finally {
    delete loading[user.id];
  }
}

export function clearUserCache() {
  users = {};
  save(users);
}

async function getUser(user: UserIdentifier): Promise<CacheUser> {
  try {
    const res = await api.get("/v1/users/" + user.id);
    return add(mapUser(res.data));
  } catch (e: any) {
    if (e instanceof CancelError || e instanceof UnauthenticatedError) {
      // CancelError: most likely refreshing the token failed
      // don't log as we are already redirected to log out
      // UnauthenticatedError: let the errorHandler do the correct thing
      throw e;
    }
    captureMessage("Could not get user", {
      request: { userId: user.id },
      response: { message: e.message },
    });
  }
  return add(uninitedUser(user, "unknown"));
}

function add(user: AuthUser) {
  const cached = user as CacheUser;
  cached.timestamp = Date.now();
  users[cached.id] = cached;
  save(users);
  return cached;
}

function load(): Users {
  try {
    return JSON.parse(localStorage.getItem("users") || "{}");
  } catch (e) {
    return {};
  }
}

function save(userObj: Users) {
  localStorage.setItem("users", JSON.stringify(userObj));
}
