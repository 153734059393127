<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { getAlmInfo } from "@/backend/Backend";
import BasePopupTitle from "@/components-ng/BasePopup/components/BasePopupTitle/BasePopupTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import UnmappedUserWarning from "@/components/card/components/UnmappedUserWarning/UnmappedUserWarning.vue";
import UserSelector from "@/components/card/components/UserSelector/UserSelector.vue";
import { cardKey } from "@/components/card/injectKeys";
import InfoMessage from "@/components/ui/InfoMessage/InfoMessage.vue";
import { AuthUser } from "@/model/user";
import { useStickyTypeStore } from "@/store/stickyType";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

const isMapped = computed(
  () => useStickyTypeStore().almStickyTypeOf(card.type)?.isMapped,
);

function setAssignee(user: AuthUser | null) {
  cardActions.setAssignee("card", card.id, user);
  dropdown.close();
}
</script>

<template>
  <div class="set-assignee">
    <UserSelector
      v-if="getAlmInfo().hasAssignee || !isMapped"
      :title="$t('general.assignee')"
      :selected="card.assignee"
      @select="setAssignee"
    />
    <template v-else>
      <BasePopupTitle v-autofocus>{{ $t("general.assignee") }}</BasePopupTitle>
      <InfoMessage>
        {{ $t("assignee.cannotSet", { almType: getAlmInfo().name }) }}
      </InfoMessage>
      <UnmappedUserWarning :user="card.assignee" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.set-assignee {
  width: 240px;
  padding: 8px;
}
</style>
