import { defineStore } from "pinia";

import { i18n } from "@/i18n";
import { BoardType, IdMap } from "@/model/baseTypes";
import { AlmStickyType, StickyType } from "@/model/stickyType";

function zombieStickyType(): StickyType {
  return {
    id: "0",
    name: i18n.global.t("stickyType.unknown"),
    almType: null,
    color: [0.5, 0.5, 0.5, 0.8],
    altColor: [0.5, 0.5, 0.5, 0.8],
    origin: "risk",
    flexOrigin: null,
    usable: [],
    flexUsable: [],
    functionality: "note",
  };
}

export function isZombieType(type: StickyType) {
  return type.id === "0";
}

export const useStickyTypeStore = defineStore("stickyType", {
  state: () => ({
    stickyTypes: [] as StickyType[],
    almStickyTypes: {} as IdMap<AlmStickyType>,
  }),
  getters: {
    findStickyType:
      (state) =>
      (id: string): StickyType => {
        const type = state.stickyTypes.find((t) => t.id === id);
        return type || zombieStickyType();
      },
    nonFlexBoardStickyTypes: (state) => (type: BoardType) => {
      const originTypes = state.stickyTypes.filter((s) => s.origin === type);
      const mirrorTypes = state.stickyTypes.filter(
        (s) => s.origin !== type && s.usable.find((a) => a === type),
      );
      return boardStickyTypes(originTypes, mirrorTypes);
    },
    flexBoardStickyTypes: (state) => (flexType: string) => {
      const originTypes = state.stickyTypes.filter(
        (s) => s.origin === "flex" && s.flexOrigin === flexType,
      );
      const mirrorTypes = state.stickyTypes.filter(
        (s) =>
          s.origin !== "flex" &&
          s.usable.find((a) => a === "flex") &&
          (s.flexUsable || []).find((a) => a === flexType),
      );
      return boardStickyTypes(originTypes, mirrorTypes);
    },
    almStickyTypeOf: (state) => (stickyType: Pick<StickyType, "almType">) => {
      return state.almStickyTypes[stickyType.almType || ""];
    },
  },
});

function boardStickyTypes(origin: StickyType[], mirror: StickyType[]) {
  mirror.sort((a, b) => a.name.localeCompare(b.name));
  return [...origin, ...mirror];
}
