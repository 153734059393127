<script lang="ts" setup>
import { computed } from "vue";

import SetWsjf from "@/components/card/components/SetWsjf/SetWsjf.vue";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import { isRiskBoard, isTeamBoard } from "@/model/board";
import { useServerSettingsStore } from "@/store/serverSettings";
import { useWorkModeStore } from "@/store/workMode";
import { injectStrict } from "@/utils/context";

import { toElipsis } from "../../utils";
import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const wsjf = computed(() => {
  return card.type.priorities?.[card.priority]?.text || card.priority + "";
});

const isVisible = computed(() => {
  // hide it when the sticky was created on the team board
  if (card.type.origin === "team") return false;
  // hide it on the team board and the risk board
  if (isTeamBoard(board.value.type) || isRiskBoard(board.value.type)) {
    return false;
  }

  return useWorkModeStore().isExecutionMode;
});
</script>

<template>
  <ActionMenuItem
    v-if="isVisible"
    width="auto"
    name="wsjf"
    :disabled="!useServerSettingsStore().isPriorityEditable"
    :tooltip-text="
      useServerSettingsStore().isPriorityEditable
        ? $t('actionMenu.wsjf', { value: wsjf })
        : $t('actionMenu.wsjf.readonly', { value: wsjf })
    "
    :aria-label="$t('label.actionMenu.wsjf')"
    aria-controls="set-wsjf-dropdown"
  >
    <span
      class="wsjf-item"
      :aria-label="$t('actionMenu.wsjf', { value: wsjf })"
    >
      {{ toElipsis(wsjf) }}
    </span>
    <template #dropdown>
      <SetWsjf id="set-wsjf-dropdown" v-focus-trap trigger="action-menu" />
    </template>
  </ActionMenuItem>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/typography";
@use "@/styles/variables/colors";

.wsjf-item {
  @include typography.small("bold");

  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background-color: colors.$background-ui;
  border-radius: 4px;
  color: colors.$text;
}
</style>
