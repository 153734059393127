import { defineStore } from "pinia";

export const useSidePanelStore = defineStore("sidePanel", {
  state: () => {
    return {
      panelLeftWidth: 0,
      panelRightWidth: 0,
    };
  },
  getters: {
    panelLeftOpen: (state) => state.panelLeftWidth > 0,
    panelRightOpen: (state) => state.panelRightWidth > 0,
  },
  actions: {
    setWidth(side: "left" | "right", width: number) {
      if (side === "left") {
        this.panelLeftWidth = width;
      } else {
        this.panelRightWidth = width;
      }
    },
  },
});
