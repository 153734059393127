import { noop } from "lodash-es";
import { ComponentPublicInstance, ObjectDirective } from "vue";

import { DragHandlers, registerDrag } from "@/Gestures";

export interface DragInfo<T> {
  data: T | null;
}

interface DraggableValue {
  enabled?: boolean;
  drag: any;
  data: unknown;
  image: string;
}

export function initDrag<T, U>(
  props: U,
  handlers?: Partial<DragHandlers<void, void>>,
): U & DragInfo<T> & DragHandlers<void, void> {
  return {
    data: null,
    start() {
      return true;
    },
    move(drag) {
      return drag.pos;
    },
    stop: noop,
    ...handlers,
    ...props,
  };
}

/**
 * Make an element draggable by adding a v-draggable attribute to it:
 *   v-draggable="{
 *     enabled: if dragging is enabled
 *     drag: binding to a DragInfo object
 *     data: binding to the data that is dragged
 *     image: id of an HTML element to be used as dragging image
 *   }"
 */
export const Draggable: ObjectDirective<HTMLElement, DraggableValue> = {
  beforeMount(el, binding) {
    const { value, instance } = binding;

    if (!instance) return;

    if (!value.drag || !value.data) {
      throw Error("draggable directive needs 'drag' and 'data' props");
    }

    const dragHandler = createDragHandler(this, value, instance);
    if (value.enabled === undefined || value.enabled) {
      (instance as any).$dragHandler = dragHandler;
      el.addEventListener("pointerdown", dragHandler);
    }
  },
  beforeUnmount(el, binding) {
    const { instance } = binding;
    el.removeEventListener("pointerdown", (instance as any).$dragHandler);
  },
};

function createDragHandler(
  obj: typeof Draggable,
  value: DraggableValue,
  instance: ComponentPublicInstance | null,
) {
  return (event: PointerEvent) => {
    return registerDrag<void>(obj, event, {
      imageEl: document.getElementById(value.image),
      start(drag) {
        if (value.drag.start(drag, value.data)) {
          value.drag.data = value.data;
          instance?.$emit("dragStart");
          return true;
        }
        return false;
      },
      move(drag) {
        return value.drag.move(drag);
      },
      stop(drag) {
        value.drag.stop(drag);
        value.drag.data = null;
      },
    });
  };
}
