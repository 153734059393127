import { noop } from "lodash-es";

import { asyncNoop } from "@/utils/general";
import { objectId } from "@/utils/objectId";

import { BackendSession } from "./BackendSession";

const asyncAny = () => Promise.resolve({} as any);
const asyncArray = () => Promise.resolve([]);

export function dummyBackendSession(): BackendSession {
  return {
    addBoard: () => Promise.resolve(""),
    addBoardToCategory: asyncNoop,
    addCategory: () => Promise.resolve(""),
    addLink: asyncAny,
    addObjectiveLink: asyncNoop,
    addReaction: asyncNoop,
    almSource: () => undefined,
    almSubscribe: asyncAny,
    boardPublish: noop,
    boardSubscribe: asyncAny,
    createTimerEvent: () => Promise.resolve(""),
    deleteBoard: asyncNoop,
    deleteCategory: asyncNoop,
    deleteEvent: asyncNoop,
    deleteLink: asyncNoop,
    deleteObjectiveLink: asyncNoop,
    getAlmConnectionId: () => undefined,
    getAlmItemType: asyncAny,
    getAlmItemTypeForSticky: asyncAny,
    getAlmStatus: asyncAny,
    hasMappedAlmUser: asyncAny,
    getAlmType: () => undefined,
    getArts: asyncArray,
    getBoardIterations: asyncArray,
    getBoardStickies: asyncAny,
    getBoards: asyncArray,
    getCategories: asyncArray,
    getEvents: asyncArray,
    getFlexBackgrounds: asyncArray,
    getFlexTypes: asyncArray,
    getIterations: asyncArray,
    getLicense: asyncAny,
    getLinkTypes: asyncArray,
    getLinks: asyncArray,
    getObjectives: asyncAny,
    getScale: () => Promise.resolve(0),
    getServerInfo: asyncAny,
    getSessions: asyncArray,
    getSettings: asyncAny,
    subscriptionCount: () => 0,
    getStickies: asyncArray,
    getStickyTypes: asyncArray,
    getTeams: asyncArray,
    mirror: asyncAny,
    move: noop,
    newSticky: () => Promise.resolve(""),
    removeBoardFromCategory: asyncNoop,
    removeReaction: asyncNoop,
    addShape: () => Promise.resolve(objectId()),
    editShape: asyncNoop,
    removeShape: asyncNoop,
    roomSubscribe: asyncAny,
    searchStickies: asyncArray,
    sessionSubscribe: asyncAny,
    setSessionId: () => Promise.resolve(false),
    syncBacklog: asyncAny,
    syncIteration: asyncAny,
    toRisk: noop,
    unsubscribe: asyncNoop,
    updateBoard: asyncNoop,
    updateCategory: asyncNoop,
    updateTimerEvent: asyncNoop,
    getAlmStickyTypes: asyncArray,
    getBoardIdsOfGroupedStickies: () => Promise.resolve({ board_ids: [] }),
    getStickyChanges: asyncArray,
    duplicateFlex: asyncNoop,
    getBoardDiff: asyncAny,
  };
}
