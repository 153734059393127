<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { getStickyChanges } from "@/backend/Backend";
import SidePanel from "@/components/SidePanel/SidePanel.vue";
import SidePanelContent from "@/components/SidePanel/SidePanelContent.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/TypeDot.vue";
import { StickyChange } from "@/model/change";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { mirrorOriginId } from "@/store/card";

import ChangesByDay from "./components/ChangesByDay.vue";

const card = computed(() => useActivityStore().card);
const changes = ref<StickyChange[]>([]);
const incomplete = ref(false);

//  Close this panel when the Card loses focus
watch(
  () => useBoardStore().activeCardId,
  (activeId) => {
    if (card.value && activeId !== card.value.id) {
      close();
    }
  },
);

watch(card, async (card) => {
  if (card) {
    const ch = await getStickyChanges(mirrorOriginId(card) || card.id);
    const len = ch.length;
    incomplete.value = len > 0 && ch[len - 1].kind !== "create";
    // if we have an incomplete history, remove the first change
    // as it's an artificial change from "no fields" to "fields at the moment sticky activity was implemented"
    if (incomplete.value) {
      ch.splice(len - 1, 1);
    }
    changes.value = ch;
  }
});

function close() {
  useActivityStore().inactivate();
}
</script>

<template>
  <div class="sticky-activity-panel" data-ignore-click @scroll.stop>
    <SidePanel side="right" :active="!!card">
      <SidePanelContent
        v-focus-trap
        v-autofocus
        icon="action-menu/activity"
        :title="$t('activity.cardTitle')"
        @close="close"
      >
        <template v-if="card" #sub-title>
          <TypeDot
            size="medium"
            :color="card.type.color"
            role="note"
            :aria-label="
              $t('label.activityPanel.cardType', { type: card.type.name })
            "
          />
          <span class="text">{{ card.text }}</span>
          <SvgIcon
            v-if="!!mirrorOriginId(card)"
            name="mirror"
            width="20"
            height="20"
            role="img"
            :aria-label="$t('label.activityPanel.mirrored')"
          />
        </template>

        <ChangesByDay :changes="changes" />

        <div v-if="changes.length === 0" class="group">
          {{ $t("stickyChange.empty") }}
        </div>
        <div v-else-if="incomplete" class="group">
          {{ $t("stickyChange.incomplete") }}
        </div>
      </SidePanelContent>
    </SidePanel>
  </div>
</template>

<style lang="scss">
@use "@/styles/z-index";
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.sticky-activity-panel {
  position: relative;
  z-index: z-index.$menu;

  .side-panel .panel-icon {
    color: colors-old.$text-subtle-color;
  }

  .text {
    color: colors-old.$text-subtle-color;
    font-weight: font.$weight-medium;
    overflow-wrap: anywhere;
  }

  .group {
    padding: 0 1em;
    margin-top: 2.5em;
    margin-bottom: 1.5em;
  }
}
</style>
