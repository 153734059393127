<script setup lang="ts">
import { nextTick, ref, watch } from "vue";

import BaseInput from "@/components/ui/BaseInput/BaseInput.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";
import { searchTextChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import ArtItemTooltip from "./tooltips/ArtItemTooltip.vue";
import AssigneeItemTooltip from "./tooltips/AssigneeItemTooltip.vue";
import DependencyLinkItemTooltip from "./tooltips/DependencyLinkItemTooltip.vue";
import IterationItemTooltip from "./tooltips/IterationItemTooltip.vue";
import RiskyLinksItemTooltip from "./tooltips/RiskyLinksItemTooltip.vue";
import StatusClassItemTooltip from "./tooltips/StatusClassItemTooltip.vue";
import StickersItemTooltip from "./tooltips/StickersItemTooltip.vue";
import StickyTypeItemTooltip from "./tooltips/StickyTypeItemTooltip.vue";
import TeamItemTooltip from "./tooltips/TeamItemTooltip.vue";

const model = defineModel<string>({ required: true });
const props = defineProps<{ isActive: boolean; anyFilterCriteria: boolean }>();
const emit = defineEmits<{ toggle: [] }>();
const inputRef = ref<HTMLInputElement>();
const store = useSearchMenuStore();

watch(
  () => props.isActive,
  async (isActive) => {
    if (isActive) {
      await nextTick();
      inputRef.value?.focus();
    }
  },
);

// the three funcs bellow are only used for analytics
const onInput = () => {
  const boardType = useBoardStore().currentBoard().type;
  trackEvent(searchTextChanged(boardType));
};

const handleInputFocus = (event: FocusEvent) => {
  const target = event.target as HTMLInputElement;
  target.addEventListener("input", onInput, { once: true });
};

const handleInputBlur = (event: FocusEvent) => {
  const target = event.target as HTMLInputElement;
  target.removeEventListener("input", onInput);
};
</script>

<template>
  <div class="search-bar" :class="{ active: isActive }">
    <!-- Button to 'open' search input -->
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
    <div v-if="!isActive" class="search-bar-icon" @click.stop="emit('toggle')">
      <IconButton
        id="search-bar-trigger"
        icon="search"
        aria-expanded="false"
        :aria-label="$t('action.search')"
      >
        <template #tooltip>
          <div v-if="!anyFilterCriteria">
            {{ $t("action.search") }}
          </div>
          <template v-else>
            <div v-if="store.text">
              {{ $t("general.text") }}
              <div class="shortcut">
                {{ store.text }}
              </div>
            </div>
            <StickyTypeItemTooltip
              v-if="store.selectedStickyTypes.length"
              :types="store.selectedStickyTypes"
            />
            <DependencyLinkItemTooltip
              v-if="store.selectedDependencyLinkItems.length"
              :items="store.selectedDependencyLinkItems"
            />
            <StickersItemTooltip
              v-if="store.flags.length"
              :stickers="store.flags"
            />
            <IterationItemTooltip
              v-if="store.selectedIterations.length"
              :iterations="store.selectedIterations"
            />
            <AssigneeItemTooltip
              v-if="store.selectedAssignees.length"
              :assignees="store.selectedAssignees"
            />
            <TeamItemTooltip
              v-if="store.selectedTeams.length"
              :teams="store.selectedTeams"
            />
            <ArtItemTooltip
              v-if="store.selectedArts.length"
              :arts="store.selectedArts"
            />
            <StatusClassItemTooltip
              v-if="store.selectedStatusClassItems.length"
              :status-class-items="store.selectedStatusClassItems"
            />
            <div v-if="store.showDependencyTeamFilter">
              {{ $t("general.teamDependencies") }}
              <div class="shortcut">
                {{ store.selectedDependencyFilterItem.name }}
              </div>
            </div>
            <RiskyLinksItemTooltip
              v-if="store.selectedRiskyLinks.length"
              :states="store.selectedRiskyLinks"
            />
          </template>
        </template>
      </IconButton>
      <InfoMarker v-if="anyFilterCriteria" :top="5" :right="9" />
    </div>
    <!-- Search input -->
    <BaseInput
      v-else
      ref="inputRef"
      v-model="model"
      control-type="text"
      icon-before="search"
      :icon-before-title="$t('label.search.inputTitle')"
      class="allow-shortcuts"
      :placeholder="$t('action.search')"
      @focus="handleInputFocus"
      @blur="handleInputBlur"
      @keydown.esc="emit('toggle')"
    />
  </div>
</template>

<style lang="scss">
.search-bar {
  .active {
    min-width: 297px;
  }
}
</style>
