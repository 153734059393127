import { Vue } from "vue-class-component";

import { nextIndex } from "@/utils/list";

export default class SearchBase<T extends { id: string }> extends Vue {
  foundIndex = 0;
  found = new Array<T>();

  search<S>(
    items: S[] | Record<string, S>,
    match: (item: S) => boolean,
    {
      mapResult = (item) => item as unknown as T,
      sortBy,
    }: {
      mapResult?: (item: S) => T;
      sortBy?: (item: T) => number;
    } = {},
  ) {
    const foundItemId = this.foundItem()?.id;
    this.found = [];
    if (Array.isArray(items)) {
      for (const item of items) {
        if (match(item)) {
          this.found.push(mapResult(item));
        }
      }
    } else {
      for (const id in items) {
        if (match(items[id])) {
          this.found.push(mapResult(items[id]));
        }
      }
    }
    if (sortBy) {
      this.found.sort((a, b) => sortBy(b) - sortBy(a));
    }
    const newFound = foundItemId
      ? this.found.findIndex((item) => item.id === foundItemId) + 1
      : 0;
    this.setFound(newFound);
  }

  findNext(offset: number) {
    if (this.found.length > 0) {
      this.setFound(nextIndex(this.found, this.foundIndex - 1, offset) + 1);
    }
  }

  foundItem() {
    return this.found[this.foundIndex - 1];
  }

  setFound(c: number, focus?: boolean) {
    if (c !== this.foundIndex) {
      this.$markFound(false, false);
      this.foundIndex = c;
      this.$markFound(true, focus);
    }
  }

  $markFound(mark: boolean, focus?: boolean) {
    if (this.foundIndex) {
      this.markFound(this.foundItem(), mark, !!focus);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  markFound(_item: T, _mark: boolean, _focus: boolean) {}
}
