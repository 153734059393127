import { registerActionShortcut } from "@/Shortcuts";
import { CardCreateProps, cardActions } from "@/action/cardActions";
import { lineSeparator } from "@/lowlevel";
import { relativeToWindow } from "@/math/coordinate-systems";
import { AlmSourceId } from "@/model/baseTypes";
import { BoardCard } from "@/model/card";
import {
  RelativeCoordinate,
  centerCoord,
  minus,
  plus,
  scrollCoord,
} from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useModalStore } from "@/store/modal";
import { useSelectionStore } from "@/store/selection";
import { useZoomStore } from "@/store/zoom";

export function registerCopyPaste() {
  registerActionShortcut("", cardActions.duplicate, { prevent: true });

  document.addEventListener("paste", (e) => {
    if (cardPasteTarget(e.target)) {
      const data = parseJson(e.clipboardData?.getData("text/sticky"));
      if (Array.isArray(data) && data.every(isSerializedCard)) {
        useSelectionStore().paste(
          data.map((d) => deserializeCard(data[0].pos, d)),
        );
      } else {
        const text = e.clipboardData?.getData("text");
        if (text) {
          cardActions.pasteText("keyboard", { text });
        }
      }
    }
  });

  document.addEventListener("copy", (e) => {
    const cards = useBoardStore().selectedOrActiveCards;
    if (cards.length > 0 && e.clipboardData) {
      cardActions.copy("keyboard");
      if (cards.length === 1 && selectedText(e.target)) {
        setClipboard(e.clipboardData, [
          cardWithText(cards[0], selectedText(e.target)),
        ]);
      } else {
        setClipboard(e.clipboardData, cards);
      }
      e.preventDefault();
    }
  });
}

function cardWithText(card: BoardCard, text: string) {
  return { ...card, data: { ...card.data, text } };
}

function setClipboard(clipboard: DataTransfer, cards: BoardCard[]) {
  clipboard.setData(
    "text/plain",
    cards.map((card) => card.data.text).join(lineSeparator),
  );
  clipboard.setData("text/sticky", JSON.stringify(cards.map(serializeCard)));
}

function cardPasteTarget(e?: any) {
  return (
    !useModalStore().isModalOpen() &&
    (!e || (e.nodeName !== "INPUT" && e.nodeName !== "TEXTAREA"))
  );
}

function selectedText(e?: any) {
  if (e?.value) {
    const start = e.selectionStart;
    const end = e.selectionEnd;
    if (start !== null && end !== null) {
      return e.value.substring(start, end);
    }
  }
  return null;
}

function parseJson(s?: string): any {
  try {
    return s ? JSON.parse(s) : null;
  } catch (e) {
    return null;
  }
}

interface SerializedCardData {
  text: string;
  typeId: string;
  typeName: string;
  almSourceId: AlmSourceId | null;
  pos: RelativeCoordinate;
}

function isSerializedCard(data: any): data is SerializedCardData {
  return (
    "text" in data &&
    "typeId" in data &&
    "typeName" in data &&
    "almSourceId" in data &&
    "pos" in data
  );
}

function serializeCard(card: BoardCard): SerializedCardData {
  return {
    typeId: card.data.type.id,
    typeName: card.data.type.name,
    almSourceId: card.data.almSourceId,
    text: card.data.text,
    pos: card.meta.pos,
  };
}

function deserializeCard(
  base: RelativeCoordinate,
  data: SerializedCardData,
): CardCreateProps {
  const stickyTypes = useBoardStore().creatableStickyTypes;
  const originalPos = minus(relativeToWindow(data.pos), scrollCoord());
  const relativeToBase = minus(
    relativeToWindow(data.pos),
    relativeToWindow(base),
  );
  const centerOfViewport = plus(relativeToBase, centerCoord());
  return {
    text: data.text,
    almSourceId: data.almSourceId ?? undefined,
    // cross board pasting -> try to match type name
    // if still not found, take the first sticky type
    // TODO improvement: ask the user how to map every sticky type
    type:
      stickyTypes.find((type) => type.id === data.typeId) ||
      stickyTypes.find((type) => type.name === data.typeName) ||
      stickyTypes[0],
    pos: useZoomStore().factor < 1.2 ? originalPos : centerOfViewport,
  };
}
