import { range } from "lodash-es";

import { i18n } from "@/i18n";
import { BoardIteration } from "@/model/board";
import {
  Rectangle,
  RelativeCoordinate,
  isCoordinate,
  rectangle,
  relativeCoord,
} from "@/model/coordinates";
import { Art, Group, Iteration, Team } from "@/model/session";
import { fixLine } from "@/model/shape";
import { useSessionStore } from "@/store/session";

import { BoardLocation } from "./BoardLocation";

const backlogColumn = -2;
const groupColumn = -1;
const iterationRow = -1;

type PlanningBoardType = "team" | "art";
const i18nMap = {
  team: {
    row: /*$t*/ "general.namedTeam",
    backlog: /*$t*/ "backlogBoard.programBacklog",
  },
  art: {
    row: /*$t*/ "general.namedArt",
    backlog: /*$t*/ "backlogBoard.solutionBacklog",
  },
};

export class PlanningBoardLocation implements BoardLocation {
  private readonly iteration?: Iteration;
  private readonly group?: Group;
  readonly iterationId: number | null;
  readonly groupId: string | null;

  constructor(
    private type: PlanningBoardType,
    private groups: Group[],
    public left: number,
    public top: number,
  ) {
    this.iteration = useSessionStore().iterations[left];
    this.group = groups[top];
    this.iterationId = this.iteration ? this.iteration.id : null;
    this.groupId = this.group?.id || null;
  }

  static of(
    type: PlanningBoardType,
    groups: Group[],
    coordOrLeft: RelativeCoordinate | number,
    top?: number,
  ): PlanningBoardLocation {
    return isCoordinate(coordOrLeft)
      ? PlanningBoardLocation.fromCoordinate(type, groups, coordOrLeft)
      : new PlanningBoardLocation(type, groups, coordOrLeft, top!);
  }

  static ofTeams(
    teams: Team[],
    coordOrLeft: RelativeCoordinate | number,
    top?: number,
  ): PlanningBoardLocation {
    return PlanningBoardLocation.of("team", teams, coordOrLeft, top);
  }

  static ofArts(
    arts: Art[],
    coordOrLeft: RelativeCoordinate | number,
    top?: number,
  ): PlanningBoardLocation {
    return PlanningBoardLocation.of("art", arts, coordOrLeft, top);
  }

  static fromCoordinate(
    type: PlanningBoardType,
    groups: Group[],
    coord: RelativeCoordinate,
  ): PlanningBoardLocation {
    const rawLeft = coord.x * (useSessionStore().iterations.length + 1) - 1;
    const left = rawLeft < -0.5 ? backlogColumn : Math.floor(rawLeft);
    const top = Math.floor(coord.y * (groups.length + 0.5) - 0.5);
    return new PlanningBoardLocation(type, groups, left, top);
  }

  matches(coord: RelativeCoordinate) {
    const loc = PlanningBoardLocation.fromCoordinate(
      this.type,
      this.groups,
      coord,
    );
    return (
      ((this.left === backlogColumn || this.top === iterationRow) &&
        this.left === loc.left) ||
      ((this.left === groupColumn || this.left === loc.left) &&
        this.top === loc.top)
    );
  }

  iterationName() {
    switch (this.left) {
      case backlogColumn:
        return i18n.global.t(i18nMap[this.type].backlog);
      case groupColumn:
        return "";
      default:
        return i18n.global.t("general.namedIteration", {
          name: this.iteration!.name,
        });
    }
  }

  groupName() {
    if (this.left === backlogColumn || !this.group) {
      return "";
    }
    if (!this.group.id) {
      return this.group.name;
    }
    return i18n.global.t(i18nMap[this.type].row, { name: this.group.name });
  }

  names() {
    return [this.groupName(), this.iterationName()].filter((name) => !!name);
  }

  boardIteration(
    teamIterations: (group: Group) => BoardIteration[],
  ): BoardIteration | undefined {
    if (this.left >= 0 && this.group?.id) {
      return teamIterations(this.group)[this.left];
    }
  }

  get name() {
    return this.iterationName() + " " + this.groupName();
  }

  get bounds(): Rectangle<RelativeCoordinate> {
    const cols = useSessionStore().iterations.length + 1;
    const rows = this.groups.length + 0.5;
    const [x, width] =
      this.left < 0
        ? [(this.left / 2 + 1) / cols, 0.5 / cols]
        : [(this.left + 1) / cols, 1 / cols];
    const [y, height] =
      this.top < 0 ? [0, 0.5 / rows] : [(this.top + 0.5) / rows, 1 / rows];
    if (this.left === backlogColumn && this.top >= 0) {
      return rectangle(
        relativeCoord(x, 0.5 / rows),
        relativeCoord(width, 1 - 0.5 / rows),
      );
    }
    return rectangle(relativeCoord(x, y), relativeCoord(width, height));
  }

  get shapes() {
    const rows = this.groups.length + 0.5;
    const cols = useSessionStore().iterations.length + 1;
    return [
      fixLine("v0", relativeCoord(0.5 / cols, 0), relativeCoord(0.5 / cols, 1)),
      ...range(1, cols).map((col) =>
        fixLine(
          "v" + col,
          relativeCoord(col / cols, 0),
          relativeCoord(col / cols, 1),
        ),
      ),
      fixLine("h0", relativeCoord(0, 0.5 / rows), relativeCoord(1, 0.5 / rows)),
      ...range(1.5, this.groups.length).map((row) =>
        fixLine(
          "h" + row,
          relativeCoord(0.5 / cols, row / rows),
          relativeCoord(1, row / rows),
        ),
      ),
    ];
  }

  index() {
    return [this.left, this.top];
  }
}
