<script setup lang="ts">
import { onMounted, ref } from "vue";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { goToLogin } from "@/router/navigation";

const isMobile = ref(false);

onMounted(() => {
  // we need body { overflow: scroll } in the app, but here it would be ugly to have scrollbars
  // but cannot use CSS for this as it would override it for all pages
  document.documentElement.style.overflow = "auto";
});

function onDesktop() {
  document.documentElement.style.overflow = null!;
  localStorage.setItem("device", "desktop");
  goToLogin();
}
</script>

<template>
  <div class="mobile-info">
    <div class="content-wrap">
      <img class="logo" src="@/assets/logo.svg" alt="" />

      <div v-if="!isMobile" class="content">
        <h2>{{ $t("mobileInfo.chooseDevice") }}</h2>
        <p>
          {{ $t("mobileInfo.userExperienceBlurb") }}
        </p>
        <div class="button-wrap">
          <BaseButton @click="isMobile = true">
            {{ $t("mobileInfo.onMobile") }}
          </BaseButton>
          <BaseButton variant="outlined" color="grey" @click="onDesktop">
            {{ $t("mobileInfo.onDesktop") }}
          </BaseButton>
        </div>
      </div>

      <div v-else class="content">
        <h2>{{ $t("mobileInfo.loginFromDesktop") }}</h2>
        <p>
          {{ $t("mobileInfo.checkVideo") }}
        </p>
        <iframe
          src="https://www.youtube.com/embed/gPuA1c8ZrJ4"
          frameBorder="0"
          allowfullscreen
          :title="$t('label.mobile.iframe')"
        />
        <span class="link-wrap">
          <p>{{ $t("mobileInfo.askIfOnDesktop") }}</p>
          <p>
            <button @click="onDesktop">
              {{ $t("mobileInfo.loginBlurb") }}
            </button>
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.mobile-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  background-color: colors-old.$back-color;

  span.link-wrap {
    position: relative;
    margin: 5rem auto;

    p {
      margin: 0;
    }
  }

  .content-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 6rem;

    .logo {
      margin-bottom: 3rem;
      height: 3rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      p {
        max-width: 23rem;
        padding: 0 2rem;
        margin: 0;
      }

      h2 {
        text-align: center;
        margin: 0 2rem 0.75rem;
      }
    }
  }

  .button-wrap {
    display: flex;
    justify-content: center;
    gap: 1.25rem;
    width: auto;
    margin-top: 3rem;
  }

  iframe {
    width: 340px;
    height: 191px;
    margin-top: 32px;
  }

  button {
    cursor: pointer;
    text-decoration: underline;
    outline: revert;
  }

  @media screen and (width <= 380px) {
    iframe {
      width: 240px;
      height: 135px;
    }
  }

  @media screen and (width <= 420px) {
    .button-wrap {
      flex-direction: column;
      margin-top: 2.5rem;
      gap: 2rem;
    }
  }
}
</style>
