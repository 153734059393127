import { RouteLocation } from "vue-router";

import { featureToggles } from "@/featureToggles";

export type Feature = "log-less" | "faro" | "faro-sample-all" | "board-diff";

export function isFeatureEnabled(
  route: Pick<RouteLocation, "query">,
  feature: Feature,
): boolean {
  return (
    !isFeatureEnabledInQuery(route, "!" + feature) &&
    (isFeatureEnabledInQuery(route, feature) ||
      featureToggles.isEnabled(feature))
  );
}

export function parseUrlWithoutRouter() {
  const hash = document.location.hash;
  const queryStart = hash.indexOf("?");
  if (queryStart < 0) {
    return {};
  }
  const params = new URLSearchParams(hash.substring(queryStart));
  const extractedParams: Record<string, string[]> = {};
  for (const [key, value] of params) {
    if (extractedParams[key]) {
      extractedParams[key].push(value);
    } else {
      extractedParams[key] = [value];
    }
  }
  return extractedParams;
}

function isFeatureEnabledInQuery(
  route: Pick<RouteLocation, "query">,
  feature: string,
): boolean {
  if (!route?.query?.feature) {
    return false;
  }
  if (Array.isArray(route.query.feature)) {
    return route.query.feature.includes(feature);
  }
  return route.query.feature === feature;
}
