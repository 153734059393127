<template>
  <div
    ref="baseListItemsContainer"
    v-keyboard-nav-local.soft-focus.ignore-trigger="{
      selector: '.base-list-item',
    }"
    :tabindex="static ? '-1' : '0'"
    class="base-list-items"
    role="listbox"
    @focus="focusin"
    @focusout="focusout"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import { keyboardNavigation } from "@/directives/keyboardNavigation";

defineProps<{ static?: boolean }>(); // static makes the whole list readonly/non-focusable

const vKeyboardNavLocal = keyboardNavigation();
const baseListItemsContainer = ref<HTMLDivElement | null>(null);

const focusin = () => {
  const active = baseListItemsContainer.value?.querySelector<HTMLElement>(
    ".active:not(.disabled):not(.static)",
  );
  if (active) {
    baseListItemsContainer?.value?.setAttribute("tabindex", "-1");
    active.focus();
  } else {
    const firstBaseListItem =
      baseListItemsContainer?.value?.querySelector<HTMLElement>(
        ".base-list-item:not(.disabled):not(.static)",
      );

    if (firstBaseListItem) {
      baseListItemsContainer.value?.setAttribute("tabindex", "-1");
      firstBaseListItem.focus();
    }
  }
};

const focusout = (e: FocusEvent) => {
  if (!baseListItemsContainer.value?.contains(e.relatedTarget as Node)) {
    baseListItemsContainer?.value?.setAttribute("tabindex", "0");
  }
};
</script>

<style lang="scss" scoped>
@use "@/styles/utils";

.base-list-items {
  position: relative;
  flex-grow: 1;
  padding: 2px 4px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: auto;
  max-height: 100%;
}
</style>
