import { InfoLevel } from "@/model/baseTypes";
import { useClientSettingsStore } from "@/store/clientSettings";

/**
 * Returns the state of the load based on the load and capacity.
 */
export function loadState(load: number, capacity: number): InfoLevel {
  if (!capacity) {
    return "default";
  }

  const loadRatio = load / capacity;

  if (loadRatio >= 1) {
    return "error";
  }

  if (loadRatio >= useClientSettingsStore().loadWarn / 100) {
    return "warn";
  }

  return "ok";
}
