<script setup lang="ts">
import { clamp } from "lodash-es";
import { ref } from "vue";

import { cardActions } from "@/action/cardActions";
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BasePopupTitle from "@/components-ng/BasePopup/components/BasePopupTitle/BasePopupTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import BoardNumberInput from "@/components/input/BoardNumberInput.vue";
import { stickyNoteWsjfChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";

const props = defineProps<{
  trigger: "action-menu" | "attribute-chip"; // used for event tracking analytics
}>();

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const dropdown = injectStrict(dropdownKey);
const priority = ref(card.priority);

const selectPriority = (priority: number): void => {
  trackEvent(
    stickyNoteWsjfChanged(
      board.value.type,
      card.type.functionality,
      props.trigger,
    ),
  );

  cardActions.setPriority("card", card.id, priority);

  dropdown.close();
};

const setInput = (val: number) => {
  if (!isFinite(val)) return 0;
  priority.value = clamp(+val.toFixed(1), 0, 999.9);
};
</script>

<template>
  <div
    class="wsjf"
    role="dialog"
    aria-labelledby="set-wsjf-title"
    :aria-description="$t('label.stickyNote.WSJFDialogDescription')"
  >
    <BasePopupTitle id="set-wsjf-title">
      {{ $t("actions.wsjf") }}
    </BasePopupTitle>
    <BaseList v-if="card.type.priorities">
      <BaseListItems aria-labelledby="set-wsjf-title">
        <BaseListItem
          v-for="prio in card.type.priorities"
          :key="prio.value"
          :active="prio.value === card.priority"
          @click="selectPriority(prio.value)"
        >
          {{ prio.text }}
        </BaseListItem>
      </BaseListItems>
    </BaseList>
    <span v-else class="wsjf-input-wrap">
      <BoardNumberInput
        v-autofocus
        class="wsjf-input"
        allow-float
        :value="priority"
        :maxlength="5"
        data-testid="wsjf-input"
        aria-labelledby="set-wsjf-title"
        @input="setInput"
        @blur="selectPriority(priority)"
        @keydown.enter="$event.target.blur()"
      />
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.wsjf {
  width: 120px;
}

.wsjf-input-wrap {
  display: flex;
  height: 40px;
  margin: 6px;

  .wsjf-input {
    text-align: center;
    font-size: font.$size-normal;
    font-weight: font.$weight-medium;
    color: colors-old.$inactive-text-color;
  }

  .wsjf-input:focus {
    color: colors-old.$text-primary-color;
  }
}
</style>
