<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import { AlmSourceId } from "@/model/baseTypes";
import { useBoardsStore } from "@/store/boards";
import { stickyNoteProjectChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const dropdown = injectStrict(dropdownKey);

const props = defineProps<{
  trigger: "action-menu"; // used for event tracking analytics
}>();

const sources = computed(() => {
  return useBoardsStore().boardByType(card.type.origin)?.almSources;
});

const handleProjectChange = (id: AlmSourceId) => {
  trackEvent(
    stickyNoteProjectChanged(
      board.value.type,
      card.type.functionality,
      props.trigger,
    ),
  );

  cardActions.setAlmSource("card", card.id, id);
  dropdown.close();
};
</script>

<template>
  <BaseList role="list" class="change-project">
    <BaseListTitle>{{ $t("cardAction.project") }}</BaseListTitle>
    <BaseListItems :aria-label="$t('cardAction.project')">
      <BaseListItem
        v-for="source in sources"
        :key="source.id"
        role="listitem"
        :active="source.id === card.almSourceId"
        @click="handleProjectChange(source.id)"
      >
        {{ source.name }}
      </BaseListItem>
    </BaseListItems>
  </BaseList>
</template>
