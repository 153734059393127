<script setup lang="ts">
import { terminateEvent } from "@/utils/dom";

// default  -> when active, item is highlighted with primary color
// stickies -> when active, item is highlighted in greyscale
export type ListItemVariant = "default" | "cards";

const props = withDefaults(
  defineProps<{
    variant?: ListItemVariant;
    static?: boolean;
    active?: boolean;
    disabled?: boolean;
  }>(),
  { variant: "default" },
);

function stop(e: Event) {
  //avoid closing the dropdown
  if (props.static) {
    terminateEvent(e);
  }
}
</script>

<!-- eslint-disable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
<template>
  <div
    class="list-item"
    tabindex="-1"
    :role="!props.static ? 'menuitem' : ''"
    :aria-disabled="props.disabled"
    :class="[{ static, active, disabled }, variant]"
    @click="stop"
  >
    <slot />
  </div>
</template>

<style lang="scss">
@use "./listItem";
</style>
