<template>
  <header
    class="menu-header"
    :class="{ 'dark-mode': darkMode }"
    :aria-label="$t('label.menuTop')"
  >
    <div class="menu-header-left">
      <template v-if="!isSearchActive">
        <exit-item />
        <menu-item-delimiter />
      </template>
      <search-item v-model="searchValue" />
      <template v-if="showMetrics">
        <menu-item-delimiter />
        <metrics-item />
      </template>
      <template v-if="showExecutionMode">
        <menu-item-delimiter />
        <work-mode-item />
      </template>
      <template v-if="showBoardDiff">
        <menu-item-delimiter />
        <ActionButton :action="toggleActions.showDiff" />
      </template>
    </div>

    <BreadCrumb />

    <div class="menu-header-right">
      <alm-status class="menu-item" />

      <template v-if="links && showRiskyLinks && !isSearchActive">
        <risky-links />
        <menu-item-delimiter />
      </template>

      <board-users />
      <FacilitationMenu />
      <RunningTimers v-if="activeTimers" @click.stop="toggleTimer" />

      <template v-if="isZoomEnabled">
        <menu-item-delimiter />
        <app-zoom class="menu-item" />
      </template>
      <menu-item-delimiter />
      <user-actions is-user-on-board />
    </div>
  </header>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import { toggleActions } from "@/action/toggleActions";
import AlmStatus from "@/components/AlmStatus.vue";
import BoardUsers from "@/components/BoardUsers.vue";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import RiskyLinks from "@/components/RiskyLinks.vue";
import SearchItem from "@/components/Search/SearchItem.vue";
import RunningTimers from "@/components/Timer/RunningTimers.vue";
import UserActions from "@/components/UserActions.vue";
import ActionButton from "@/components/ui/ActionButton/ActionButton.vue";
import { isFeatureEnabled } from "@/feature";
import EventBusUser from "@/mixins/EventBusUser";
import { currentRoute } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useTimerStore } from "@/store/timer";
import { useWorkModeStore } from "@/store/workMode";

import AppZoom from "./AppZoom.vue";
import ExitItem from "./ExitItem.vue";
import MetricsItem from "./MetricsItem.vue";
import FacilitationMenu from "./components/FacilitationMenu.vue";
import MenuItemDelimiter from "./components/MenuItemDelimiter.vue";
import WorkModeItem from "./components/WorkModeItem.vue";

@Component({
  components: {
    ActionButton,
    BreadCrumb,
    MetricsItem,
    SearchItem,
    ExitItem,
    AppZoom,
    UserActions,
    AlmStatus,
    BoardUsers,
    RiskyLinks,
    WorkModeItem,
    MenuItemDelimiter,
    RunningTimers,
    FacilitationMenu,
  },
})
export default class TopMenuBar extends mixins(EventBusUser) {
  searchValue = "";

  get toggleActions() {
    return toggleActions;
  }

  get darkMode() {
    return (
      !this.isSearchActive &&
      useWorkModeStore().showExecutionMode &&
      useWorkModeStore().isExecutionMode
    );
  }

  get isSearchActive() {
    return useSearchMenuStore().isSearchActive;
  }

  get isZoomEnabled() {
    return useBoardStore().isCurrentBoardFluid;
  }

  get showRiskyLinks() {
    return useBoardStore().showRiskyLinks;
  }

  get showMetrics() {
    return (
      useBoardStore().board?.type === "program" ||
      useBoardStore().board?.type === "team"
    );
  }

  get showExecutionMode() {
    return useWorkModeStore().showExecutionMode && !this.isSearchActive;
  }

  get showBoardDiff() {
    return isFeatureEnabled(currentRoute(), "board-diff");
  }

  get links() {
    return useLinkStore().hasRiskyLinks;
  }

  get activeTimers() {
    const allTimers = [
      ...useTimerStore().boardTimers,
      ...useTimerStore().artTimers,
    ];
    return allTimers.some((timer) => timer.data.state === "running");
  }

  toggleTimer() {
    toggleActions.showTimer("mainMenu");
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.menu-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--back-color);
  border-bottom: 2px solid colors-a11y.$board-border;
  height: variables.$top-header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3px;
  padding-right: 14px;
  transition: 0.15s ease-in-out background-color;
  z-index: z-index.$menu;

  .menu-header-left,
  .menu-header-right {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
  }

  .menu-header-right {
    justify-content: flex-end;

    .menu-item-delimiter {
      margin: 0 8px;
    }
  }

  .menu-item {
    min-height: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.plan-readout-button-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
