<script setup lang="ts">
import { computed, ref } from "vue";

import { loadFlexBoards } from "@/backend/Backend";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/DropdownMenu/DropdownTrigger.vue";
import ListItem from "@/components/ListItem/ListItem.vue";
import FlexBackground from "@/components/background/FlexBackground.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { BoardData } from "@/model/board";
import { Session } from "@/model/session";
import { useBoardsStore } from "@/store/boards";
import { useSessionStore } from "@/store/session";

const emit = defineEmits<{
  close: [];
  duplicate: [board: BoardData<"flex">];
}>();

const sessions = computed(() => useSessionStore().session.list);
const selected = ref(useSessionStore().session.current);

const boards = ref(
  Object.values(useBoardsStore().boards).filter(
    (board) => board.type === "flex",
  ) as Array<BoardData<"flex">>,
);

async function select(session: Session) {
  selected.value = session;
  boards.value = (await loadFlexBoards(session.id)).flexBoards;
}
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
  <div
    v-focus-trap
    class="duplicate-flex-board"
    role="dialog"
    aria-modal="true"
    aria-labelledby="duplicate-canvas-title"
    tabindex="-1"
    @click="emit('close')"
    @keydown.esc.stop="emit('close')"
    @keydown.stop
  >
    <div class="type-dialog">
      <div class="message">
        <h2 id="duplicate-canvas-title">
          {{ $t("flexModal.chooseDuplicateCanvas") }}
        </h2>
        {{ $t("flexModal.chooseDuplicateSession") }}
        <dropdown-menu class="duplicate-flex-board-menu" @click.stop>
          <template #trigger>
            <dropdown-trigger v-autofocus :value="selected.name" />
          </template>
          <div v-keyboard-nav class="dropdown-list">
            <list-item
              v-for="session in sessions"
              :key="session.id"
              @click="select(session)"
            >
              {{ session.name }}
            </list-item>
          </div>
        </dropdown-menu>
        <IconButton
          icon="close"
          class="close-button"
          :aria-label="$t('general.close')"
        />
      </div>
      <div class="scrollable" style="margin-top: 7em">
        <button
          v-for="board in boards"
          :key="board.id"
          class="action flex-button"
          @click="emit('duplicate', board)"
        >
          <FlexBackground
            :type="board.flexType.background"
            class="background"
          />
          <div class="type-name">{{ board.name }}</div>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables/colors" as colors;

.duplicate-flex-board {
  .dropdown-trigger {
    &,
    button {
      border-color: colors.$text !important;
      color: colors.$text !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.duplicate-flex-board-menu .dropdown-list {
  max-height: 250px;
  overflow-y: auto;
}
</style>
