import { ComponentPublicInstance } from "vue";

import CardFlag from "./CardFlag";
import { AlmSourceId, Color, RiskType } from "./baseTypes";
import { RelativeCoordinate } from "./coordinates";
import { Link } from "./link";
import { MarkMode } from "./markMode";
import { LinkedObjective } from "./objective";
import { DependencyTeamFilter } from "./search";
import { Team } from "./session";
import { AlmItemStatus, Status } from "./status";
import { Functionality, StickyType } from "./stickyType";
import { AuthUser } from "./user";

export interface BoardCard {
  data: Card;
  meta: CardMeta;
}

export interface CardMeta {
  pos: RelativeCoordinate;
  zIndex: number;
  mark: MarkMode;
  editing: boolean;
  dragging: boolean;
  isHighlighted: boolean;
  isRelatedToHighlighted: boolean;
  shouldAnimate?: boolean;
  dependencyTeamFilter: DependencyTeamFilter | null;
}

/**
 * Model that represents a card component on the UI.
 */
export interface Card {
  id: string;
  groupId?: string | null;
  almId?: string; // the ID of the external ALM item
  almIssueUrl?: string;
  almSourceId: AlmSourceId | null;
  text: string;
  points: number;
  priority: number;
  teamId: string | null;
  precondTeam?: Team; // team that created the sticky
  dependTeam?: Team; // team that the sticky depends on
  iterationId: number | null;
  editor: AuthUser | null;
  links: Link[];
  type: StickyType;
  flagType: CardFlag;
  risk?: RiskType;
  status?: AlmItemStatus;
  transition?: string;
  objectives: LinkedObjective[];
  reactions?: Reactions;
  artId: string | null;
  assignee: AuthUser | null;
  reporter: AuthUser | null;
} //TODO subtypes depending on type.functionality

export interface BaseStickyInfo {
  id: string;
  text: string;
  color: Color;
  type: string;
  flag: CardFlag;
  iterationId?: number;
  teamId?: string;
  almId?: string;
  status?: Status;
  points?: number;
  iterationName?: string;
  teamName?: string;
  precondTeam?: Team;
  dependTeam?: Team;
  functionality: Functionality;
}

export type Reactions = { [reaction in Reaction]: AuthUser[] };

export const reactions = ["hot", "love", "increment"] as const;

export type Reaction = (typeof reactions)[number];

export type ReactionIcons = {
  [reaction in Reaction]: ComponentPublicInstance | null;
};
