<script setup lang="ts">
import { onMounted, ref } from "vue";

import { normalizeWheelEvent } from "@/utils/wheelEvent";

interface DeprecatedWheelEvent {
  wheelDelta: number;
  wheelDeltaX: number;
  wheelDeltaY: number;
}

type EventData = Pick<WheelEvent, "deltaX" | "deltaY" | "ctrlKey"> &
  DeprecatedWheelEvent & {
    // x/y are the normalized values
    x: number;
    y: number;
  };

const events = ref<EventData[]>([]);

onMounted(() => {
  document.addEventListener("wheel", (e) => {
    const { x, y } = normalizeWheelEvent(e);
    const deprecated = e as unknown as DeprecatedWheelEvent;
    events.value.push({
      deltaX: e.deltaX,
      deltaY: e.deltaY,
      ctrlKey: e.ctrlKey,
      wheelDelta: deprecated.wheelDelta,
      wheelDeltaX: deprecated.wheelDeltaX,
      wheelDeltaY: deprecated.wheelDeltaY,
      x,
      y,
    });
  });
});
</script>

<template>
  <span>
    <span data-t>Test Mouse wheel / track pad</span>
  </span>
  <div v-for="(event, index) in events" :key="index">
    {{ event }}
  </div>
</template>
