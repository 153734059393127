<!-- eslint-disable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
<template>
  <div
    class="flex-board-elem"
    @click.stop="!dragging && !duplicating && $emit('select', board)"
    @keydown.enter.stop
    @keydown.space.stop
  >
    <!-- eslint-enable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
    <div class="image">
      <FlexBackground
        :type="board.flexType.background"
        class="action background"
        aria-hidden="true"
      />

      <div v-if="!duplicating && !deleting && !edit && !dragging" class="icons">
        <IconButton
          v-if="canEdit"
          icon="pen"
          class="action action-icon"
          :title="$t('label.flexModal.canvasEdit', { value: board.name })"
          @click.stop="$emit('edit', board)"
        />
        <IconButton
          v-if="canDuplicate"
          icon="duplicate"
          class="action action-icon"
          :title="$t('label.flexModal.canvasDuplicate', { value: board.name })"
          @click.stop="duplicating = true"
        />
        <IconButton
          v-if="canDelete"
          icon="action-menu/delete"
          class="action action-icon"
          :title="$t('label.flexModal.canvasDelete', { value: board.name })"
          @click.stop="deleting = true"
        />
      </div>
      <duplicate-flex-board-elem
        v-if="duplicating"
        class="flex-dialog"
        @close="duplicating = false"
        @duplicate="duplicateCanvas"
      />
      <delete-flex-board-elem
        v-if="deleting"
        class="flex-dialog"
        @close="deleting = false"
        @delete="deleteCanvas"
      />
    </div>
    <div class="text" @click.stop>
      <input-field
        ref="input"
        :value="board.name"
        :max-length="30"
        :read-only="!canEdit"
        underline
        tabindex="-1"
        :placeholder="$t('flexModal.defaultCanvasName')"
        :aria-label="$t('flexModal.defaultCanvasName')"
        @change="$emit('setName', board, $event)"
        @focus="$emit('edit', board)"
        @blur="$emit('edit')"
        @keydown.stop
      />
      <SvgIcon v-show="canEdit && edit" name="check" width="20" height="20" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";

import { flexActions } from "@/action/flexActions";
import { duplicateFlex } from "@/backend/Backend";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import FlexBackground from "@/components/background/FlexBackground.vue";
import InputField from "@/components/input/InputField.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { BoardData } from "@/model/board";
import { Session } from "@/model/session";
import { goToBoard } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useFlexStore } from "@/store/flex";
import { useToastStore } from "@/store/toast";
import { useUserStore } from "@/store/user";

import DeleteFlexBoardElem from "./DeleteFlexBoardElem.vue";
import DuplicateFlexBoardElem from "./DuplicateFlexBoardElem.vue";

@Component({
  components: {
    DuplicateFlexBoardElem,
    DeleteFlexBoardElem,
    SvgIcon,
    InputField,
    FlexBackground,
    IconButton,
  },
  emits: ["select", "edit", "setName", "deleted", "duplicated", "click"],
})
export default class FlexBoardElem extends Vue {
  @Prop(Object) readonly board!: BoardData<"flex">;
  @Prop(Boolean) readonly dragging!: boolean;
  @Prop(Boolean) readonly edit!: boolean;

  @Ref("input") inputElem!: Vue;
  deleting = false;
  duplicating = false;

  @Watch("edit")
  editChanged(e: boolean) {
    if (e) {
      const el = this.inputElem.$el as HTMLElement;
      el.focus();
      el.click();
    }
  }

  get canEdit() {
    return useUserStore().isAllowed("edit");
  }

  get canDelete() {
    return useUserStore().isAllowed("deleteBoard");
  }

  get canDuplicate() {
    return useUserStore().isAllowed("duplicateFlex");
  }

  deleteCanvas() {
    this.$emit("deleted");
    if (useBoardStore().currentBoard().id === this.board.id) {
      goToBoard("team");
    }
    if (useFlexStore().currentFlexBoard?.id === this.board.id) {
      useFlexStore().currentFlexBoard = null;
    }
    flexActions.delete("modal", this.board.id);
  }

  async duplicateCanvas(session: Session) {
    try {
      await duplicateFlex(this.board.id, session.id);
      useToastStore().show(/*$t*/ "flexModal.duplicated", { level: "ok" });
      this.duplicating = false;
      this.$emit("duplicated");
    } catch (e) {
      useToastStore().show(/*$t*/ "flexModal.duplicatedError", {
        level: "error",
      });
    }
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.flex-board-elem {
  outline: revert;

  .icons {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .image {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .action-icon {
    outline: revert;
    color: colors.$icon !important;

    svg {
      height: 30px !important;
      width: 30px !important;
    }
  }

  .flex-dialog {
    position: absolute;
    background-color: colors-old.$delete-color;
    color: colors-old.$back-color;
    inset: 0 0 -2em;
    text-align: center;
    padding: 1em;

    .action svg {
      height: 1.5em;
      vertical-align: middle;
    }
  }

  .text {
    display: flex;
    align-items: center;

    input {
      text-align: center;
      border: none;
      padding: 0 20px;

      &.edit {
        border-bottom: 0.1em solid colors-old.$input-border-color;
      }
    }
  }
}
</style>
