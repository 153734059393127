<script setup lang="ts">
import { BaseStickyInfo } from "@/model/card";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { searchItemSelected } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { ItemKey } from "./SearchResult";
import SearchResultsItem from "./SearchResultsItem.vue";

const emit = defineEmits<{
  select: [item: ItemKey, focus: boolean];
  pointerenter: [item: BaseStickyInfo];
  pointerleave: [item: BaseStickyInfo];
}>();

const props = defineProps<{
  results: BaseStickyInfo[];
  selected?: ItemKey;
  current: ItemKey | null;
  boardId: string;
}>();

function isCurrentBoard(boardId: string) {
  return useBoardStore().boardId() === boardId;
}

function isSelected(item: BaseStickyInfo) {
  return (
    props.selected &&
    item.id === props.selected.id &&
    item.teamId === props.selected.teamId
  );
}

function isHighlighted(item: BaseStickyInfo) {
  return (
    props.current &&
    item.id === props.current.id &&
    item.teamId === props.current.teamId
  );
}

function select(item: BaseStickyInfo, focus?: boolean) {
  const otherBoardId = isCurrentBoard(props.boardId)
    ? undefined
    : props.boardId;

  trackItemSelected(item, otherBoardId);
  emit(
    "select",
    {
      id: item.id,
      boardId: otherBoardId,
      teamId: item.teamId,
    },
    !!focus,
  );
}

// used for analytics
function trackItemSelected(item: BaseStickyInfo, boardId?: string) {
  const currentBoardType = useBoardStore().currentBoard().type;
  const targetBoardType = boardId
    ? useBoardsStore().boards[boardId].type
    : undefined;

  trackEvent(searchItemSelected(currentBoardType, targetBoardType));
}

/**
 * Selects the item and focuses on it for editing.
 */
function selectAndFocus(item: BaseStickyInfo, event: KeyboardEvent) {
  select(item, true);
  event.preventDefault(); // so it doesn't trigger a click too
  event.stopPropagation();
}
</script>

<template>
  <div>
    <SearchResultsItem
      v-for="item in results"
      :id="`result-${item.id}`"
      :key="item.id"
      aria-keyshortcuts="enter"
      role="option"
      tabindex="0"
      :class="{
        selected: isSelected(item),
        current: isHighlighted(item),
      }"
      :item="item"
      @click.stop="select(item)"
      @keydown.enter.prevent="selectAndFocus(item, $event)"
      @pointerenter="emit('pointerenter', item)"
      @pointerleave="emit('pointerleave', item)"
    >
    </SearchResultsItem>
  </div>
</template>
