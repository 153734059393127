import { defineStore } from "pinia";

import { Card } from "@/model/card";

import { useCardStore } from "./card";

export const useActivityStore = defineStore("activity", {
  state: () => ({
    card: null as Card | null,
    board: false,
  }),
  getters: {
    active: (state) => !!state.card || !!state.board,
  },
  actions: {
    toggleCard(id: string) {
      this.card = this.card?.id === id ? null : useCardStore().cards[id];
      this.board = false;
    },
    toggleBoard() {
      this.board = !this.board;
      this.card = null;
    },
    inactivate() {
      this.card = null;
      this.board = false;
    },
  },
});
