import { zoomFactor } from "@/Settings";
import { Board, BoardData } from "@/model/board";
import { FlexType } from "@/model/flexboard";
import { useTeamStore } from "@/store/team";

import { BackendSession } from "../BackendSession";
import { ServerBoard, ServerFlexBoard } from "../serverModel";

export function mapBoard(
  board: ServerBoard | ServerFlexBoard,
  flexTypes: FlexType[],
  almSourceProvider: BackendSession["almSource"],
): Board {
  return board.board_type === "flex"
    ? mapFlexBoard(board, flexTypes)
    : mapNonFlexBoard(board, almSourceProvider);
}

export function mapFlexBoard(
  board: ServerFlexBoard,
  flexTypes: FlexType[],
): BoardData<"flex"> {
  return {
    ...baseBoardData(board),
    flexType: flexTypes.find((type) => type.id === board.flexboard_type)!,
    name: board.name,
    almSources: [],
  } as BoardData<"flex">;
}

function mapNonFlexBoard(
  board: ServerBoard,
  almSourceProvider: BackendSession["almSource"],
): Board {
  const alm = almSourceProvider(board.board_type, {
    teamId: board.user_id,
    artId: board.art_id,
  });
  return {
    ...baseBoardData(board),
    team:
      board.user_id === null
        ? undefined
        : useTeamStore().teams.find((t) => t.id === "" + board.user_id),
    almSources: alm ? alm.sources.map(({ id, name }) => ({ id, name })) : [],
    artId: board.art_id ? "" + board.art_id : undefined,
  } as Board;
}

function baseBoardData(board: ServerBoard | ServerFlexBoard): Partial<Board> {
  return {
    id: board.board_id,
    type: board.board_type,
    cardSize: { factor: 1, ...zoomFactor },
    cards: {},
    maxZ: 0,
    loaded: 0,
    selected: {},
    shapes: board.shapes || [],
  };
}
