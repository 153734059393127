<script setup lang="ts">
import { computed } from "vue";

import { linkActions } from "@/action/linkActions";
import CardTree from "@/components/CardTree.vue";
import LinkableCardRow from "@/components/LinkableCardRow.vue";
import { BoardData } from "@/model/board";
import { LinkableCard } from "@/model/link";
import { useBoardsStore } from "@/store/boards";
import { useModalStore } from "@/store/modal";
import { useObjectiveStore } from "@/store/objective";
import { useUserStore } from "@/store/user";

import BaseModal from "./BaseModal.vue";

const props = defineProps<{ boardId: string; objectiveId: string }>();
const modals = useModalStore();

const readonly = computed(() => !useUserStore().isAllowed("edit"));

const board = computed(
  () => useBoardsStore().boardById(props.boardId) as BoardData<"team">,
);
const objective = computed(
  () =>
    useObjectiveStore().objectiveById(props.objectiveId, {
      teamId: board.value.team.id,
    })!,
);
const linkedStickies = computed(
  () =>
    useObjectiveStore().linkedCardTree(objective.value, {
      teamId: board.value.team.id,
    }).cards,
);

async function removeLink(sticky: LinkableCard) {
  if (readonly.value) return;

  await linkActions.removeObjective(
    "modal",
    sticky.id,
    board.value.id,
    objective.value.id,
    "team-objectives-modal",
  );
  modals.hide();
}
</script>

<template>
  <BaseModal
    :title="$t('objectiveLinkModal.title')"
    :description="$t('label.objectiveLinkModal.description')"
    class="objective-link-modal"
  >
    <div class="scrollable-list">
      <div
        class="linked-stickies-list"
        role="list"
        :class="{ readonly }"
        :aria-label="$t('objectiveLinkModal.title')"
      >
        <div v-for="item in linkedStickies" :key="item.id">
          <CardTree
            role="group"
            class="linked-group"
            :linked-card-ids="item.childIds"
            :aria-label="item.text"
          >
            <LinkableCardRow
              role="listitem"
              :linkable-card="item"
              show-team
              :show-actions="!readonly"
              @set-linked-state="removeLink(item)"
              @keydown.space.stop
              @keydown.enter.stop
            />
          </CardTree>
        </div>
      </div>
    </div>
    <span v-if="!linkedStickies.length" class="empty">
      {{ $t("general.noLinkedStickies") }}
    </span>
  </BaseModal>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.objective-link-modal {
  .empty {
    display: inline-flex;
    padding: 3rem 0;
    color: colors-old.$text-secondary-color;
    justify-content: center;
    width: 100%;
    margin: auto;
  }

  .linked-stickies-list.readonly {
    pointer-events: none;
  }

  .linked-group :deep(.item),
  .linked-group :deep(.linkable-card-row) {
    cursor: default;
  }
}
</style>
