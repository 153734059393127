import { noop } from "lodash-es";
import { nextTick } from "vue";

import { logPerformance } from "@/Environment";

export function nextTicks(ticks: number, callback: () => void) {
  const start = performance.now();
  if (ticks === 0) {
    callback();
  } else {
    nextTick(() => {
      performanceLog(`tick ${ticks}`, start);
      nextTicks(ticks - 1, callback);
    });
  }
}

export const performanceLog = logPerformance
  ? (message: string, start: number) => {
      // eslint-disable-next-line no-console
      console.log(message, performance.now() - start + " ms");
    }
  : noop;
