import color from "@/styles/color.module.scss";

export type GroupedValues<K, T> = Map<K, { value: number } & T>;

interface BarChartItem<K> {
  key: K;
  value: number;
  color: string;
  x: number;
  width: number;
}

export interface BarChartData<K, T> {
  total: number;
  items: Array<BarChartItem<K> & T>;
}

const totalWidth = 100;

export function createBarChartData<K, T>(
  values: GroupedValues<K, T>,
  enrichFn: (key: K, value: { value: number } & T) => T,
): BarChartData<K, T> | BarChartData<string, never> {
  const total = [...values.values()].reduce((sum, item) => sum + item.value, 0);

  if (total <= 0) {
    return {
      total: 0,
      items: [
        {
          key: "",
          value: 0,
          color: "",
          x: 0,
          width: totalWidth,
        },
      ],
    } as BarChartData<string, never>;
  }
  const res: BarChartData<K, T> = { total, items: [] };
  let width = totalWidth;
  values.forEach((value, key) => {
    res.items.push({
      key,
      value: value.value,
      color: color.ok,
      x: 0,
      width,
      ...enrichFn(key, value),
    });
    width -= (totalWidth * value.value) / total;
  });
  return res;
}
