import { Directive, createApp, ref } from "vue";

import CardsSkipButton from "@/components-ng/a11y/CardsSkipButton.vue";
import { BoardLocation, cardsInLocation } from "@/components/BoardLocation";
import { i18n } from "@/i18n";
import { Board } from "@/model/board";
import { BoardCard } from "@/model/card";

/**
 * Sort cards by their position on the board
 */
export const byPosition = (a: BoardCard, b: BoardCard) => {
  const yDiff = a.meta.pos.y - b.meta.pos.y;
  const xDiff = a.meta.pos.x - b.meta.pos.x;
  return yDiff * 10 + xDiff;
};

/**
 * Generate the id for the cards-skip-button button in a board location
 */
export const skipButtonId = (location: BoardLocation) =>
  `${location.name}-skip-button`;

/**
 * Set the el's aria-owns to the ids of all associated Cards,
 * sorted by their position on the board
 *
 * @param el The element to set the aria-owns attribute on
 * @param board The board the element is on
 * @param location The board location the element represents
 */
const setAriaOwns = (
  el: HTMLElement,
  board: Board,
  location: BoardLocation,
) => {
  const cards = cardsInLocation(board, location);

  // Sort cards by their position, so that the
  // screen readers read them in the correct order
  const sortedCards = cards.sort(byPosition);
  const cardIds = sortedCards.map((card) => card.data.id).join(" ");

  el.setAttribute("aria-owns", cardIds);
};

/**
 * Focus on the first card in the board location (based on parent's aria-owns property)
 */
const focusFirstCard = (e: MouseEvent) => {
  const el = e.currentTarget as HTMLElement;
  const firstCardId = el
    .closest("[aria-owns]")
    ?.getAttribute("aria-owns")
    ?.split(" ")?.[0];
  if (firstCardId) {
    document.getElementById(firstCardId)?.focus();
  }
};

/**
 * Add a cards-skip-button button to the board location that allows
 * the keyboard user to interact with the cards in that location
 */
const addSkipToCardsButton = (
  el: HTMLElement,
  location: BoardLocation,
  tabindex?: string,
) => {
  // Find the cards-skip-button button if it already exists in this element
  const existingButton = el.querySelector<HTMLElement>(".cards-skip-button");

  // If it already exists, update with appropriate id, tabindex, and click handler
  if (existingButton) {
    existingButton.id = skipButtonId(location);
    existingButton.addEventListener("click", focusFirstCard);
    if (tabindex) {
      existingButton.setAttribute("tabindex", tabindex);
    }
    return;
  }

  // Otherwise, add a cards-skip-button button to the board location
  const div = document.createElement("div");
  el.appendChild(div);

  const buttonProps = { id: skipButtonId(location), tabindex: tabindex || "0" };

  createApp(CardsSkipButton, buttonProps).use(i18n).mount(div);
  div.addEventListener("click", focusFirstCard);
};

/**
 * Directive to set the aria-owns attribute of an element
 * (representing a board location) to the ids of all cards
 * in that location.
 *
 * This allows screen readers to associate cards with their 'parent'
 * board location, so they can be navigated to *in logical order* while
 * navigating the board.
 *
 * The calculation is triggered when the element is focused, which is much
 * more efficient than to calculate it while the cards are loading.
 *
 * Usage: v-owned-cards="{
 *  board: { the Board the element is on },
 *  location: { the BoardLocation represented by the element}
 *  tabindex: "{ optional string value to set on the skip button element }"
 * }"
 */
export function ownedCards(): Directive<HTMLElement> {
  const focusedLocation = ref<BoardLocation | null>(null); // Shared across all instances of directive
  return {
    mounted(el, { value: { board, location, tabindex } }) {
      addSkipToCardsButton(el, location, tabindex);

      // On focusin, update the el's aria-owns attribute to reflect
      // the cards currently in the location
      el.addEventListener("focusin", () => {
        if (
          focusedLocation.value?.index()?.toString() !==
          location.index()?.toString()
        ) {
          setAriaOwns(el, board, location);
        }
        focusedLocation.value = location;
      });
    },
  };
}
