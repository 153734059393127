import { noop } from "lodash-es";

export function firstProperty(
  obj: Record<string, unknown>,
): string | undefined {
  for (const prop in obj) {
    return prop;
  }
}

export function mapObjectValues<S, T>(
  obj: Record<string, S>,
  mapper: (value: S) => T,
): Record<string, T> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, mapper(value)]),
  );
}

export function firstValue(obj: Record<string, unknown>): any {
  return obj[firstProperty(obj)!];
}

export function isEmpty(obj: Record<string, unknown>): boolean {
  return !firstProperty(obj);
}

export function toHex(value: number, len: number): string {
  return pad(value.toString(16), len);
}

export function pad(s: string | number, len: number): string {
  let r = s.toString();
  while (r.length < len) {
    r = "0" + r;
  }
  return r;
}

export function generateId(type: string, data?: string) {
  let res = type;
  const end = data || "";
  while (res.length + end.length < 24) {
    res += "0";
  }
  return res + end;
}

export function hash(s: string) {
  let h = 3496329;
  for (let i = 0; i < s.length; i++) {
    // eslint-disable-next-line no-bitwise
    h = ((h << 5) - h + s.charCodeAt(i)) | 0;
  }
  return h;
}

export function flattenObject(obj: any, level = 2, path = ""): any {
  const flattenable = ["[object Object]", "[object Array]"].includes(
    Object.prototype.toString.call(obj),
  );
  const keys = Object.keys(obj || "");
  if (level === 0 || !flattenable || !keys.length) {
    return path.length ? { [path.substring(1)]: obj } : obj;
  }
  return keys.reduce(
    (accu, prop) => ({
      ...accu,
      ...flattenObject(obj[prop], level - 1, path + "." + prop),
    }),
    {},
  );
}

/**
 * Removes the non-printing character from a string
 * https://en.wikipedia.org/wiki/Control_character#In_Unicode
 */
export function removeNonPrintable(value: string) {
  // eslint-disable-next-line no-control-regex
  return value.replace(/[\u0000-\u001F\u007F-\u009F]/g, " ");
}

export function neverResolve<T>(): Promise<T> {
  return new Promise(noop);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export async function asyncNoop() {}
