<script setup lang="ts">
import { computed, ref } from "vue";

import { objectiveActions } from "@/action/objectiveActions";
import RevealHeightTransition from "@/components-ng/transitions/RevealHeightTransition.vue";
import CardTree from "@/components/CardTree.vue";
import LinkableCardRow from "@/components/LinkableCardRow.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import PointsSelect from "@/components/input/PointsSelect.vue";
import { BoardWithObjectives } from "@/model/board";
import { ObjectiveType } from "@/model/objective";
import { useObjectiveStore } from "@/store/objective";
import { useUserStore } from "@/store/user";
import {
  objectiveBusinessValueSet,
  planReadoutObjectiveLinksToggled,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

defineOptions({ inheritAttrs: false });

const props = defineProps<{
  objectiveId: string;
  type: ObjectiveType;
  index: number;
  board: BoardWithObjectives;
}>();

const isLinkedStickiesExpanded = ref(false);
const showBusinessValueSelector = ref(false);

const teamId = computed(() => {
  return props.board.type === "team" ? props.board.team.id : undefined;
});

const objective = computed(
  () =>
    useObjectiveStore().objectiveById(props.objectiveId, {
      teamId: teamId.value,
    })!,
);

const readonly = computed(() => !useUserStore().isAllowed("edit"));

const setBusinessValue = (value?: number) => {
  showBusinessValueSelector.value = false;
  if (value !== undefined) {
    trackEvent(
      objectiveBusinessValueSet({
        hadPreviousValue: objective.value.bv > 0,
        hasNewValue: true,
        location: "plan-readout-modal",
      }),
    );

    objective.value.bv = value;
    objectiveActions.setBv("mainMenu", props.board.id, objective.value);
  }
};

const linkedCardTree = computed(() =>
  useObjectiveStore().linkedCardTree(objective.value, {
    teamId: teamId.value,
  }),
);

const toggleExpandLinkedStickies = () => {
  trackEvent(planReadoutObjectiveLinksToggled(!isLinkedStickiesExpanded.value));
  isLinkedStickiesExpanded.value = !isLinkedStickiesExpanded.value;
};
</script>

<template>
  <div class="objective-container no-scrollbar">
    <div class="objective-header">
      <div class="objective-details">
        <div class="objective-values">
          <div class="objective-tag">
            {{ $t("planReadoutModal.objectives.indexPlaceholder", { index }) }}
          </div>
          <div v-if="type === 'committed'" class="objective-tag">
            <SvgIcon name="committed" width="20" height="20" />
            {{ $t("objectives.committed") }}
          </div>
          <div v-else class="objective-tag">
            <SvgIcon name="uncommitted" width="20" height="20" />
            {{ $t("objectives.uncommitted") }}
          </div>
        </div>
        <div class="objective-values-bv">
          <div class="objective-bv-title">
            {{ $t("objectives.businessValue") }}
          </div>
          <button
            class="objective-bv-container"
            :class="{ focused: showBusinessValueSelector }"
            :disabled="readonly"
            @click.stop="showBusinessValueSelector = !showBusinessValueSelector"
          >
            <span class="value">{{ objective.bv }}</span>
            <PointsSelect
              :show="showBusinessValueSelector"
              :current="objective.bv"
              @input="setBusinessValue"
            />
          </button>
        </div>
      </div>
      <div class="objective-title">
        {{ objective.text }}
      </div>
    </div>
    <div class="no-scrollbar">
      <div v-if="objective.description" class="objective-description">
        <p class="text">
          {{ objective.description }}
        </p>
      </div>
      <div v-else class="objective-description no-description">
        {{ $t("planReadoutModal.objectives.noDescription") }}
      </div>
      <div class="objective-expanded-content">
        <div class="objective-content-header">
          <button
            class="linked-stickies-count"
            @click.stop="toggleExpandLinkedStickies"
          >
            <SvgIcon name="link" width="20" height="20" />
            <span style="margin-left: 5px">
              {{
                $t("objectivesModal.linkedStickiesNumber", linkedCardTree.count)
              }}
            </span>
            <SvgIcon
              v-if="linkedCardTree.cards.length > 0"
              name="arrow/up"
              width="20"
              height="20"
              class="arrow-icon"
              :class="{ expanded: isLinkedStickiesExpanded }"
            />
          </button>
        </div>
        <RevealHeightTransition wipe>
          <div v-if="isLinkedStickiesExpanded">
            <div
              v-for="sticky in linkedCardTree.cards"
              :id="'sticky-' + sticky.id"
              :key="sticky.id"
            >
              <CardTree
                :linked-card-ids="sticky.childIds"
                show-status
                class="card-row-item"
              >
                <LinkableCardRow
                  :linkable-card="sticky"
                  reset-button
                  show-status
                />
              </CardTree>
            </div>
          </div>
        </RevealHeightTransition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/utils";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/typography";

.objective-container {
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
  max-height: 100%;

  & > * {
    flex-shrink: 0;
  }
}

.objective-header {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: colors-old.$back-color;
  border-bottom: 1px solid colors-old.$divider-color;
  padding-bottom: 24px;

  .objective-details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    height: 40px;

    .objective-values {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: 8px;

      .objective-tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 12px;
        gap: 8px;
        background: colors-old.$light-background-color;
        border-radius: 8px;
        font-size: font.$size-large;
        color: colors-old.$text-primary-color;
        font-weight: font.$weight-bold;
        line-height: 20px;
      }
    }

    .objective-values-bv {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      height: 40px;

      .objective-bv-title {
        font-size: font.$size-large;
        font-weight: font.$weight-semi-bold;
        line-height: 20px;
      }

      .objective-bv-container {
        position: relative;
        border: 1px solid colors-old.$objective-border-color;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: right;
        text-align: center;
        outline: revert;

        &:hover:not(:disabled) {
          background-color: colors-old.$background-hover-menu-color;
        }

        .value {
          flex-grow: 1;
          font-size: font.$size-large;
          font-weight: font.$weight-bold;
        }

        &.focused {
          border: 1px solid colors-old.$menu-item-focus-color;
        }

        .points-select {
          top: 100%;
          margin-top: 2px;
        }
      }
    }
  }

  .objective-title {
    font-weight: font.$weight-extra-bold;
    font-size: 2rem;
    line-height: 40px;
    overflow-wrap: break-word;
  }
}

.objective-description {
  padding-bottom: 24px;
  padding-top: 20px;
  background-color: colors-old.$back-color;
  border-bottom: 1px solid colors-old.$divider-color;
  font-size: font.$size-large;
  color: colors-old.$text-secondary-color;
  font-weight: font.$weight-medium;
  line-height: 24px;
  overflow: hidden auto;
  overflow-wrap: break-word;

  > .text {
    @include typography.large;

    white-space: break-spaces;
    user-select: text;
  }
}

.no-description {
  color: colors-old.$placeholder-color;
}

.no-scrollbar {
  overflow-y: scroll;

  @include utils.hide-scrollbar;
}

.objective-expanded-content {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .objective-content-header {
    display: flex;
    justify-content: space-between;

    .linked-stickies-count {
      font-size: font.$size-normal;
      color: colors-old.$text-secondary-color;
      font-weight: font.$weight-semi-bold;
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      margin-bottom: 10px;
      outline: revert;

      :nth-child(2) {
        flex-grow: 1;
      }

      .arrow-icon {
        transform: rotate(180deg);
        transition: transform 0.4s ease;

        &.expanded {
          transform: none;
        }
      }
    }
  }

  .card-row-item {
    :deep(*) {
      cursor: default;
    }
  }
}
</style>
