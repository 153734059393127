<script lang="ts" setup>
import { computed } from "vue";

import { getAlmInfo } from "@/backend/Backend";
import UserChip from "@/components-ng/StickyNote/components/StickyNoteFooter/components/UserChip/UserChip.vue";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import {
  isArtBoard,
  isBacklogBoard,
  isPlanningBoard,
  isRiskBoard,
  isSolutionBoard,
  isTeamBoard,
} from "@/model/board";
import { useBoardStore } from "@/store/board";
import { useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";
import { useWorkModeStore } from "@/store/workMode";
import { injectStrict } from "@/utils/context";

import StickyNoteRow from "../../../StickyNoteRow/StickyNoteRow.vue";
import ArtSelectChip from "../ArtSelectChip/ArtSelectChip.vue";
import MirrorStickyChip from "../MirrorStickyChip/MirrorStickyChip.vue";
import StickyLinksChip from "../StickyLinksChip/StickyLinksChip.vue";
import SelectStatusChip from "../StickyStatusChip/StickyStatusChip.vue";
import StoryPointsChip from "../StoryPointsChip/StoryPointsChip.vue";
import TeamSelectChip from "../TeamSelectChip/TeamSelectChip.vue";
import WSJFChip from "../WSJFChip/WSJFChip.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isSelected = computed(() => useBoardStore().activeCardId === card.id);

const isAssigneeVisible = computed(
  () =>
    (!useStickyTypeStore().almStickyTypeOf(card.type)?.isMapped ||
      getAlmInfo().hasAssignee) &&
    card.type.origin === "team",
);
const teamName = computed(() => {
  const teams = useTeamStore().teamsInCurrentArt;
  return teams.find((team) => team.id === card.teamId)?.name;
});

const isTeamNameVisible = computed(() => isRiskBoard(board.value.type));
const isTeamSelectChipVisible = computed(() => {
  return (
    // visible only on the art backlog board
    isArtBoard(board.value.type) && isBacklogBoard(board.value.type)
  );
});

const isArtSelectChipVisible = computed(() => {
  // visible only on the solution backlog board
  return isSolutionBoard(board.value.type) && isBacklogBoard(board.value.type);
});

const isLinksChipVisible = computed(() => {
  // visible only when selected or on the planning/team board
  return (
    isPlanningBoard(board.value.type) ||
    isTeamBoard(board.value.type) ||
    isSelected.value
  );
});

// visible only if sticky is selected
const isMirrorChipVisible = computed(() => isSelected.value);

const isWSJFChipVisible = computed(() => {
  // hidden on the team board and risk board
  if (isTeamBoard(board.value.type) || isRiskBoard(board.value.type)) {
    return false;
  }
  // hidden for execution mode
  if (useWorkModeStore().isExecutionMode) {
    return false;
  }
  return (
    // visible on all backlog boards
    isBacklogBoard(board.value.type) ||
    // visible only for stickies that we created on the backlog board
    card.type.origin === "backlog" ||
    card.type.origin === "solution_backlog"
  );
});

const isStatusChipVisible = computed(() => {
  // visible only in execution mode
  return useWorkModeStore().isExecutionMode;
});

const isStoryPointsChipVisible = computed(() => {
  // hidden in execution mode
  if (useWorkModeStore().isExecutionMode) return false;
  // visible only for work items created on the team board
  return card.type.origin === "team";
});
</script>

<template>
  <StickyNoteRow class="workitem-footer">
    <span v-if="isTeamNameVisible && teamName" class="team-name">
      {{ teamName }}
    </span>
    <UserChip v-if="isAssigneeVisible" />
    <TeamSelectChip v-if="isTeamSelectChipVisible" shrink />
    <ArtSelectChip v-if="isArtSelectChipVisible" shrink />
    <StickyLinksChip v-if="isLinksChipVisible" />
    <MirrorStickyChip v-if="isMirrorChipVisible" />

    <div class="devider"></div>

    <WSJFChip v-if="isWSJFChipVisible" />
    <StoryPointsChip v-if="isStoryPointsChipVisible" />
    <SelectStatusChip v-if="isStatusChipVisible" />
  </StickyNoteRow>
</template>

<style lang="scss" scoped>
.workitem-footer {
  .team-name {
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  .devider {
    margin-left: auto;
    margin-right: -6px;
  }
}
</style>
