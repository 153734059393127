<script lang="ts" setup>
import Fuse from "fuse.js";
import { computed, ref } from "vue";

import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListFooter from "@/components-ng/BaseList/components/BaseListFooter/BaseListFooter.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import SearchInput from "@/components-ng/SearchInput/SearchInput.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import type { Art } from "@/model/session";

const props = defineProps<{ arts: Art[]; selected: Art | null }>();
const emit = defineEmits<{ select: [art: Art | null] }>();

const filter = ref<string>("");
const arts = computed(() => {
  const fuse = new Fuse(props.arts, { keys: ["name"], isCaseSensitive: false });

  if (!filter.value) return props.arts;
  return fuse.search(filter.value).map((r) => r.item);
});

const handleArtChange = (art: Art | null) => {
  emit("select", art);
};
</script>

<template>
  <BaseList class="art-selector">
    <BaseListTitle>{{ $t("actions.art") }}</BaseListTitle>

    <!-- Search Input -->
    <div class="search-container">
      <SearchInput
        v-model="filter"
        autofocus
        icon-position="after"
        size="small"
        :icon-title="$t('label.artSelector.searchTitle')"
        :results-count="arts?.length"
      />
    </div>

    <!-- List -->
    <BaseListItems :aria-label="$t('actions.art')">
      <BaseListItem
        v-for="art in arts"
        :key="art.id"
        :active="art.id === selected?.id"
        @click="handleArtChange(art)"
        @keydown.enter="handleArtChange(art)"
      >
        <template #before>
          <SvgIcon name="art" width="20" height="20" />
        </template>
        {{ art.name }}
      </BaseListItem>
    </BaseListItems>
    <BaseListFooter :disabled="!selected" @click="handleArtChange(null)" />
  </BaseList>
</template>

<style lang="scss" scoped>
.art-selector {
  max-height: 350px;

  .search-container {
    padding: 8px;
  }
}
</style>
